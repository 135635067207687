export const source = ` <div class="flex items-center justify-center bg-gradient-to-r from-rose-50 via-sky-50 to-teal-50 ">
<div class="flex flex-col lg:flex-row p-4 space-x-4 max-w-7xl justify-around w-full lg:h-60 items-center ">
    <div class="h-40 w-[70%] md:w-1/4 flex items-center justify-center  text-3xl font-bold">Tailblocks is the best website for Tailwind CSS Blocks</div>
    <div class="h-40 w-[70%] md:w-1/4 flex items-center justify-center">
        <div class="flex-col space-y-2">
            <div class="text-5xl font-bold">15k+</div>
            <div class="text-sm font-medium text-gray-600">Tailblocks has more than 15k+ visitors per month.</div>
        </div>
    </div>
    <div class="h-40 w-[70%] md:w-1/4 flex items-center justify-center">
        <div class="flex-col space-y-2">
            <div class="text-5xl font-bold">1.2M+</div>
            <div class="text-sm font-medium text-gray-600">Tailblocks has more than 1.2M+ total users.</div>
        </div>
    </div>
    <div class="h-40 w-[70%] md:w-1/4 flex items-center justify-center">
        <div class="flex-col space-y-2">
            <div class="text-5xl font-bold">69k</div>
            <div class="text-sm font-medium text-gray-600">Tailblocks has gained 69k+ users last month.</div>
        </div>
    </div>
    
</div>

</div>`;
