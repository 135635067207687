export const source = `
<section class="relative h-screen overflow-hidden" data-section="hero-video">
<video autoplay loop muted class="absolute z-0 w-full h-full object-cover">
  <source src="your-video.mp4" type="video/mp4">
</video>
<div class="relative z-10 flex items-center justify-center h-full bg-black bg-opacity-50">
  <div class="text-center text-white">
    <h1 class="text-5xl font-bold mb-4">MetaFlow3D: Революція в 3D дизайні</h1>
    <p class="text-xl mb-8">Створюйте приголомшливі 3D проекти з силою ШІ</p>
    <a href="#" class="bg-blue-600 text-white px-8 py-3 rounded-full hover:bg-blue-700 transition duration-300">Почати безкоштовно</a>
  </div>
</div>
</section>
`;
