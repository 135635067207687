export const source = `<div class="flex justify-center bg-gradient-to-r from-blue-700 via-blue-800 to-stone-900 p-4">
<div class="flex flex-col justify-center items-center ">
    <div class="flex flex-col md:flex-row max-w-7xl justify-center items-center ">

        <div class="overflow-hidden md:w-3/4 m-4 flex justify-center bg-white  ">

            <div class="flex flex-col md:flex-row items-center justify-center md:w-3/4  ">
                <div class=" flex flex-col items-center justify-center flex py-2">
                    <div class="text-3xl font-bold text-stone-700 text-center md:-ml-24">Testimonials</div>
                    <div class="text-md text-stone-700 font-medium text-center md:-ml-24 m-2">See What People
                        are Saying</div>
                    <div class="flex flex-col md:flex-row   items-center justify-center   mx-10">

                        <div
                            class="pl-2 pt-2 bg-gradient-to-r from-blue-700 via-blue-600 to-stone-700 rounded-full ">

                            <img src="https://source.unsplash.com/150x150/?man,boy" alt=""
                                class="rounded-full" />
                        </div>
                        <div class="flex flex-col w-full md:w-2/3  mx-10">
                            <div class="text-stone-600 font-normal m-2 italic"> Tailblocks provides best
                                Tailwind CSS Components and Blocks to create an unique websites within minutes.
                                It has upto 60+ free components for front-end Web Development.</div>
                            <div class="font-bold text-2xl text-blue-500 ">John Doe</div>
                            <div class="text-stone-500 text-sm   "><a href="#">CEO, Tailblocks</a></div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>
</div>`;
