export const source = `
<!-- Tools Section -->
    <section class="bg-gray-900 py-20 relative overflow-hidden" data-section="tools">
        <div class="container mx-auto px-4 relative z-10">
            <div class="mb-12">
                <span class="text-yellow-500 text-sm font-semibold mr-2 px-2.5 py-0.5 rounded border border-yellow-500">WORKFLOW ESSENTIALS</span>
                <h2 class="text-4xl font-bold text-white mt-4">Tools For Smooth <span class="text-yellow-500">3D Workflows</span></h2>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8" data-element="tool-cards">
                <!-- Tool cards here -->
            </div>
        </div>
    </section>
`;
