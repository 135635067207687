export const source = `    <div class="flex justify-center">
<div class="flex flex-col md:flex-row justify-center max-w-7xl  mt-5">
    <div class="flex flex-col px-7 py-2 bg-slate-200  w-full rounded-tl-3xl">
        <div class="text-lg font-semibold">
            <h1>Send a message</h1>
        </div>
        <div class="flex flex-col md:flex-row ">
            <div class="">
                <input type="text" name="" id="" placeholder="Enter first name" class="w-full border-2 border-white px-3 py-1 text-sm outline-none placeholder:text-black placeholder:opacity-40 mt-4 mr-2 focus:border-blue-500 duration-200 ease-in-out rounded-lg">
            </div>
            <div class="">
                <input type="text" name="" id="" placeholder="Enter last name" class="w-full md:w-[95%] border-2 border-white px-3 py-1 text-sm outline-none placeholder:text-black placeholder:opacity-40 mt-4 md:ml-2 focus:border-blue-500 duration-200 ease-in-out rounded-lg">
            </div>
        </div>
        <input type="email" name="" id="" placeholder="Enter email address" class="w-full border-2 border-white text-sm outline-none px-3 py-1 placeholder:text-black placeholder:opacity-40 mt-4 focus:border-blue-500 duration-200 ease-in-out rounded-lg">
        <input type="tel" name="" id="" placeholder="Phone Number" class="w-full border-2 border-white text-sm outline-none px-3 py-1 placeholder:text-black placeholder:opacity-40 mt-4 focus:border-blue-500 duration-200 ease-in-out rounded-lg">
        <input type="text" name="" id="" placeholder="Subject" class="w-full border-2 border-white text-sm outline-none px-3 py-1 placeholder:text-black placeholder:opacity-40 mt-4 focus:border-blue-500 duration-200 ease-in-out rounded-lg">
        <textarea id="message" name="message" placeholder="Write us a message" class="mb-3 w-full border-2 border-white h-44 text-sm outline-none placeholder:text-black placeholder:opacity-40 focus:border-blue-500 py-1 px-3 mt-4 resize-none leading-6 duration-200 ease-in-out rounded-lg"></textarea>
        <button class="bg-blue-900 hover:bg-blue-600 px-4 font-semibold text-white w-1/2 text-center mx-auto my-3 rounded-tr-lg rounded-bl-lg">Submit</button>
    </div>
    <div class="flex flex-col bg-blue-900 text-white md:w-[65%] rounded-br-3xl">
        <h1 class="font-semibold px-5 my-5">Contact info</h1>
        <div class="flex px-5 space-x-2">
            <ion-icon name="call-sharp" class="text-orange-500  mt-1 my-3"></ion-icon>
            <span class="text-sm">+1 900 267 2678</span>
        </div>
        <div class="flex px-5 space-x-2">
            <ion-icon name="mail" class="text-orange-500  mt-1 my-3"></ion-icon>
            <span class="text-sm">info@gmail.com</span>
        </div>
        <div class="flex px-5 space-x-2">
            <ion-icon name="globe-outline" class="text-orange-500 mt-1 my-3" ></ion-icon>
            <span class="text-sm">www.tailblocks.org</span>
        </div>
        <div class="px-5 space-x-5 mt-7 mx-auto mb-5" >
            <a href="#"><i class="fa-brands fa-facebook-f text-white text-2xl"></i></a>
            <a href="#"><i class="fa-brands fa-instagram text-red-500  text-2xl"></i></a>
            <a href="#"><i class="fa-brands fa-linkedin text-yellow-500 text-2xl"></i></a>
        </div>
    </div>
</div>
</div>`;
