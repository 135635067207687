export const source = ` 
<section class="bg-gray-100 py-20" data-section="features-comparison">
<div class="container mx-auto px-4">
  <h2 class="text-3xl font-bold text-center mb-12">MetaFlow3D vs. Конкуренти</h2>
  <div class="overflow-x-auto">
    <table class="w-full bg-white shadow-lg rounded-lg overflow-hidden">
      <thead class="bg-gray-800 text-white">
        <tr>
          <th class="py-3 px-4 text-left">Особливість</th>
          <th class="py-3 px-4 text-center">MetaFlow3D</th>
          <th class="py-3 px-4 text-center">Конкурент A</th>
          <th class="py-3 px-4 text-center">Конкурент B</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="py-3 px-4 border-b">ШІ-асистент</td>
          <td class="py-3 px-4 border-b text-center text-green-600">✓</td>
          <td class="py-3 px-4 border-b text-center text-red-600">✗</td>
          <td class="py-3 px-4 border-b text-center text-red-600">✗</td>
        </tr>
        <!-- Додайте інші рядки для порівняння -->
      </tbody>
    </table>
  </div>
</div>
</section>
`;
