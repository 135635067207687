export const source = `<div class="flex items-center justify-center bg-gray-800 p-4 md:h-40">
<div class="flex flex-col md:flex-row">
    <div class="bg-gray-700 flex items-start h-28 md:w-40 w-60 justify-center px-4 mx-0.5 my-0.5">
        <div class="flex-col">
            <div class="text-sm font-medium text-gray-400 my-2">Summary</div>
            <div class="class flex items-center">
                <div class="text-3xl font-bold text-gray-200">15k</div>
                <div class="flex items-center justify-between mx-2 px-0.5 py-0.5 rounded-xl text-violet-500 font-medium ">
                    <div><ion-icon name="arrow-down-outline"></ion-icon></div>
                    <div>25%</div>
                </div>
            </div>
            <div class="text-sm font-medium text-gray-400 ">Last week 18.7k</div>
        </div>
    </div>
    <div class="bg-gray-700 flex items-start h-28 md:w-40 w-60 justify-center px-4 mx-0.5 my-0.5">
        <div class="flex-col">
            <div class="text-sm font-medium text-gray-400 my-2">Engagement</div>
            <div class="class flex items-center">
                <div class="text-3xl font-bold text-gray-200">23k</div>
                <div class="flex items-center justify-between mx-2 px-0.5 py-0.5 rounded-xl text-green-500 font-medium ">
                    <div><ion-icon name="arrow-up-outline"></ion-icon></div>
                    <div>5%</div>
                </div>
            </div>
            <div class="text-sm font-medium text-gray-400 ">Last week 20.2k</div>
        </div>
    </div>
    <div class="bg-gray-700 flex items-start h-28 md:w-40 w-60 justify-center px-4 mx-0.5 my-0.5">
        <div class="flex-col">
            <div class="text-sm font-medium text-gray-400 my-2">Customers</div>
            <div class="class flex items-center">
                <div class="text-3xl font-bold text-gray-200">43k</div>
                <div class="flex items-center justify-between mx-2 px-0.5 py-0.5 rounded-xl text-violet-500 font-medium ">
                    <div><ion-icon name="arrow-down-outline"></ion-icon></div>
                    <div>2%</div>
                </div>
            </div>
            <div class="text-sm font-medium text-gray-400 ">Last week 44.9k</div>
        </div>
    </div>
    <div class="bg-gray-700 flex items-start h-28 md:w-40 w-60 justify-center px-4 mx-0.5 my-0.5">
        <div class="flex-col">
            <div class="text-sm font-medium text-gray-400 my-2">Views</div>
            <div class="class flex items-center">
                <div class="text-3xl font-bold text-gray-200">107k</div>
                <div class="flex items-center justify-between mx-2 px-0.5 py-0.5 rounded-xl text-green-500 font-medium ">
                    <div><ion-icon name="arrow-up-outline"></ion-icon></div>
                    <div>35%</div>
                </div>
            </div>
            <div class="text-sm font-medium text-gray-400 ">Last week 97k</div>
        </div>
    </div>
   
</div>
</div> `;
