export const source =`	<!-- nav -->
<nav class="w-full px-6 bg-white shadow-lg relative z-20">
    <div class="container mx-auto max-w-4xl h-16 flex justify-between text-xs md:text-sm">

        <div class="h-full flex items-center">
            <a href="#" class="text-gray-600 no-underline mr-4">Home</a>
            <a href="#" class="text-gray-600 no-underline mr-4">About</a>
        </div>

        <div class="pt-4">
            <a href="#" class="block w-16 h-16 md:w-24 md:h-24 rounded-full border-2 overflow-hidden">
                <img src="https://images.unsplash.com/photo-1522322659370-3c98da30c99c?w=300" class="w-full h-full object-cover" />
            </a>
        </div>

        <div class="h-full flex items-center">
            <a href="#" class="text-gray-600 no-underline ml-4">Products</a>
            <a href="#" class="text-gray-600 no-underline ml-4">Contact</a>
        </div>
    </div>
</nav>
<!-- /nav -->

<!-- hero -->
<div class="w-full py-24 px-6 bg-blue-100 relative z-10">

    <div class="container max-w-4xl mx-auto text-left flex items-center flex-wrap">

        <div class="w-full md:w-2/3 md:pr-6">
            <h1 class="text-2xl md:text-4xl text-gray-900 mb-3">Lorem ipsum dolor sit amet</h1>
            <p class="text-md md:text-lg text-gray-600 leading-normal">
                Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
            </p>

            <a href="/register" class="mt-6 inline-block bg-white text-black no-underline px-4 py-3 shadow-lg">Contact us</a>
        </div>

        <div class="hidden md:block w-1/3">
            <img src="https://images.unsplash.com/photo-1506152983158-b4a74a01c721?w=800" class="w-full h-auto shadow-lg" />
        </div>
    </div>

</div>
<!-- /hero -->


<!-- testimonial -->
<div class="w-full px-6 py-12 bg-gray-600 text-gray-300">
    <div class="container max-w-lg mx-auto text-center">
        <p class="leading-normal mb-6">Thanks guys, keep up the good work! You won't regret it. After using Free Templates my business skyrocketed! I'd be lost without Free Templates.</p>
        <p class="font-bold">Rubie J.</p>
    </div>
</div>
<!-- /testimonial -->

<!-- content -->
<div class="w-full px-6 py-12 bg-white">

    <div class="container max-w-4xl mx-auto flex flex-wrap">
        
        <div class="w-full md:w-1/2 flex flex-wrap">
            <div class="w-full md:w-1/2 p-2">
                <img src="https://images.unsplash.com/photo-1486308510493-aa64833637bc?w=800" class="w-full h-48 object-cover" />
            </div>
            <div class="w-full md:w-1/2 p-2">
                <img src="https://images.unsplash.com/photo-1506629082955-511b1aa562c8?w=800" class="w-full h-48 object-cover" />
            </div>
            <div class="w-full md:w-1/2 p-2">
                <img src="https://images.unsplash.com/photo-1537832816519-689ad163238b?w=800" class="w-full h-48 object-cover" />
            </div>
            <div class="w-full md:w-1/2 p-2">
                <img src="https://images.unsplash.com/photo-1499175898579-d743fea6e7dc?w=800" class="w-full h-48 object-cover" />
            </div>
        </div>

        <div class="w-full md:w-1/2 p-2 md:px-6">
            <h3 class="text-xl md:text-2xl text-gray-900 mb-6">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            </h3>
            
            <p class="mb-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
            quis nostrud exercitation ullamco laboris nisi ut.</p>
            <p class="mb-8">Aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
            proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <a href="#" class="inline-block bg-black text-white text-sm px-4 py-3 no-underline">Browse our products</a>
        </div>

    </div>
</div>
<!-- /content -->

<!-- footer -->
<footer class="w-full bg-gray-300 border-t">
    <div class="container mx-auto max-w-4xl h-full flex flex-wrap md:flex-no-wrap justify-between items-start text-sm p-6 pt-8 pb-4">
        <div class="mb-4">
            <h4 class="mb-4">Quick links</h4>
            <a href="#" class="block text-gray-900 mb-2">About</a>
            <a href="#" class="block text-gray-900 mb-2">Products</a>
            <a href="#" class="block text-gray-900 mb-2">Services</a>
            <a href="#" class="block text-gray-900 mb-2">FAQ</a>
            <a href="#" class="block text-gray-900 mb-2">Contact</a>
            <a href="#" class="block text-gray-900 mb-2">Delivery</a>
        </div>
        <div class="mb-4">
            
            <h4 class="mb-4">Categories</h4>
            <a href="#" class="block text-gray-900 mb-2">Food</a>
            <a href="#" class="block text-gray-900 mb-2">Drink</a>
            <a href="#" class="block text-gray-900 mb-2">Homeware</a>
            <a href="#" class="block text-gray-900 mb-2">Kitchen</a>
            <a href="#" class="block text-gray-900 mb-2">Outdoor</a>
            <a href="#" class="block text-gray-900 mb-2">Lighting</a>
        </div>
        <div class="mb-4">
            <h4 class="mb-4">About Our Company</h4>

            <p class="mb-4 leading-normal">
            108  Stamford Road<br />
            ANGERSLEIGH<br />
            TA3 6NY</p>

            <p class="text-sm">079 6293 6068</p>
        </div>
    </div>
</footer>
<!-- /footer -->

<!-- footer -->
<footer class="w-full bg-white px-6 border-t">
    <div class="container mx-auto max-w-4xl py-6 flex flex-wrap md:flex-no-wrap justify-between items-center text-sm">
        &copy;2019 Your Company. All rights reserved.
        <div class="pt-4 md:p-0 text-center md:text-right text-xs">
            <a href="#" class="text-black no-underline hover:underline">Privacy Policy</a>
            <a href="#" class="text-black no-underline hover:underline ml-4">Terms &amp; Conditions</a>
            <a href="#" class="text-black no-underline hover:underline ml-4">Contact Us</a>
        </div>
    </div>
</footer>
<!-- /footer -->`;