export const source = `  <!-- ====== Lists Section Start -->
<section class="bg-white py-20 lg:py-[120px] dark:bg-dark">
  <div class="container mx-auto">
    <div class="-mx-4 flex flex-wrap">
      <div class="w-full px-4">
        <h3
          class="mb-10 text-[26px] font-semibold text-dark dark:text-white"
        >
          Order list
        </h3>
      </div>

      <div class="w-full px-4 lg:w-1/3">
        <div class="mb-12">
          <ol class="space-y-3">
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span
                class="mr-2.5 flex h-[26px] w-full max-w-[26px] items-center justify-center rounded-full bg-primary text-base text-white"
              >
                1
              </span>
              Lorem ipsum dolor sit amet
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span
                class="mr-2.5 flex h-[26px] w-full max-w-[26px] items-center justify-center rounded-full bg-primary text-base text-white"
              >
                2
              </span>
              It is a long established fact reader
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span
                class="mr-2.5 flex h-[26px] w-full max-w-[26px] items-center justify-center rounded-full bg-primary text-base text-white"
              >
                3
              </span>
              The point of using Lorem Ipsum
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span
                class="mr-2.5 flex h-[26px] w-full max-w-[26px] items-center justify-center rounded-full bg-primary text-base text-white"
              >
                4
              </span>
              There are many variations of passages
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span
                class="mr-2.5 flex h-[26px] w-full max-w-[26px] items-center justify-center rounded-full bg-primary text-base text-white"
              >
                5
              </span>
              If you are going to use a of Lorem
            </li>
          </ol>
        </div>
      </div>

      <div class="w-full px-4 lg:w-1/3">
        <div class="mb-12">
          <ol class="space-y-3">
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span
                class="mr-2.5 flex h-[26px] w-full max-w-[26px] items-center justify-center rounded bg-primary text-base text-white"
              >
                1
              </span>
              Lorem ipsum dolor sit amet
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span
                class="mr-2.5 flex h-[26px] w-full max-w-[26px] items-center justify-center rounded bg-primary text-base text-white"
              >
                2
              </span>
              It is a long established fact reader
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span
                class="mr-2.5 flex h-[26px] w-full max-w-[26px] items-center justify-center rounded bg-primary text-base text-white"
              >
                3
              </span>
              The point of using Lorem Ipsum
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span
                class="mr-2.5 flex h-[26px] w-full max-w-[26px] items-center justify-center rounded bg-primary text-base text-white"
              >
                4
              </span>
              There are many variations of passages
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span
                class="mr-2.5 flex h-[26px] w-full max-w-[26px] items-center justify-center rounded bg-primary text-base text-white"
              >
                5
              </span>
              If you are going to use a of Lorem
            </li>
          </ol>
        </div>
      </div>

      <div class="w-full px-4 lg:w-1/3">
        <div class="mb-12">
          <ol class="space-y-4">
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span
                class="relative z-10 mr-2.5 flex h-[26px] w-full max-w-[26px] items-center justify-center rounded text-base text-white"
              >
                <span
                  class="absolute left-0 top-0 z-[-1] h-full w-full -rotate-45 rounded bg-primary"
                ></span>
                1
              </span>
              Lorem ipsum dolor sit amet
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span
                class="relative z-10 mr-2.5 flex h-[26px] w-full max-w-[26px] items-center justify-center rounded text-base text-white"
              >
                <span
                  class="absolute left-0 top-0 z-[-1] h-full w-full -rotate-45 rounded bg-primary"
                ></span>
                2
              </span>
              It is a long established fact reader
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span
                class="relative z-10 mr-2.5 flex h-[26px] w-full max-w-[26px] items-center justify-center rounded text-base text-white"
              >
                <span
                  class="absolute left-0 top-0 z-[-1] h-full w-full -rotate-45 rounded bg-primary"
                ></span>
                3
              </span>
              The point of using Lorem Ipsum
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span
                class="relative z-10 mr-2.5 flex h-[26px] w-full max-w-[26px] items-center justify-center rounded text-base text-white"
              >
                <span
                  class="absolute left-0 top-0 z-[-1] h-full w-full -rotate-45 rounded bg-primary"
                ></span>
                4
              </span>
              There are many variations of passages
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span
                class="relative z-10 mr-2.5 flex h-[26px] w-full max-w-[26px] items-center justify-center rounded text-base text-white"
              >
                <span
                  class="absolute left-0 top-0 z-[-1] h-full w-full -rotate-45 rounded bg-primary"
                ></span>
                5
              </span>
              If you are going to use a of Lorem
            </li>
          </ol>
        </div>
      </div>

      <div class="w-full px-4">
        <h3
          class="mb-10 text-[26px] font-semibold text-dark dark:text-white"
        >
          Unorder list
        </h3>
      </div>

      <div class="w-full px-4 lg:w-1/3">
        <div class="mb-12">
          <ul class="space-y-3">
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span
                class="mr-2.5 mt-2 flex h-2 w-full max-w-[8px] items-center justify-center rounded-full bg-primary text-base"
              >
              </span>
              Lorem ipsum dolor sit amet
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span
                class="mr-2.5 mt-2 flex h-2 w-full max-w-[8px] items-center justify-center rounded-full bg-primary text-base"
              >
              </span>
              It is a long established fact reader
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span
                class="mr-2.5 mt-2 flex h-2 w-full max-w-[8px] items-center justify-center rounded-full bg-primary text-base"
              >
              </span>
              The point of using Lorem Ipsum
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span
                class="mr-2.5 mt-2 flex h-2 w-full max-w-[8px] items-center justify-center rounded-full bg-primary text-base"
              >
              </span>
              There are many variations of passages
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span
                class="mr-2.5 mt-2 flex h-2 w-full max-w-[8px] items-center justify-center rounded-full bg-primary text-base"
              >
              </span>
              If you are going to use a of Lorem
            </li>
          </ul>
        </div>
      </div>

      <div class="w-full px-4 lg:w-1/3">
        <div class="mb-12">
          <ul class="space-y-3">
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span class="mr-2.5 mt-0.5 text-primary">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_980_24852)">
                    <path
                      d="M10 0.5625C4.78125 0.5625 0.5625 4.78125 0.5625 10C0.5625 15.2188 4.78125 19.4688 10 19.4688C15.2188 19.4688 19.4688 15.2188 19.4688 10C19.4688 4.78125 15.2188 0.5625 10 0.5625ZM10 18.0625C5.5625 18.0625 1.96875 14.4375 1.96875 10C1.96875 5.5625 5.5625 1.96875 10 1.96875C14.4375 1.96875 18.0625 5.59375 18.0625 10.0312C18.0625 14.4375 14.4375 18.0625 10 18.0625Z"
                      fill="currentColor"
                    />
                    <path
                      d="M12.6875 7.09375L8.96875 10.7188L7.28125 9.0625C7 8.78125 6.5625 8.8125 6.28125 9.0625C6 9.34375 6.03125 9.78125 6.28125 10.0625L8.28125 12C8.46875 12.1875 8.71875 12.2813 8.96875 12.2813C9.21875 12.2813 9.46875 12.1875 9.65625 12L13.6875 8.125C13.9688 7.84375 13.9688 7.40625 13.6875 7.125C13.4063 6.84375 12.9688 6.84375 12.6875 7.09375Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_980_24852">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              Lorem ipsum dolor sit amet
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span class="mr-2.5 mt-0.5 text-primary">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_980_24852)">
                    <path
                      d="M10 0.5625C4.78125 0.5625 0.5625 4.78125 0.5625 10C0.5625 15.2188 4.78125 19.4688 10 19.4688C15.2188 19.4688 19.4688 15.2188 19.4688 10C19.4688 4.78125 15.2188 0.5625 10 0.5625ZM10 18.0625C5.5625 18.0625 1.96875 14.4375 1.96875 10C1.96875 5.5625 5.5625 1.96875 10 1.96875C14.4375 1.96875 18.0625 5.59375 18.0625 10.0312C18.0625 14.4375 14.4375 18.0625 10 18.0625Z"
                      fill="currentColor"
                    />
                    <path
                      d="M12.6875 7.09375L8.96875 10.7188L7.28125 9.0625C7 8.78125 6.5625 8.8125 6.28125 9.0625C6 9.34375 6.03125 9.78125 6.28125 10.0625L8.28125 12C8.46875 12.1875 8.71875 12.2813 8.96875 12.2813C9.21875 12.2813 9.46875 12.1875 9.65625 12L13.6875 8.125C13.9688 7.84375 13.9688 7.40625 13.6875 7.125C13.4063 6.84375 12.9688 6.84375 12.6875 7.09375Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_980_24852">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              It is a long established fact reader
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span class="mr-2.5 mt-0.5 text-primary">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_980_24852)">
                    <path
                      d="M10 0.5625C4.78125 0.5625 0.5625 4.78125 0.5625 10C0.5625 15.2188 4.78125 19.4688 10 19.4688C15.2188 19.4688 19.4688 15.2188 19.4688 10C19.4688 4.78125 15.2188 0.5625 10 0.5625ZM10 18.0625C5.5625 18.0625 1.96875 14.4375 1.96875 10C1.96875 5.5625 5.5625 1.96875 10 1.96875C14.4375 1.96875 18.0625 5.59375 18.0625 10.0312C18.0625 14.4375 14.4375 18.0625 10 18.0625Z"
                      fill="currentColor"
                    />
                    <path
                      d="M12.6875 7.09375L8.96875 10.7188L7.28125 9.0625C7 8.78125 6.5625 8.8125 6.28125 9.0625C6 9.34375 6.03125 9.78125 6.28125 10.0625L8.28125 12C8.46875 12.1875 8.71875 12.2813 8.96875 12.2813C9.21875 12.2813 9.46875 12.1875 9.65625 12L13.6875 8.125C13.9688 7.84375 13.9688 7.40625 13.6875 7.125C13.4063 6.84375 12.9688 6.84375 12.6875 7.09375Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_980_24852">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              The point of using Lorem Ipsum
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span class="mr-2.5 mt-0.5 text-primary">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_980_24852)">
                    <path
                      d="M10 0.5625C4.78125 0.5625 0.5625 4.78125 0.5625 10C0.5625 15.2188 4.78125 19.4688 10 19.4688C15.2188 19.4688 19.4688 15.2188 19.4688 10C19.4688 4.78125 15.2188 0.5625 10 0.5625ZM10 18.0625C5.5625 18.0625 1.96875 14.4375 1.96875 10C1.96875 5.5625 5.5625 1.96875 10 1.96875C14.4375 1.96875 18.0625 5.59375 18.0625 10.0312C18.0625 14.4375 14.4375 18.0625 10 18.0625Z"
                      fill="currentColor"
                    />
                    <path
                      d="M12.6875 7.09375L8.96875 10.7188L7.28125 9.0625C7 8.78125 6.5625 8.8125 6.28125 9.0625C6 9.34375 6.03125 9.78125 6.28125 10.0625L8.28125 12C8.46875 12.1875 8.71875 12.2813 8.96875 12.2813C9.21875 12.2813 9.46875 12.1875 9.65625 12L13.6875 8.125C13.9688 7.84375 13.9688 7.40625 13.6875 7.125C13.4063 6.84375 12.9688 6.84375 12.6875 7.09375Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_980_24852">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              There are many variations of passages
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span class="mr-2.5 mt-0.5 text-primary">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_980_24852)">
                    <path
                      d="M10 0.5625C4.78125 0.5625 0.5625 4.78125 0.5625 10C0.5625 15.2188 4.78125 19.4688 10 19.4688C15.2188 19.4688 19.4688 15.2188 19.4688 10C19.4688 4.78125 15.2188 0.5625 10 0.5625ZM10 18.0625C5.5625 18.0625 1.96875 14.4375 1.96875 10C1.96875 5.5625 5.5625 1.96875 10 1.96875C14.4375 1.96875 18.0625 5.59375 18.0625 10.0312C18.0625 14.4375 14.4375 18.0625 10 18.0625Z"
                      fill="currentColor"
                    />
                    <path
                      d="M12.6875 7.09375L8.96875 10.7188L7.28125 9.0625C7 8.78125 6.5625 8.8125 6.28125 9.0625C6 9.34375 6.03125 9.78125 6.28125 10.0625L8.28125 12C8.46875 12.1875 8.71875 12.2813 8.96875 12.2813C9.21875 12.2813 9.46875 12.1875 9.65625 12L13.6875 8.125C13.9688 7.84375 13.9688 7.40625 13.6875 7.125C13.4063 6.84375 12.9688 6.84375 12.6875 7.09375Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_980_24852">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              If you are going to use a of Lorem
            </li>
          </ul>
        </div>
      </div>

      <div class="w-full px-4 lg:w-1/3">
        <div class="mb-12">
          <ul class="space-y-3">
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span class="mr-2.5 mt-0.5 text-secondary">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_980_24852)">
                    <path
                      d="M10 0.5625C4.78125 0.5625 0.5625 4.78125 0.5625 10C0.5625 15.2188 4.78125 19.4688 10 19.4688C15.2188 19.4688 19.4688 15.2188 19.4688 10C19.4688 4.78125 15.2188 0.5625 10 0.5625ZM10 18.0625C5.5625 18.0625 1.96875 14.4375 1.96875 10C1.96875 5.5625 5.5625 1.96875 10 1.96875C14.4375 1.96875 18.0625 5.59375 18.0625 10.0312C18.0625 14.4375 14.4375 18.0625 10 18.0625Z"
                      fill="currentColor"
                    />
                    <path
                      d="M12.6875 7.09375L8.96875 10.7188L7.28125 9.0625C7 8.78125 6.5625 8.8125 6.28125 9.0625C6 9.34375 6.03125 9.78125 6.28125 10.0625L8.28125 12C8.46875 12.1875 8.71875 12.2813 8.96875 12.2813C9.21875 12.2813 9.46875 12.1875 9.65625 12L13.6875 8.125C13.9688 7.84375 13.9688 7.40625 13.6875 7.125C13.4063 6.84375 12.9688 6.84375 12.6875 7.09375Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_980_24852">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              Lorem ipsum dolor sit amet
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span class="mr-2.5 mt-0.5 text-secondary">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_980_24852)">
                    <path
                      d="M10 0.5625C4.78125 0.5625 0.5625 4.78125 0.5625 10C0.5625 15.2188 4.78125 19.4688 10 19.4688C15.2188 19.4688 19.4688 15.2188 19.4688 10C19.4688 4.78125 15.2188 0.5625 10 0.5625ZM10 18.0625C5.5625 18.0625 1.96875 14.4375 1.96875 10C1.96875 5.5625 5.5625 1.96875 10 1.96875C14.4375 1.96875 18.0625 5.59375 18.0625 10.0312C18.0625 14.4375 14.4375 18.0625 10 18.0625Z"
                      fill="currentColor"
                    />
                    <path
                      d="M12.6875 7.09375L8.96875 10.7188L7.28125 9.0625C7 8.78125 6.5625 8.8125 6.28125 9.0625C6 9.34375 6.03125 9.78125 6.28125 10.0625L8.28125 12C8.46875 12.1875 8.71875 12.2813 8.96875 12.2813C9.21875 12.2813 9.46875 12.1875 9.65625 12L13.6875 8.125C13.9688 7.84375 13.9688 7.40625 13.6875 7.125C13.4063 6.84375 12.9688 6.84375 12.6875 7.09375Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_980_24852">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              It is a long established fact reader
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span class="mr-2.5 mt-0.5 text-secondary">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_980_24852)">
                    <path
                      d="M10 0.5625C4.78125 0.5625 0.5625 4.78125 0.5625 10C0.5625 15.2188 4.78125 19.4688 10 19.4688C15.2188 19.4688 19.4688 15.2188 19.4688 10C19.4688 4.78125 15.2188 0.5625 10 0.5625ZM10 18.0625C5.5625 18.0625 1.96875 14.4375 1.96875 10C1.96875 5.5625 5.5625 1.96875 10 1.96875C14.4375 1.96875 18.0625 5.59375 18.0625 10.0312C18.0625 14.4375 14.4375 18.0625 10 18.0625Z"
                      fill="currentColor"
                    />
                    <path
                      d="M12.6875 7.09375L8.96875 10.7188L7.28125 9.0625C7 8.78125 6.5625 8.8125 6.28125 9.0625C6 9.34375 6.03125 9.78125 6.28125 10.0625L8.28125 12C8.46875 12.1875 8.71875 12.2813 8.96875 12.2813C9.21875 12.2813 9.46875 12.1875 9.65625 12L13.6875 8.125C13.9688 7.84375 13.9688 7.40625 13.6875 7.125C13.4063 6.84375 12.9688 6.84375 12.6875 7.09375Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_980_24852">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              The point of using Lorem Ipsum
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span class="mr-2.5 mt-0.5 text-secondary">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_980_24852)">
                    <path
                      d="M10 0.5625C4.78125 0.5625 0.5625 4.78125 0.5625 10C0.5625 15.2188 4.78125 19.4688 10 19.4688C15.2188 19.4688 19.4688 15.2188 19.4688 10C19.4688 4.78125 15.2188 0.5625 10 0.5625ZM10 18.0625C5.5625 18.0625 1.96875 14.4375 1.96875 10C1.96875 5.5625 5.5625 1.96875 10 1.96875C14.4375 1.96875 18.0625 5.59375 18.0625 10.0312C18.0625 14.4375 14.4375 18.0625 10 18.0625Z"
                      fill="currentColor"
                    />
                    <path
                      d="M12.6875 7.09375L8.96875 10.7188L7.28125 9.0625C7 8.78125 6.5625 8.8125 6.28125 9.0625C6 9.34375 6.03125 9.78125 6.28125 10.0625L8.28125 12C8.46875 12.1875 8.71875 12.2813 8.96875 12.2813C9.21875 12.2813 9.46875 12.1875 9.65625 12L13.6875 8.125C13.9688 7.84375 13.9688 7.40625 13.6875 7.125C13.4063 6.84375 12.9688 6.84375 12.6875 7.09375Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_980_24852">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              There are many variations of passages
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span class="mr-2.5 mt-0.5 text-secondary">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_980_24852)">
                    <path
                      d="M10 0.5625C4.78125 0.5625 0.5625 4.78125 0.5625 10C0.5625 15.2188 4.78125 19.4688 10 19.4688C15.2188 19.4688 19.4688 15.2188 19.4688 10C19.4688 4.78125 15.2188 0.5625 10 0.5625ZM10 18.0625C5.5625 18.0625 1.96875 14.4375 1.96875 10C1.96875 5.5625 5.5625 1.96875 10 1.96875C14.4375 1.96875 18.0625 5.59375 18.0625 10.0312C18.0625 14.4375 14.4375 18.0625 10 18.0625Z"
                      fill="currentColor"
                    />
                    <path
                      d="M12.6875 7.09375L8.96875 10.7188L7.28125 9.0625C7 8.78125 6.5625 8.8125 6.28125 9.0625C6 9.34375 6.03125 9.78125 6.28125 10.0625L8.28125 12C8.46875 12.1875 8.71875 12.2813 8.96875 12.2813C9.21875 12.2813 9.46875 12.1875 9.65625 12L13.6875 8.125C13.9688 7.84375 13.9688 7.40625 13.6875 7.125C13.4063 6.84375 12.9688 6.84375 12.6875 7.09375Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_980_24852">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              If you are going to use a of Lorem
            </li>
          </ul>
        </div>
      </div>

      <div class="w-full px-4 lg:w-1/3">
        <div class="mb-12">
          <ul class="space-y-3">
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span class="mr-2.5 flex items-center text-base text-red">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_980_24913)">
                    <path
                      d="M10 0.5625C4.78125 0.5625 0.5625 4.78125 0.5625 10C0.5625 15.2188 4.78125 19.4688 10 19.4688C15.2188 19.4688 19.4688 15.2188 19.4688 10C19.4688 4.78125 15.2188 0.5625 10 0.5625ZM10 18.0625C5.5625 18.0625 1.96875 14.4375 1.96875 10C1.96875 5.5625 5.5625 1.96875 10 1.96875C14.4375 1.96875 18.0625 5.59375 18.0625 10.0312C18.0625 14.4375 14.4375 18.0625 10 18.0625Z"
                      fill="currentColor"
                    />
                    <path
                      d="M12.875 7.09375C12.5938 6.8125 12.1563 6.8125 11.875 7.09375L10 9L8.09375 7.09375C7.8125 6.8125 7.375 6.8125 7.09375 7.09375C6.8125 7.375 6.8125 7.8125 7.09375 8.09375L9 10L7.09375 11.9062C6.8125 12.1875 6.8125 12.625 7.09375 12.9062C7.21875 13.0312 7.40625 13.125 7.59375 13.125C7.78125 13.125 7.96875 13.0625 8.09375 12.9062L10 11L11.9062 12.9062C12.0312 13.0312 12.2188 13.125 12.4063 13.125C12.5938 13.125 12.7812 13.0625 12.9062 12.9062C13.1875 12.625 13.1875 12.1875 12.9062 11.9062L11 10L12.9062 8.09375C13.1562 7.8125 13.1563 7.375 12.875 7.09375Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_980_24913">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              Lorem ipsum dolor sit amet
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span class="mr-2.5 flex items-center text-base text-red">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_980_24913)">
                    <path
                      d="M10 0.5625C4.78125 0.5625 0.5625 4.78125 0.5625 10C0.5625 15.2188 4.78125 19.4688 10 19.4688C15.2188 19.4688 19.4688 15.2188 19.4688 10C19.4688 4.78125 15.2188 0.5625 10 0.5625ZM10 18.0625C5.5625 18.0625 1.96875 14.4375 1.96875 10C1.96875 5.5625 5.5625 1.96875 10 1.96875C14.4375 1.96875 18.0625 5.59375 18.0625 10.0312C18.0625 14.4375 14.4375 18.0625 10 18.0625Z"
                      fill="currentColor"
                    />
                    <path
                      d="M12.875 7.09375C12.5938 6.8125 12.1563 6.8125 11.875 7.09375L10 9L8.09375 7.09375C7.8125 6.8125 7.375 6.8125 7.09375 7.09375C6.8125 7.375 6.8125 7.8125 7.09375 8.09375L9 10L7.09375 11.9062C6.8125 12.1875 6.8125 12.625 7.09375 12.9062C7.21875 13.0312 7.40625 13.125 7.59375 13.125C7.78125 13.125 7.96875 13.0625 8.09375 12.9062L10 11L11.9062 12.9062C12.0312 13.0312 12.2188 13.125 12.4063 13.125C12.5938 13.125 12.7812 13.0625 12.9062 12.9062C13.1875 12.625 13.1875 12.1875 12.9062 11.9062L11 10L12.9062 8.09375C13.1562 7.8125 13.1563 7.375 12.875 7.09375Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_980_24913">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              It is a long established fact reader
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span class="mr-2.5 flex items-center text-base text-red">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_980_24913)">
                    <path
                      d="M10 0.5625C4.78125 0.5625 0.5625 4.78125 0.5625 10C0.5625 15.2188 4.78125 19.4688 10 19.4688C15.2188 19.4688 19.4688 15.2188 19.4688 10C19.4688 4.78125 15.2188 0.5625 10 0.5625ZM10 18.0625C5.5625 18.0625 1.96875 14.4375 1.96875 10C1.96875 5.5625 5.5625 1.96875 10 1.96875C14.4375 1.96875 18.0625 5.59375 18.0625 10.0312C18.0625 14.4375 14.4375 18.0625 10 18.0625Z"
                      fill="currentColor"
                    />
                    <path
                      d="M12.875 7.09375C12.5938 6.8125 12.1563 6.8125 11.875 7.09375L10 9L8.09375 7.09375C7.8125 6.8125 7.375 6.8125 7.09375 7.09375C6.8125 7.375 6.8125 7.8125 7.09375 8.09375L9 10L7.09375 11.9062C6.8125 12.1875 6.8125 12.625 7.09375 12.9062C7.21875 13.0312 7.40625 13.125 7.59375 13.125C7.78125 13.125 7.96875 13.0625 8.09375 12.9062L10 11L11.9062 12.9062C12.0312 13.0312 12.2188 13.125 12.4063 13.125C12.5938 13.125 12.7812 13.0625 12.9062 12.9062C13.1875 12.625 13.1875 12.1875 12.9062 11.9062L11 10L12.9062 8.09375C13.1562 7.8125 13.1563 7.375 12.875 7.09375Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_980_24913">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              The point of using Lorem Ipsum
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span class="mr-2.5 flex items-center text-base text-red">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_980_24913)">
                    <path
                      d="M10 0.5625C4.78125 0.5625 0.5625 4.78125 0.5625 10C0.5625 15.2188 4.78125 19.4688 10 19.4688C15.2188 19.4688 19.4688 15.2188 19.4688 10C19.4688 4.78125 15.2188 0.5625 10 0.5625ZM10 18.0625C5.5625 18.0625 1.96875 14.4375 1.96875 10C1.96875 5.5625 5.5625 1.96875 10 1.96875C14.4375 1.96875 18.0625 5.59375 18.0625 10.0312C18.0625 14.4375 14.4375 18.0625 10 18.0625Z"
                      fill="currentColor"
                    />
                    <path
                      d="M12.875 7.09375C12.5938 6.8125 12.1563 6.8125 11.875 7.09375L10 9L8.09375 7.09375C7.8125 6.8125 7.375 6.8125 7.09375 7.09375C6.8125 7.375 6.8125 7.8125 7.09375 8.09375L9 10L7.09375 11.9062C6.8125 12.1875 6.8125 12.625 7.09375 12.9062C7.21875 13.0312 7.40625 13.125 7.59375 13.125C7.78125 13.125 7.96875 13.0625 8.09375 12.9062L10 11L11.9062 12.9062C12.0312 13.0312 12.2188 13.125 12.4063 13.125C12.5938 13.125 12.7812 13.0625 12.9062 12.9062C13.1875 12.625 13.1875 12.1875 12.9062 11.9062L11 10L12.9062 8.09375C13.1562 7.8125 13.1563 7.375 12.875 7.09375Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_980_24913">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              There are many variations of passages
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span class="mr-2.5 flex items-center text-base text-red">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_980_24913)">
                    <path
                      d="M10 0.5625C4.78125 0.5625 0.5625 4.78125 0.5625 10C0.5625 15.2188 4.78125 19.4688 10 19.4688C15.2188 19.4688 19.4688 15.2188 19.4688 10C19.4688 4.78125 15.2188 0.5625 10 0.5625ZM10 18.0625C5.5625 18.0625 1.96875 14.4375 1.96875 10C1.96875 5.5625 5.5625 1.96875 10 1.96875C14.4375 1.96875 18.0625 5.59375 18.0625 10.0312C18.0625 14.4375 14.4375 18.0625 10 18.0625Z"
                      fill="currentColor"
                    />
                    <path
                      d="M12.875 7.09375C12.5938 6.8125 12.1563 6.8125 11.875 7.09375L10 9L8.09375 7.09375C7.8125 6.8125 7.375 6.8125 7.09375 7.09375C6.8125 7.375 6.8125 7.8125 7.09375 8.09375L9 10L7.09375 11.9062C6.8125 12.1875 6.8125 12.625 7.09375 12.9062C7.21875 13.0312 7.40625 13.125 7.59375 13.125C7.78125 13.125 7.96875 13.0625 8.09375 12.9062L10 11L11.9062 12.9062C12.0312 13.0312 12.2188 13.125 12.4063 13.125C12.5938 13.125 12.7812 13.0625 12.9062 12.9062C13.1875 12.625 13.1875 12.1875 12.9062 11.9062L11 10L12.9062 8.09375C13.1562 7.8125 13.1563 7.375 12.875 7.09375Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_980_24913">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              If you are going to use a of Lorem
            </li>
          </ul>
        </div>
      </div>

      <div class="w-full px-4 lg:w-1/3">
        <div class="mb-12">
          <ul class="space-y-3">
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span class="mr-2.5 mt-0.5 text-primary">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 9.5L11.5312 2.9375C11.25 2.65625 10.8125 2.65625 10.5312 2.9375C10.25 3.21875 10.25 3.65625 10.5312 3.9375L15.7812 9.28125H2.5C2.125 9.28125 1.8125 9.59375 1.8125 9.96875C1.8125 10.3438 2.125 10.6875 2.5 10.6875H15.8437L10.5312 16.0938C10.25 16.375 10.25 16.8125 10.5312 17.0938C10.6562 17.2188 10.8437 17.2812 11.0312 17.2812C11.2187 17.2812 11.4062 17.2188 11.5312 17.0625L18 10.5C18.2812 10.2187 18.2812 9.78125 18 9.5Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
              Lorem ipsum dolor sit amet
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span class="mr-2.5 mt-0.5 text-primary">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 9.5L11.5312 2.9375C11.25 2.65625 10.8125 2.65625 10.5312 2.9375C10.25 3.21875 10.25 3.65625 10.5312 3.9375L15.7812 9.28125H2.5C2.125 9.28125 1.8125 9.59375 1.8125 9.96875C1.8125 10.3438 2.125 10.6875 2.5 10.6875H15.8437L10.5312 16.0938C10.25 16.375 10.25 16.8125 10.5312 17.0938C10.6562 17.2188 10.8437 17.2812 11.0312 17.2812C11.2187 17.2812 11.4062 17.2188 11.5312 17.0625L18 10.5C18.2812 10.2187 18.2812 9.78125 18 9.5Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
              It is a long established fact reader
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span class="mr-2.5 mt-0.5 text-primary">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 9.5L11.5312 2.9375C11.25 2.65625 10.8125 2.65625 10.5312 2.9375C10.25 3.21875 10.25 3.65625 10.5312 3.9375L15.7812 9.28125H2.5C2.125 9.28125 1.8125 9.59375 1.8125 9.96875C1.8125 10.3438 2.125 10.6875 2.5 10.6875H15.8437L10.5312 16.0938C10.25 16.375 10.25 16.8125 10.5312 17.0938C10.6562 17.2188 10.8437 17.2812 11.0312 17.2812C11.2187 17.2812 11.4062 17.2188 11.5312 17.0625L18 10.5C18.2812 10.2187 18.2812 9.78125 18 9.5Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
              The point of using Lorem Ipsum
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span class="mr-2.5 mt-0.5 text-primary">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 9.5L11.5312 2.9375C11.25 2.65625 10.8125 2.65625 10.5312 2.9375C10.25 3.21875 10.25 3.65625 10.5312 3.9375L15.7812 9.28125H2.5C2.125 9.28125 1.8125 9.59375 1.8125 9.96875C1.8125 10.3438 2.125 10.6875 2.5 10.6875H15.8437L10.5312 16.0938C10.25 16.375 10.25 16.8125 10.5312 17.0938C10.6562 17.2188 10.8437 17.2812 11.0312 17.2812C11.2187 17.2812 11.4062 17.2188 11.5312 17.0625L18 10.5C18.2812 10.2187 18.2812 9.78125 18 9.5Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
              There are many variations of passages
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span class="mr-2.5 mt-0.5 text-primary">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 9.5L11.5312 2.9375C11.25 2.65625 10.8125 2.65625 10.5312 2.9375C10.25 3.21875 10.25 3.65625 10.5312 3.9375L15.7812 9.28125H2.5C2.125 9.28125 1.8125 9.59375 1.8125 9.96875C1.8125 10.3438 2.125 10.6875 2.5 10.6875H15.8437L10.5312 16.0938C10.25 16.375 10.25 16.8125 10.5312 17.0938C10.6562 17.2188 10.8437 17.2812 11.0312 17.2812C11.2187 17.2812 11.4062 17.2188 11.5312 17.0625L18 10.5C18.2812 10.2187 18.2812 9.78125 18 9.5Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
              If you are going to use a of Lorem
            </li>
          </ul>
        </div>
      </div>

      <div class="w-full px-4 lg:w-1/3">
        <div class="mb-12">
          <ul class="space-y-3">
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span class="mr-2.5 text-primary">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_980_24878)">
                    <path
                      d="M5.03124 19.4375C4.74999 19.4375 4.46874 19.3437 4.24999 19.1875C3.81249 18.875 3.56249 18.3125 3.65624 17.7812L4.46874 12.75L0.968744 9.15625C0.593744 8.78125 0.468744 8.21875 0.624994 7.6875C0.781244 7.1875 1.21874 6.8125 1.71874 6.75L6.56249 5.96875L8.74999 1.375C8.99999 0.875 9.46874 0.5625 9.99999 0.5625C10.5312 0.5625 11.0312 0.875 11.25 1.375L13.4375 5.9375L18.25 6.6875C18.75 6.78125 19.1875 7.125 19.3437 7.625C19.5312 8.15625 19.375 8.71875 19 9.09375L15.5312 12.7187L16.3437 17.7812C16.4375 18.3437 16.2187 18.875 15.75 19.1875C15.3125 19.5 14.7812 19.5312 14.3125 19.2812L9.99999 16.9375L5.68749 19.2812C5.49999 19.4062 5.24999 19.4375 5.03124 19.4375ZM1.96874 8.125C1.96874 8.125 1.96874 8.15625 1.96874 8.1875L5.62499 11.9375C5.84374 12.1562 5.93749 12.5 5.90624 12.8125L5.06249 18.0312C5.06249 18.0312 5.06249 18.0312 5.06249 18.0625L9.56249 15.625C9.84374 15.4687 10.1875 15.4687 10.5 15.625L15 18.0625C15 18.0625 15 18.0625 15 18.0312L14.1562 12.7812C14.0937 12.4688 14.2187 12.1562 14.4375 11.9062L18.0937 8.15625C18.125 8.125 18.0937 8.09375 18.0937 8.09375L13.0625 7.3125C12.75 7.25 12.4687 7.0625 12.3437 6.75L9.99999 2L7.74999 6.78125C7.62499 7.0625 7.34374 7.28125 7.03124 7.34375L1.96874 8.125Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_980_24878">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              Lorem ipsum dolor sit amet
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span class="mr-2.5 text-primary">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_980_24878)">
                    <path
                      d="M5.03124 19.4375C4.74999 19.4375 4.46874 19.3437 4.24999 19.1875C3.81249 18.875 3.56249 18.3125 3.65624 17.7812L4.46874 12.75L0.968744 9.15625C0.593744 8.78125 0.468744 8.21875 0.624994 7.6875C0.781244 7.1875 1.21874 6.8125 1.71874 6.75L6.56249 5.96875L8.74999 1.375C8.99999 0.875 9.46874 0.5625 9.99999 0.5625C10.5312 0.5625 11.0312 0.875 11.25 1.375L13.4375 5.9375L18.25 6.6875C18.75 6.78125 19.1875 7.125 19.3437 7.625C19.5312 8.15625 19.375 8.71875 19 9.09375L15.5312 12.7187L16.3437 17.7812C16.4375 18.3437 16.2187 18.875 15.75 19.1875C15.3125 19.5 14.7812 19.5312 14.3125 19.2812L9.99999 16.9375L5.68749 19.2812C5.49999 19.4062 5.24999 19.4375 5.03124 19.4375ZM1.96874 8.125C1.96874 8.125 1.96874 8.15625 1.96874 8.1875L5.62499 11.9375C5.84374 12.1562 5.93749 12.5 5.90624 12.8125L5.06249 18.0312C5.06249 18.0312 5.06249 18.0312 5.06249 18.0625L9.56249 15.625C9.84374 15.4687 10.1875 15.4687 10.5 15.625L15 18.0625C15 18.0625 15 18.0625 15 18.0312L14.1562 12.7812C14.0937 12.4688 14.2187 12.1562 14.4375 11.9062L18.0937 8.15625C18.125 8.125 18.0937 8.09375 18.0937 8.09375L13.0625 7.3125C12.75 7.25 12.4687 7.0625 12.3437 6.75L9.99999 2L7.74999 6.78125C7.62499 7.0625 7.34374 7.28125 7.03124 7.34375L1.96874 8.125Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_980_24878">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              It is a long established fact reader
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span class="mr-2.5 text-primary">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_980_24878)">
                    <path
                      d="M5.03124 19.4375C4.74999 19.4375 4.46874 19.3437 4.24999 19.1875C3.81249 18.875 3.56249 18.3125 3.65624 17.7812L4.46874 12.75L0.968744 9.15625C0.593744 8.78125 0.468744 8.21875 0.624994 7.6875C0.781244 7.1875 1.21874 6.8125 1.71874 6.75L6.56249 5.96875L8.74999 1.375C8.99999 0.875 9.46874 0.5625 9.99999 0.5625C10.5312 0.5625 11.0312 0.875 11.25 1.375L13.4375 5.9375L18.25 6.6875C18.75 6.78125 19.1875 7.125 19.3437 7.625C19.5312 8.15625 19.375 8.71875 19 9.09375L15.5312 12.7187L16.3437 17.7812C16.4375 18.3437 16.2187 18.875 15.75 19.1875C15.3125 19.5 14.7812 19.5312 14.3125 19.2812L9.99999 16.9375L5.68749 19.2812C5.49999 19.4062 5.24999 19.4375 5.03124 19.4375ZM1.96874 8.125C1.96874 8.125 1.96874 8.15625 1.96874 8.1875L5.62499 11.9375C5.84374 12.1562 5.93749 12.5 5.90624 12.8125L5.06249 18.0312C5.06249 18.0312 5.06249 18.0312 5.06249 18.0625L9.56249 15.625C9.84374 15.4687 10.1875 15.4687 10.5 15.625L15 18.0625C15 18.0625 15 18.0625 15 18.0312L14.1562 12.7812C14.0937 12.4688 14.2187 12.1562 14.4375 11.9062L18.0937 8.15625C18.125 8.125 18.0937 8.09375 18.0937 8.09375L13.0625 7.3125C12.75 7.25 12.4687 7.0625 12.3437 6.75L9.99999 2L7.74999 6.78125C7.62499 7.0625 7.34374 7.28125 7.03124 7.34375L1.96874 8.125Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_980_24878">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              The point of using Lorem Ipsum
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span class="mr-2.5 text-primary">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_980_24878)">
                    <path
                      d="M5.03124 19.4375C4.74999 19.4375 4.46874 19.3437 4.24999 19.1875C3.81249 18.875 3.56249 18.3125 3.65624 17.7812L4.46874 12.75L0.968744 9.15625C0.593744 8.78125 0.468744 8.21875 0.624994 7.6875C0.781244 7.1875 1.21874 6.8125 1.71874 6.75L6.56249 5.96875L8.74999 1.375C8.99999 0.875 9.46874 0.5625 9.99999 0.5625C10.5312 0.5625 11.0312 0.875 11.25 1.375L13.4375 5.9375L18.25 6.6875C18.75 6.78125 19.1875 7.125 19.3437 7.625C19.5312 8.15625 19.375 8.71875 19 9.09375L15.5312 12.7187L16.3437 17.7812C16.4375 18.3437 16.2187 18.875 15.75 19.1875C15.3125 19.5 14.7812 19.5312 14.3125 19.2812L9.99999 16.9375L5.68749 19.2812C5.49999 19.4062 5.24999 19.4375 5.03124 19.4375ZM1.96874 8.125C1.96874 8.125 1.96874 8.15625 1.96874 8.1875L5.62499 11.9375C5.84374 12.1562 5.93749 12.5 5.90624 12.8125L5.06249 18.0312C5.06249 18.0312 5.06249 18.0312 5.06249 18.0625L9.56249 15.625C9.84374 15.4687 10.1875 15.4687 10.5 15.625L15 18.0625C15 18.0625 15 18.0625 15 18.0312L14.1562 12.7812C14.0937 12.4688 14.2187 12.1562 14.4375 11.9062L18.0937 8.15625C18.125 8.125 18.0937 8.09375 18.0937 8.09375L13.0625 7.3125C12.75 7.25 12.4687 7.0625 12.3437 6.75L9.99999 2L7.74999 6.78125C7.62499 7.0625 7.34374 7.28125 7.03124 7.34375L1.96874 8.125Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_980_24878">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              There are many variations of passages
            </li>
            <li class="flex text-base text-body-color dark:text-dark-6">
              <span class="mr-2.5 text-primary">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_980_24878)">
                    <path
                      d="M5.03124 19.4375C4.74999 19.4375 4.46874 19.3437 4.24999 19.1875C3.81249 18.875 3.56249 18.3125 3.65624 17.7812L4.46874 12.75L0.968744 9.15625C0.593744 8.78125 0.468744 8.21875 0.624994 7.6875C0.781244 7.1875 1.21874 6.8125 1.71874 6.75L6.56249 5.96875L8.74999 1.375C8.99999 0.875 9.46874 0.5625 9.99999 0.5625C10.5312 0.5625 11.0312 0.875 11.25 1.375L13.4375 5.9375L18.25 6.6875C18.75 6.78125 19.1875 7.125 19.3437 7.625C19.5312 8.15625 19.375 8.71875 19 9.09375L15.5312 12.7187L16.3437 17.7812C16.4375 18.3437 16.2187 18.875 15.75 19.1875C15.3125 19.5 14.7812 19.5312 14.3125 19.2812L9.99999 16.9375L5.68749 19.2812C5.49999 19.4062 5.24999 19.4375 5.03124 19.4375ZM1.96874 8.125C1.96874 8.125 1.96874 8.15625 1.96874 8.1875L5.62499 11.9375C5.84374 12.1562 5.93749 12.5 5.90624 12.8125L5.06249 18.0312C5.06249 18.0312 5.06249 18.0312 5.06249 18.0625L9.56249 15.625C9.84374 15.4687 10.1875 15.4687 10.5 15.625L15 18.0625C15 18.0625 15 18.0625 15 18.0312L14.1562 12.7812C14.0937 12.4688 14.2187 12.1562 14.4375 11.9062L18.0937 8.15625C18.125 8.125 18.0937 8.09375 18.0937 8.09375L13.0625 7.3125C12.75 7.25 12.4687 7.0625 12.3437 6.75L9.99999 2L7.74999 6.78125C7.62499 7.0625 7.34374 7.28125 7.03124 7.34375L1.96874 8.125Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_980_24878">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              If you are going to use a of Lorem
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- ====== Lists Section End -->`;
