export const source = `  <!-- ====== Button Group Section Start -->
<section class="pb-10 pt-20 lg:pb-20 lg:pt-[120px] dark:bg-dark">
  <div class="container">
    <div class="flex justify-center">
      <div
        class="inline-flex items-center overflow-hidden rounded-lg border border-stroke dark:border-dark-3"
      >
        <button
          class="border-r border-stroke px-4 py-3 text-base font-medium text-dark last-of-type:border-r-0 hover:bg-gray-2 hover:text-primary dark:border-dark-3 dark:text-white"
        >
          Button Text
        </button>
        <button
          class="border-r border-stroke px-4 py-3 text-base font-medium text-dark last-of-type:border-r-0 hover:bg-gray-2 hover:text-primary dark:border-dark-3 dark:text-white"
        >
          Button Text
        </button>
        <button
          class="border-r border-stroke px-4 py-3 text-base font-medium text-dark last-of-type:border-r-0 hover:bg-gray-2 hover:text-primary dark:border-dark-3 dark:text-white"
        >
          Button Text
        </button>
      </div>
    </div>
  </div>
</section>
<!-- ====== Buttons Group Section End -->`;
