export const source = ` 
<section class="bg-gray-900 text-white py-20" data-section="features-tabbed">
      <div class="container mx-auto px-4">
        <h2 class="text-3xl font-bold text-center mb-12">Відкрийте можливості MetaFlow3D</h2>
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/3">
            <div class="flex flex-col">
              <button class="py-3 px-6 bg-gray-800 hover:bg-gray-700 text-left" data-tab="1">Моделювання</button>
              <button class="py-3 px-6 bg-gray-800 hover:bg-gray-700 text-left" data-tab="2">Текстурування</button>
              <button class="py-3 px-6 bg-gray-800 hover:bg-gray-700 text-left" data-tab="3">Анімація</button>
            </div>
          </div>
          <div class="w-full md:w-2/3 bg-gray-800 p-6">
            <div class="tab-content" id="tab1">
              <h3 class="text-xl font-bold mb-4">Потужне 3D моделювання</h3>
              <p>Створюйте складні 3D моделі з легкістю завдяки нашим передовим інструментам.</p>
            </div>
            <!-- Повторіть для інших вкладок -->
          </div>
        </div>
      </div>
    </section>
`;
