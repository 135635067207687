export const source = ` 
<section class="bg-gray-100 py-20" data-section="features-icon-grid">
<div class="container mx-auto px-4">
  <h2 class="text-3xl font-bold text-center mb-12">Ключові особливості MetaFlow3D</h2>
  <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
    <div class="text-center">
      <div class="bg-blue-500 text-white rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
        <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path></svg>
      </div>
      <h3 class="text-xl font-semibold mb-2">Швидкий рендеринг</h3>
      <p>Отримуйте результати в рази швидше завдяки нашим оптимізованим алгоритмам</p>
    </div>
    <!-- Повторіть для інших особливостей -->
  </div>
</div>
</section>
`;
