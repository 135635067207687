export const source = ` <!-- ====== Table Grid Section Start -->
<section class="bg-white pb-[84px] pt-[120px] dark:bg-dark">
  <div class="mx-auto px-4 sm:container">
    <div class="mb-9">
      <h2
        class="mb-2 text-2xl font-semibold text-dark sm:text-[28px] dark:text-white"
      >
        My Library
      </h2>
      <p class="text-base text-body-color dark:text-dark-6">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      </p>
    </div>
    <div class="-mx-4 flex flex-wrap">
      <div class="w-full px-4 sm:w-1/2 lg:w-1/3 xl:w-1/4">
        <div class="mb-9">
          <div class="mb-[10px] overflow-hidden rounded">
            <img
              src="../images/table-grids/table-grid-01/image-01.jpg"
              alt="table grids"
              class="h-full w-full object-cover object-center"
            />
          </div>
          <div>
            <a
              href="javascript:void(0)"
              class="inline-block text-base font-medium text-dark hover:text-primary dark:text-white"
            >
              family_photo.jpg
            </a>
            <p class="text-sm text-body-color dark:text-dark-6">589KB</p>
          </div>
        </div>
      </div>
      <div class="w-full px-4 sm:w-1/2 lg:w-1/3 xl:w-1/4">
        <div class="mb-9">
          <div class="mb-[10px] overflow-hidden rounded">
            <img
              src="../images/table-grids/table-grid-01/image-02.jpg"
              alt="table grids"
              class="h-full w-full object-cover object-center"
            />
          </div>
          <div>
            <a
              href="javascript:void(0)"
              class="inline-block text-base font-medium text-dark hover:text-primary dark:text-white"
            >
              office_tour.png
            </a>
            <p class="text-sm text-body-color dark:text-dark-6">985KB</p>
          </div>
        </div>
      </div>
      <div class="w-full px-4 sm:w-1/2 lg:w-1/3 xl:w-1/4">
        <div class="mb-9">
          <div class="mb-[10px] overflow-hidden rounded">
            <img
              src="../images/table-grids/table-grid-01/image-03.jpg"
              alt="table grids"
              class="h-full w-full object-cover object-center"
            />
          </div>
          <div>
            <a
              href="javascript:void(0)"
              class="inline-block text-base font-medium text-dark hover:text-primary dark:text-white"
            >
              personal_image.jpg
            </a>
            <p class="text-sm text-body-color dark:text-dark-6">4MB</p>
          </div>
        </div>
      </div>
      <div class="w-full px-4 sm:w-1/2 lg:w-1/3 xl:w-1/4">
        <div class="mb-9">
          <div class="mb-[10px] overflow-hidden rounded">
            <img
              src="../images/table-grids/table-grid-01/image-04.jpg"
              alt="table grids"
              class="h-full w-full object-cover object-center"
            />
          </div>
          <div>
            <a
              href="javascript:void(0)"
              class="inline-block text-base font-medium text-dark hover:text-primary dark:text-white"
            >
              my_photo.jpg
            </a>
            <p class="text-sm text-body-color dark:text-dark-6">993KB</p>
          </div>
        </div>
      </div>
      <div class="w-full px-4 sm:w-1/2 lg:w-1/3 xl:w-1/4">
        <div class="mb-9">
          <div class="mb-[10px] overflow-hidden rounded">
            <img
              src="../images/table-grids/table-grid-01/image-05.jpg"
              alt="table grids"
              class="h-full w-full object-cover object-center"
            />
          </div>
          <div>
            <a
              href="javascript:void(0)"
              class="inline-block text-base font-medium text-dark hover:text-primary dark:text-white"
            >
              mother_image.jpg
            </a>
            <p class="text-sm text-body-color dark:text-dark-6">7MB</p>
          </div>
        </div>
      </div>
      <div class="w-full px-4 sm:w-1/2 lg:w-1/3 xl:w-1/4">
        <div class="mb-9">
          <div class="mb-[10px] overflow-hidden rounded">
            <img
              src="../images/table-grids/table-grid-01/image-06.jpg"
              alt="table grids"
              class="h-full w-full object-cover object-center"
            />
          </div>
          <div>
            <a
              href="javascript:void(0)"
              class="inline-block text-base font-medium text-dark hover:text-primary dark:text-white"
            >
              father_image.jpg
            </a>
            <p class="text-sm text-body-color dark:text-dark-6">2MB</p>
          </div>
        </div>
      </div>
      <div class="w-full px-4 sm:w-1/2 lg:w-1/3 xl:w-1/4">
        <div class="mb-9">
          <div class="mb-[10px] overflow-hidden rounded">
            <img
              src="../images/table-grids/table-grid-01/image-07.jpg"
              alt="table grids"
              class="h-full w-full object-cover object-center"
            />
          </div>
          <div>
            <a
              href="javascript:void(0)"
              class="inline-block text-base font-medium text-dark hover:text-primary dark:text-white"
            >
              brother_image.jpg
            </a>
            <p class="text-sm text-body-color dark:text-dark-6">343KB</p>
          </div>
        </div>
      </div>
      <div class="w-full px-4 sm:w-1/2 lg:w-1/3 xl:w-1/4">
        <div class="mb-9">
          <div class="mb-[10px] overflow-hidden rounded">
            <img
              src="../images/table-grids/table-grid-01/image-08.jpg"
              alt="table grids"
              class="h-full w-full object-cover object-center"
            />
          </div>
          <div>
            <a
              href="javascript:void(0)"
              class="inline-block text-base font-medium text-dark hover:text-primary dark:text-white"
            >
              sister_image.jpg
            </a>
            <p class="text-sm text-body-color dark:text-dark-6">5MB</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- ====== Table Grid Section End -->`;
