export const source = `<div class="flex justify-center  p-4">
<div class="flex flex-col justify-center items-center ">
    <div class=" text-2xl md:text-5xl font-medium flex my-6">
        <div>Testimonials By </div>
        <div class="text-cyan-400 mx-1"> Trusted</div>
    </div>
    <div class="flex flex-col md:flex-row max-w-7xl justify-center items-center ">

        <div class="overflow-hidden w-full m-4 flex justify-center   md:w-[33%]  shadow-xl bg-cyan-400">

            <div class="flex flex-col md:flex-row items-center justify-center  bg-white rounded-tl-full ">
                <div class="  items-center justify-center flex py-2">
                    <div class="flex flex-col  items-center justify-center ">
                        <div class="flex items-center">
                            <div class="p-1 bg-white rounded-full">

                                <img src="https://source.unsplash.com/100x100/?man,boy" alt=""
                                    class="rounded-full" />
                            </div>
                            <div>
                                <div class="font-bold text-stone-500 mx-4">John Doe</div>
                                <div class="text-sm font-medium text-stone-500 hover:text-stone-500 mx-4"><a
                                        href="#">Board Director of Tailblocks</a></div>
                            </div>
                        </div>
                        <div class="text-stone-400  m-2 px-8"> Tailblocks provides best Tailwind CSS Components and
                            Blocks to create an unique websites within minutes. It has upto 60+ free components for
                            front-end Web Development.</div>

                    </div>
                </div>

            </div>
        </div>
        <div class="overflow-hidden w-full m-4 flex justify-center   md:w-[33%]  shadow-xl bg-cyan-400">

            <div class="flex flex-col md:flex-row items-center justify-center  bg-white rounded-tl-full ">
                <div class="  items-center justify-center flex py-2">
                    <div class="flex flex-col  items-center justify-center ">
                        <div class="flex items-center">
                            <div class="p-1 bg-white rounded-full">

                                <img src="https://source.unsplash.com/100x100/?girl" alt="" class="rounded-full" />
                            </div>
                            <div>
                                <div class="font-bold text-stone-500 mx-4">Elina Gilbert</div>
                                <div class="text-sm font-medium text-stone-500 hover:text-stone-500 mx-4"><a
                                        href="#">ShareHolder, Tailblocks</a></div>
                            </div>
                        </div>
                        <div class="text-stone-400  m-2 px-8"> Tailblocks provides best Tailwind CSS Components and
                            Blocks to create an unique websites within minutes. It has upto 60+ free components for
                            front-end Web Development.</div>

                    </div>
                </div>

            </div>
        </div>
        <div class="overflow-hidden w-full m-4 flex justify-center   md:w-[33%]  shadow-xl bg-cyan-400">

            <div class="flex flex-col md:flex-row items-center justify-center  bg-white rounded-tl-full ">
                <div class="  items-center justify-center flex py-2">
                    <div class="flex flex-col  items-center justify-center ">
                        <div class="flex items-center">
                            <div class="p-1 bg-white rounded-full">

                                <img src="https://source.unsplash.com/100x100/?boy" alt="" class="rounded-full" />
                            </div>
                            <div>
                                <div class="font-bold text-stone-500 mx-4">Josh Reacher</div>
                                <div class="text-sm font-medium text-stone-500 hover:text-stone-500 mx-4"><a
                                        href="#">CEO, Tailblocks</a></div>
                            </div>
                        </div>
                        <div class="text-stone-400  m-2 px-8"> Tailblocks provides best Tailwind CSS Components and
                            Blocks to create an unique websites within minutes. It has upto 60+ free components for
                            front-end Web Development.</div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
</div>`;
