export const source = `  <div class="flex justify-center ">
<div class="flex flex-col justify-center items-center bg-slate-100 max-w-7xl">
    <div class="text-3xl text-green-600 font-bold mt-4 ">Testimonial</div>
    <div class="text-2xl text-black font-bold mt-2 ">What our Customers Say</div>
    <div class="flex flex-col items-center justify-evenly my-12 lg:px-10 lg:flex-row space-y-8 lg:space-y-0 ">
        <div
            class="flex flex-col justify-center items-center p-10 bg-white shadow w-[90%] md:w-[70%] lg:w-[30%] overflow-hidden">
            <div class="  "></div>
            <div class="flex flex-col -mt-20 -mr-[352px] hidden xl:block">
                <div class="w-2 h-14 bg-blue-900"></div>
                <div class="w-2 h-14 bg-green-500"></div>
            </div>
            <h1 class="text-xl tracking-tight font-semibold text-green-600 uppercase   ">1. Best Components</h1>
            <p class="text-black mt-3 text-center w-[90%] lg:w-[100%]"> Tailblocks provides best Tailwind CSS
                Components and Blocks to create an unique websites within minutes. It has upto 60+ free
                components for front-end Web Development.</p>
            <img src="https://source.unsplash.com/150x150/?man" class="rounded-full mt-4" alt="">
            <div class="flex flex-col -ml-[352px] mt-72 absolute hidden xl:block">
                <div class="w-2 h-14 bg-blue-900"></div>
                <div class="w-2 h-14 bg-green-500"></div>
            </div>
            <h1 class="text-lg font-semibold text-green-600 uppercase mt-1">mark warner</h1>
            <h1 class="text-lg font-semibold text-black uppercase -mt-1">ceo / director</h1>
        </div>
        <div
            class="flex flex-col justify-center items-center p-10 bg-white shadow w-[90%] md:w-[70%] lg:w-[30%] overflow-hidden">
            <div class="  "></div>
            <div class="flex flex-col -mt-20 -mr-[352px] hidden xl:block">
                <div class="w-2 h-14 bg-blue-900"></div>
                <div class="w-2 h-14 bg-green-500"></div>
            </div>
            <h1 class="text-xl tracking-tight font-semibold text-green-600 uppercase   ">2. Amazing Designs</h1>
            <p class="text-black mt-3 text-center w-[90%] lg:w-[100%]"> Tailblocks provides best Tailwind CSS
                Components and Blocks to create an unique websites within minutes. It has upto 60+ free
                components for front-end Web Development.</p>
            <img src="https://source.unsplash.com/150x150/?girl" class="rounded-full mt-4" alt="">
            <div class="flex flex-col -ml-[352px] mt-72 absolute hidden xl:block">
                <div class="w-2 h-14 bg-blue-900"></div>
                <div class="w-2 h-14 bg-green-500"></div>
            </div>
            <h1 class="text-lg font-semibold text-green-600 uppercase mt-1">alexa daniel</h1>
            <h1 class="text-lg font-semibold text-black uppercase -mt-1">ceo / director</h1>
        </div>
        <div
            class="flex flex-col justify-center items-center p-10 bg-white shadow w-[90%]  md:w-[70%] lg:w-[30%] overflow-hidden">
            <div class="  "></div>
            <div class="flex flex-col -mt-20 -mr-[352px] hidden xl:block">
                <div class="w-2 h-14 bg-blue-900"></div>
                <div class="w-2 h-14 bg-green-500"></div>
            </div>
            <h1 class="text-xl tracking-tight font-semibold text-green-600 uppercase   ">3. Impressive</h1>
            <p class="text-black mt-3 text-center w-[90%] lg:w-[100%]"> Tailblocks provides best Tailwind CSS
                Components and Blocks to create an unique websites within minutes. It has upto 60+ free
                components for front-end Web Development.</p>
            <img src="https://source.unsplash.com/150x150/?woman" class="rounded-full mt-4" alt="">
            <div class="flex flex-col -ml-[352px] mt-72 absolute hidden xl:block">
                <div class="w-2 h-14 bg-blue-900"></div>
                <div class="w-2 h-14 bg-green-500"></div>
            </div>
            <h1 class="text-lg font-semibold text-green-600 uppercase mt-1">jessy bush</h1>
            <h1 class="text-lg font-semibold text-black uppercase -mt-1">ceo / director</h1>
        </div>
    </div>
</div>
</div> `;
