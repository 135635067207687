export const source = `<div
class="flex justify-center items-center bg-gradient-to-r from-blue-600 via-indigo-600 to-purple-600 p-10 md:py-16">
<div class="bg-white w-full md:w-1/2 py-4 shadow flex justify-center  max-w-7xl ">
    <div class="px-8">
        <h1 class="text-indigo-600  text-2xl md:text-4xl font-semibold my-4 text-center">Client testimonials
        </h1>
        <div class="flex items-center justify-center space-x-10">

            <div class="md:ml-[6px] flex flex-col items-center lg:flex-row  bg-white">
                <img src="https://source.unsplash.com/200x300/?girl" class=" " alt="">

                <div class="px-5 py-10 text-black flex flex-col w-full lg:w-[70%] ">
                    <q class="text-sm -mt-5 -ml-5 md:-ml-0  text-left">
                        Tailblockss provides best Tailwind CSS Components
                        and Blocks to create an unique websites within minutes. It has upto 60+ free
                        components for front-end Web Development.
                    </q>
                    <div class="mt-5 text-left -ml-5 md:text-right ">
                        <h1 class="font-bold text-lg">Harried Mitchell</h1>
                        <p class="text-sm">Marketing Director</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
</div>`;
