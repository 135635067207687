export const source = ` <!-- ====== Brands Section Start -->
<section class="bg-white py-20 dark:bg-dark lg:py-[120px]">
  <div class="container mx-auto">
    <div class="-mx-4 flex flex-wrap">
      <div class="w-full px-4">
        <div class="flex flex-wrap items-center justify-center">
          <a
            href="javascript:void(0)"
            class="mx-4 flex w-[150px] items-center justify-center py-5 2xl:w-[180px]"
          >
            <img
              src="../../assets/images/brands/graygrids.svg"
              alt="image"
              class="h-10 w-full dark:hidden"
            />
            <img
              src="../../assets/images/brands/graygrids-white.svg"
              alt="image"
              class="hidden h-10 w-full dark:block"
            />
          </a>
          <a
            href="javascript:void(0)"
            class="mx-4 flex w-[150px] items-center justify-center py-5 2xl:w-[180px]"
          >
            <img
              src="../../assets/images/brands/lineicons.svg"
              alt="image"
              class="h-10 w-full dark:hidden"
            />
            <img
              src="../../assets/images/brands/lineIcons-white.svg"
              alt="image"
              class="hidden h-10 w-full dark:block"
            />
          </a>
          <a
            href="javascript:void(0)"
            class="mx-4 flex w-[150px] items-center justify-center py-5 2xl:w-[180px]"
          >
            <img
              src="../../assets/images/brands/uideck.svg"
              alt="image"
              class="h-10 w-full dark:hidden"
            />
            <img
              src="../../assets/images/brands/uideck-white.svg"
              alt="image"
              class="hidden h-10 w-full dark:block"
            />
          </a>
          <a
            href="javascript:void(0)"
            class="mx-4 flex w-[150px] items-center justify-center py-5 2xl:w-[180px]"
          >
            <img
              src="../../assets/images/brands/ayroui.svg"
              alt="image"
              class="h-10 w-full dark:hidden"
            />
            <img
              src="../../assets/images/brands/ayroui-white.svg"
              alt="image"
              class="hidden h-10 w-full dark:block"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- ====== Brands Section End -->`;