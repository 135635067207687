export const source = `<div class="flex items-center justify-center bg-gradient-to-r from-blue-900 to-fuchsia-700 ">
<div class="flex-col items-center justify-center text-white">
    <div class="h-40 w-[70%] md:w-1/2 flex items-center justify-center  text-3xl lg:text-5xl font-bold mx-14">Tailblocks is the best website for Tailwind CSS Blocks</div>

    <div class="flex">

    <div class="flex md:flex-row flex-col p-4 space-x-4 space-y-2 max-w-7xl justify-around w-full h-auto lg:h-60 items-center ">
        <div class="h-40 w-[70%] md:w-1/4 flex items-center justify-center ml-4">
                <div class="flex-col space-y-2 items-center px-0 md:px-6">
                    <div class="text-sm font-medium text-gray-200">Clients</div>
                    <div class="text-5xl font-bold">15k+</div>
                    <div class="text-sm font-medium text-gray-200">Tailblocks has more than 15k+ visitors per month.</div>
            </div>
        </div>
        <div class="h-40 w-[70%] md:w-1/4 flex items-center justify-center">
            <div class="flex-col space-y-2">
                <div class="text-sm font-medium text-gray-200">Users</div>
                <div class="text-5xl font-bold">1.2M+</div>
                <div class="text-sm font-medium text-gray-200">Tailblocks has more than 1.2M+ total users.</div>
            </div>
        </div>
        <div class="h-40 w-[70%] md:w-1/4 flex items-center justify-center">
            <div class="flex-col space-y-2">
                <div class="text-sm font-medium text-gray-200">Engagement</div>
                <div class="text-5xl font-bold">69k</div>
                <div class="text-sm font-medium text-gray-200">Tailblocks has gained 69k+ users last month.</div>
            </div>
        </div>
        </div>
        
    </div>
    </div>

</div> 
`;
