export const source = `<div class="flex justify-center  p-4 bg-gray-700">
<div class="flex flex-col justify-center items-center text-center">
    <div class="text-white text-3xl md:text-5xl font-medium  my-2">What People are Saying</div>
    <div class="text-white md:text-xl  my-2 w-3/4">TailBlocks has inspired tens of thousands of Front-end Web
        Developers</div>
    <div class=" my-2 flex flex-col md:flex-row max-w-7xl justify-center items-center ">
        <div>
            <div class="flex flex-col  justify-center items-center ">
                <div class="overflow-hidden  m-4 flex flex-col justify-center   bg-gray-600 rounded-xl">

                    <div class="flex flex-col md:flex-row items-center justify-center   ">
                        <div class="  items-center justify-center flex py-2">
                            <div class="flex flex-col  items-center justify-center text-center">

                                <div class="text-stone-200  m-2 px-8 text-xl">Tailblocks provides best Tailwind
                                    CSS
                                    Components and Blocks to create an unique websites within minutes. It has
                                    upto
                                    60+ free components for front-end Web Development.</div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="h-6 w-6 bg-gray-600 -mt-8 mb-4 origin-center rotate-45"></div>
                <div class="flex flex-col  items-center justify-center">

                    <img src="https://source.unsplash.com/100x100/?man,boy" alt=""
                        class="rounded-full h-20 w-20" />
                    <div class="text-white text-sm font-medium">John Doe, CEO</div>
                </div>
            </div>
        </div>
        <div>
            <div class="flex flex-col  justify-center items-center ">
                <div class="overflow-hidden  m-4 flex flex-col justify-center   bg-gray-600 rounded-xl">

                    <div class="flex flex-col md:flex-row items-center justify-center   ">
                        <div class="  items-center justify-center flex py-2">
                            <div class="flex flex-col  items-center justify-center text-center">

                                <div class="text-stone-200  m-2 px-8 text-xl">Tailblocks provides best Tailwind
                                    CSS
                                    Components and Blocks to create an unique websites within minutes. It has
                                    upto
                                    60+ free components for front-end Web Development.</div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="h-6 w-6 bg-gray-600 -mt-8 mb-4 origin-center rotate-45"></div>
                <div class="flex flex-col  items-center justify-center">

                    <img src="https://source.unsplash.com/100x100/?girl" alt=""
                        class="rounded-full h-20 w-20" />
                    <div class="text-white text-sm font-medium">Jessica Jones, SEO Executive</div>
                </div>
            </div>
        </div>
        <div>
            <div class="flex flex-col  justify-center items-center ">
                <div class="overflow-hidden  m-4 flex flex-col justify-center   bg-gray-600 rounded-xl">

                    <div class="flex flex-col md:flex-row items-center justify-center   ">
                        <div class="  items-center justify-center flex py-2">
                            <div class="flex flex-col  items-center justify-center text-center">

                                <div class="text-stone-200  m-2 px-8 text-xl">Tailblocks provides best Tailwind
                                    CSS
                                    Components and Blocks to create an unique websites within minutes. It has
                                    upto
                                    60+ free components for front-end Web Development.</div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="h-6 w-6 bg-gray-600 -mt-8 mb-4 origin-center rotate-45"></div>
                <div class="flex flex-col  items-center justify-center">

                    <img src="https://source.unsplash.com/100x100/?woman" alt=""
                        class="rounded-full h-20 w-20" />
                    <div class="text-white text-sm font-medium">Sierra, Front-End Dev</div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>`;
