export const source = `
<!-- Goal Section -->
<section class="bg-gray-900 py-20 relative overflow-hidden" data-section="goal">
  <div class="container mx-auto px-4 relative z-10">
      <div class="text-center">
          <p class="text-gray-400 text-sm uppercase tracking-wider mb-4">OUR GOAL</p>
          <h2 class="text-3xl md:text-4xl lg:text-5xl font-bold text-white leading-tight max-w-4xl mx-auto" data-element="goal-statement">
              "To Simplify Complex Workflows With <span class="text-blue-500">Intuitive Tools</span>, Helping Teams Work Smarter, Faster, And More Efficiently."
          </h2>
      </div>
  </div>
</section>
`;
