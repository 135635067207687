export const source = `
<section class="bg-gradient-to-br from-indigo-900 to-purple-900 text-white py-20 relative overflow-hidden">
<div class="absolute inset-0 opacity-20">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" class="absolute bottom-0">
    <path fill="currentColor" fill-opacity="1" d="M0,64L48,80C96,96,192,128,288,128C384,128,480,96,576,90.7C672,85,768,107,864,128C960,149,1056,171,1152,165.3C1248,160,1344,128,1392,112L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
  </svg>
</div>
<div class="container mx-auto px-4 relative z-10">
  <h2 class="text-4xl font-bold mb-12 text-center">Key Features</h2>
  <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
    <div class="bg-white bg-opacity-10 p-6 rounded-lg backdrop-blur-lg">
      <h3 class="text-xl font-semibold mb-4">Seamless Integration</h3>
      <p>Effortlessly connect your workflow with industry-standard tools.</p>
    </div>
    <div class="bg-white bg-opacity-10 p-6 rounded-lg backdrop-blur-lg">
      <h3 class="text-xl font-semibold mb-4">AI-Powered Analytics</h3>
      <p>Gain insights with advanced artificial intelligence algorithms.</p>
    </div>
    <div class="bg-white bg-opacity-10 p-6 rounded-lg backdrop-blur-lg">
      <h3 class="text-xl font-semibold mb-4">Real-time Collaboration</h3>
      <p>Work together seamlessly with your team, anytime, anywhere.</p>
    </div>
  </div>
</div>
</section>
`;
