export const source = ` 
<section class="bg-gray-100 py-20" data-section="testimonials-slider">
<div class="container mx-auto px-4">
  <h2 class="text-3xl font-bold text-center mb-12">Що кажуть наші клієнти</h2>
  <div class="testimonial-slider">
    <div class="bg-white p-6 rounded-lg shadow-lg">
      <p class="text-gray-600 mb-4">"MetaFlow3D повністю змінив наш робочий процес. Тепер ми створюємо приголомшливі 3D моделі швидше, ніж будь-коли."</p>
      <div class="flex items-center">
        <img src="user1.jpg" alt="User" class="w-12 h-12 rounded-full mr-4">
        <div>
          <p class="font-bold">Джон Доу</p>
          <p class="text-sm text-gray-500">3D дизайнер, Компанія XYZ</p>
        </div>
      </div>
    </div>
    <!-- Додайте більше відгуків -->
  </div>
</div>
</section>
`;
