import { useState } from "react";
import ModalCustom from "../../partial/ModalCustom";
import GradientGenerator from "../partial/GradientGenerator";
import { CardAnimator } from "../partial/CardAnimator";
import GradientRandomGenerator from "../partial/GradientRandomGenerator";
import GradientSvgGridEditor from "../partial/GradientSvgGridEditor";
import SvgBlobGenerator from "../partial/SvgBlobGenerator";
import SvgIconEditor from "../partial/SvgIconEditor/SvgIconEditor";
import TextEffectGenerator from "../partial/SvgText/TextEffectGenerator";
//import SvgIconEditor from "../partial/SvgIconEditor";

const BackgroundSubMenu = () => {
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [gradientVisible, setgradientVisible] = useState(false);
  const [animationCardsVisible, setanimationCardsVisible] = useState(false);
  const [gradientRandomVisible, setgradientRandomVisible] = useState(false);
  const [gradientSVGVisible, setgradientSVGVisible] = useState(false);
  const [gradientSVGBlobVisible, setgradientSVGBlobVisible] = useState(false);

  const [SvgIconEditorVisible, setSvgIconEditorVisible] = useState(false);
  const [textEffectGeneratorVisible, settextEffectGeneratorVisible] = useState(false);
  const toggleSubmenu = (id) => {
    setActiveSubmenu(activeSubmenu === id ? null : id);
  };

  const closegradientVisible = () => setgradientVisible(false);
  const closeanimationCardsVisible = () => setanimationCardsVisible(false);
  const closegradientRandomVisible = () => setgradientRandomVisible(false);
  const closegradientSVGVisible = () => setgradientSVGVisible(false);
  const closegradientSVGBlobVisible = () => setgradientSVGBlobVisible(false);
  const closeSvgIconEditorVisible = () => setSvgIconEditorVisible(false);
  const closetextEffectGeneratorVisible = () => settextEffectGeneratorVisible(false);

  const cardsData = [
    {
      title: "Безшовна інтеграція з Blender",
      description: "Імпортуйте свої 3D-ресурси безпосередньо з Blender на нашу платформу.",
      backTitle: "Додаткова інформація",
      backDescription: "Наша інтеграція з Blender зберігає всі матеріали та текстури.",
    },
    {
      title: "Безшовна інтеграція з Blender",
      description: "Імпортуйте свої 3D-ресурси безпосередньо з Blender на нашу платформу.",
      backTitle: "Додаткова інформація",
      backDescription: "Наша інтеграція з Blender зберігає всі матеріали та текстури.",
    },
    {
      title: "Безшовна інтеграція з Blender",
      description: "Імпортуйте свої 3D-ресурси безпосередньо з Blender на нашу платформу.",
      backTitle: "Додаткова інформація",
      backDescription: "Наша інтеграція з Blender зберігає всі матеріали та текстури.",
    },
  ];

  return (
    <>
      <ul className="general_menu__nav">
        <div className="general_menu__nav__row">
          {/* Цей рядок може містити кілька підменю, кожне з унікальним ідентифікатором */}

          <div className="general_menu__nav__column">
            <div className="general_menu__nav__row">
              {["blocks"].map((menu, index) => (
                <li key={menu}>
                  <button onClick={() => toggleSubmenu(menu)} className="but_submenu">
                    {menu}
                  </button>
                  {/* 
                                <div className="submenu_col" style={{ display: activeSubmenu === menu ? 'flex' : 'none' }}>
                                    {index === 0 ? <MenuProject /> : null}
                                </div> */}
                </li>
              ))}
            </div>
          </div>

          <div className="general_menu__nav__column">
            <div className="general_menu__nav__row">
              <div className="general_menu__nav__row">
                <button onClick={() => setgradientVisible(true)}>Градіент</button>
              </div>
              <div className="general_menu__nav__row">
                <button onClick={() => setgradientRandomVisible(true)}>Градіент рандом</button>
              </div>
              <div className="general_menu__nav__row">
                <button onClick={() => setanimationCardsVisible(true)}>Анімація карток</button>
              </div>
            </div>
            <div className="mt-3 general_menu__nav__row grid grid-cols-2 gap-4">
              <div className="general_menu__nav__column">
                <div className="general_menu__nav__row">
                  <button onClick={() => setgradientSVGBlobVisible(true)}>SVG Blob Editor</button>
                </div>
                <div className="general_menu__nav__row">
                  <button onClick={() => setSvgIconEditorVisible(true)}>Svg Icon Editor</button>
                </div>
              </div>
            </div>
          </div>

          <div className="general_menu__nav__column">
            <div className="general_menu__nav__row">
              <button onClick={() => settextEffectGeneratorVisible(true)}>Text Effect Generator</button>
            </div>
          </div>

          {/* Додаткові меню можуть мати свої підменю */}
          <div className="general_menu__nav__column">
            <div className="general_menu__nav__row"></div>
          </div>

          <div className="general_menu__nav__column">
            <div className="general_menu__nav__row"></div>
          </div>
        </div>
      </ul>
      {gradientVisible && (
        <ModalCustom isOpen={gradientVisible} closeModal={closegradientVisible} text={{ title: "Open Template" }} maxW={"max-w-6xl"}>
          <GradientGenerator />
        </ModalCustom>
      )}
      {animationCardsVisible && (
        <ModalCustom isOpen={animationCardsVisible} closeModal={closeanimationCardsVisible} text={{ title: "Open Template" }} maxW={"max-w-6xl"}>
          <CardAnimator
            cards={cardsData}
            initialStyles={{
              duration: 1.5,
              distance: 400,
            }}
            onStylesChange={(styles) => console.log("Styles changed:", styles)}
          />
        </ModalCustom>
      )}
      {gradientRandomVisible && (
        <ModalCustom isOpen={gradientRandomVisible} closeModal={closegradientRandomVisible} text={{ title: "Open Template" }} maxW={"max-w-6xl"}>
          <GradientRandomGenerator />
        </ModalCustom>
      )}
      {gradientSVGVisible && (
        <ModalCustom isOpen={gradientSVGVisible} closeModal={closegradientSVGVisible} text={{ title: "Open Template" }} maxW={"max-w-6xl"}>
          <GradientSvgGridEditor />
        </ModalCustom>
      )}
      {gradientSVGBlobVisible && (
        <ModalCustom isOpen={gradientSVGBlobVisible} closeModal={closegradientSVGBlobVisible} text={{ title: "Open Template" }} maxW={"max-w-8xl"}>
          <SvgBlobGenerator />
        </ModalCustom>
      )}
      {SvgIconEditorVisible && (
        <ModalCustom isOpen={SvgIconEditorVisible} closeModal={closeSvgIconEditorVisible} text={{ title: "Open Svg Editor" }} maxW={"max-w-8xl"}>
          <SvgIconEditor />
        </ModalCustom>
      )}

      {textEffectGeneratorVisible && (
        <ModalCustom isOpen={textEffectGeneratorVisible} closeModal={closetextEffectGeneratorVisible} text={{ title: "Open Svg Editor" }} maxW={"max-w-8xl"}>
          <TextEffectGenerator />
        </ModalCustom>
      )}
    </>
  );
};

export default BackgroundSubMenu;
