export const source = `
<!-- Outcomes Section -->
<section class="bg-gray-900 py-20 relative overflow-hidden" data-section="outcomes">
    <div class="container mx-auto px-4 relative z-10">
        <div class="max-w-4xl mx-auto">
            <h2 class="text-3xl md:text-4xl lg:text-5xl font-bold text-white mb-6 leading-tight" data-element="outcome-title">
                Achieve Reliable And Precise Outcomes With
                <span class="text-blue-500">Full Support</span> For Both Images And Videos.
            </h2>
            <div class="relative mt-8 rounded-lg overflow-hidden shadow-2xl" data-element="outcome-image">
                <!-- Image and overlay here -->
            </div>
        </div>
    </div>
</section>
`;