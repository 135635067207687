export const source = ` <div class="flex flex-col md:flex-row h-screen">
<div class="bg-blue-500 flex-1 md:w-1/3 h-1/2 md:h-full">
  <div class="p-4 text-white">
    <h1 class="text-3xl">Лівий блок</h1>
    <p>Цей блок займає 1/3 ширини екрану на великих екранах і 100% ширини на мобільних пристроях.</p>
  </div>
</div>
<div class="bg-green-500 h-1/2 md:w-2/3 md:h-full">
  <div class="p-4 text-white">
    <h1 class="text-3xl">Правий блок</h1>
    <p>Цей блок займає всю ширину екрану на великих екранах і 100% ширини на мобільних пристроях.</p>
  </div>
</div>`;
