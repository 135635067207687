export const source = ` <div class="flex items-center justify-center bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 ">
<div class="flex-col items-center justify-center ">
    <div class="flex">
    <div class="flex md:flex-row flex-col p-4 space-x-4 space-y-4 max-w-7xl justify-around w-full h-auto lg:h-60 items-center ">
        <div class="border rounded-xl h-40 w-[70%] md:w-1/4 flex items-center justify-center ml-4 lg:px-0 px-6 bg-white shadow-xl">
                <div class="flex-col space-y-2 items-center px-0 md:px-6">
                    <div class="text-5xl font-bold">69%</div>
                    <div class="text-sm font-medium text-gray-500">Tailblocks has more than 15k+ visitors per month.</div>
            </div>
        </div>
        <div class="border rounded-xl h-40 w-[70%] md:w-1/4 flex items-center justify-center px-6 bg-white shadow-xl">
            <div class="flex-col space-y-2">
                <div class="text-5xl font-bold">42%</div>
                <div class="text-sm font-medium text-gray-500">Tailblocks has more than 1.2M+ total users.</div>
            </div>
        </div>
        <div class="border rounded-xl h-40 w-[70%] md:w-1/4 flex items-center justify-center px-6 bg-white shadow-xl">
            <div class="flex-col space-y-2">
                <div class="text-5xl font-bold">71%</div>
                <div class="text-sm font-medium text-gray-500">Tailblocks has gained 69k+ users last month.</div>
            </div>
        </div>
        <div class="border rounded-xl h-40 w-[70%] md:w-1/4 flex items-center justify-center px-6">
            <div class="flex-col space-y-2">
                <div class="text-3xl font-bold">Join Our Community</div>
                <div class="text-sm font-medium text-gray-500">Join Tailblocks and contribute your designs and grow our community.</div>
                <div class="flex">
                    <div class="bg-gradient-to-r from-sky-400 via-rose-400 to-lime-400 p-0.5 rounded-md">
                        <div class=" text-sm bg-white py-1 font-bold px-2 rounded-md cursor-pointer "> Join Tailblocks</div>
                    </div>

                </div>
            </div>
        </div>
        </div>
        
    </div>
    </div>

</div>`;
