export const source = `
<!-- Features Section -->
<section class="bg-gray-900 py-16 relative overflow-hidden" data-section="features">
  <div class="absolute inset-0 bg-gradient-to-br from-gray-900 to-gray-800 opacity-50"></div>
  <div class="absolute inset-0 grid-background opacity-10"></div>
  <div class="container mx-auto px-4 relative z-10">
    <h2 class="text-3xl font-bold text-center text-white mb-12">Основні функції</h2>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8" data-element="feature-cards">
      <!-- Feature cards here -->
      <h2 class="text-3xl font-bold text-center text-white mb-12">Feature cards here</h2>
    </div>
  </div>
</section>
`;
