import { useRef, useState } from "react";
import html2canvas from "html2canvas";

const ScreenshotComponent = () => {
  const containerRef = useRef(null);

  const [screenshot, setScreenshot] = useState(null);
  const isSelf = false;
  const takeSelfScreenshot = () => {
    if (
      (containerRef.current,
      {
        logging: true,
        useCORS: true,
      })
    ) {
      html2canvas(containerRef.current).then((canvas) => {
        const url = canvas.toDataURL(); // Отримання URL скріншота
        console.log(url);
        // Далі ви можете зробити що завгодно з отриманим URL, наприклад, відобразити його в <img> тегу або зберегти на сервері
      });
    }
  };
  async function download() {
    const element = (window as any).editor.Canvas.getBody(); 
    const canvas = await html2canvas(element);
    canvas.style.display = "none";
    document.body.appendChild(canvas);
    const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    const a = document.createElement("a");
    a.setAttribute("download", `info.png`);
    a.setAttribute("href", image);
    a.click();
  }

  const takeScreenshot = () => {
    // Отримуємо елемент, який ми хочемо заскріншотити
    const element = (window as any).editor.Canvas.getBody();
    //document.querySelector(".gjs-dashed");//(window as any).editor.getHtml();
    console.log(element);
    // Створюємо скріншот за допомогою html2canvas
    if (element) {
      html2canvas(element, {
        logging: true,
        useCORS: true,
      }).then((canvas) => {
        const url = canvas.toDataURL();
        console.log(url);
      });
    }
  };

  const saveScreenshot = (imageData, filename) => {
    const formData = new FormData();
    const imageBlob = dataURItoBlob(imageData);
    formData.append("screenshot", imageBlob, filename);

    fetch("url_to_your_server_endpoint", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to upload screenshot");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Screenshot uploaded successfully", data);
      })
      .catch((error) => {
        console.error("Error uploading screenshot", error);
      });
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  return (
    (isSelf && (
      <div>
        <div ref={containerRef}>
          {/* Тут ваш вміст, який ви хочете заскрінити */}
          <h1>Hello, world!</h1>
          <p>This is a screenshot example.</p>
        </div>
        <button onClick={takeSelfScreenshot}>
          <i className="fa fa-solid fa-camera"></i>
        </button>
      </div>
    )) ||
    (!isSelf && (
      <>      
        <button className="ml-1" onClick={takeScreenshot}>
          <i className="fa fa-solid fa-camera-retro"></i>
        </button>

        <a className="mx-2" href="#" onClick={() => download()}>
          Download
        </a>
      </>
    ))
  );
};

export default ScreenshotComponent;
