import  { useState, useEffect } from "react";
import axios from "axios";

const WebAppManagement = ({ projectId, setMessage }) => {
  const [webAppUrl, setWebAppUrl] = useState("");
  const [chatId, setChatId] = useState("");
  const [messageText, setMessageText] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchWebAppInfo();
  }, [projectId]);

  const fetchWebAppInfo = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${apiUrl}/telegram/webapp/info?project_id=${projectId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setWebAppUrl(response.data.web_app_url || "");
    } catch (error) {
      console.error("Error fetching WebApp info:", error);
      setMessage("Error fetching WebApp info");
    }
  };

  const sendWebAppMessage = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${apiUrl}/telegram/webapp/send-message`,
        {
          project_id: projectId,
          chat_id: chatId,
          text: messageText,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setMessage("WebApp message sent successfully");
    } catch (error) {
      console.error("Error sending WebApp message:", error);
      setMessage("Error sending WebApp message");
    }
  };

  return (
    <div className="mb-8">
      <h2 className="text-xl font-semibold mb-4">WebApp Management</h2>
      
      <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4" role="alert">
        <p className="font-bold">Інформація про WebApp</p>
        <p>WebApp URL: {webAppUrl || "Не встановлено"}</p>
        <p className="mt-2">
          WebApp - це мініатюрний веб-додаток, який можна відкрити прямо в Telegram. 
          Ви можете використовувати його для створення інтерактивних інтерфейсів у ваших чатах.
        </p>
      </div>
      
      <form onSubmit={sendWebAppMessage} className="mb-4">
        <h3 className="text-lg font-semibold mb-2">Відправити повідомлення з WebApp</h3>
        <div className="mb-4">
          <label className="block mb-2">Chat ID:</label>
          <input
            type="text"
            value={chatId}
            onChange={(e) => setChatId(e.target.value)}
            className="w-full p-2 border rounded"
            placeholder="Наприклад: 123456789 або @channel_name"
            required
          />
          <p className="text-sm text-gray-600 mt-1">
            Введіть ID чату або юзернейм каналу, куди ви хочете відправити повідомлення. 
            Для приватних чатів використовуйте числовий ID, для каналів - username.
          </p>
        </div>
        <div className="mb-4">
          <label className="block mb-2">Текст повідомлення:</label>
          <textarea
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            className="w-full p-2 border rounded"
            rows={4}
            placeholder="Введіть текст повідомлення. Кнопка для відкриття WebApp буде додана автоматично."
            required
          ></textarea>
          <p className="text-sm text-gray-600 mt-1">
            Це повідомлення буде відправлено разом з кнопкою, яка відкриває ваш WebApp.
          </p>
        </div>
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300">
          Відправити повідомлення з WebApp
        </button>
      </form>

      <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
        <p className="font-bold">Підказка</p>
        <p>
          Перед відправкою повідомлення переконайтеся, що ви встановили правильний WebApp URL 
          в налаштуваннях Telegram бота. Також перевірте, чи має ваш бот доступ до вказаного чату або каналу.
        </p>
      </div>
    </div>
  );
};

export default WebAppManagement;