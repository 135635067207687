export const source = `<div class="flex justify-center">
<div class="flex flex-col md:flex-row max-w-7xl justify-center items-center m-2">
    <div
        class="bg-white m-4 shadow-xl min-h-96 flex flex-col justify-center text-black hover:text-white hover:bg-stone-900 hover:scale-105 ">
        <div class="m-8 h-96">
            <div class=" m-2">Free</div>
            <div class=" m-2 text-3xl">$0</div>
            <div class=" m-2 mt-8">For Freelancers</div>
            <div class="flex">
                <ion-icon name="checkmark-circle-outline" class="m-1 text-green-500"></ion-icon>
                <div class="">1 user</div>
            </div>
            <div class="flex">
                <ion-icon name="checkmark-circle-outline" class="m-1 text-green-500"></ion-icon>
                <div class="">10 downloads per day</div>
            </div>
            <div class="flex">
                <ion-icon name="checkmark-circle-outline" class="m-1 text-green-500"></ion-icon>
                <div class="">Daily content updates</div>
            </div>
            <div class="flex">
                <ion-icon name="close-circle-outline" class="m-1 text-red-500"></ion-icon>
                <div class="">100 downloads per day</div>
            </div>
            <div class="flex">
                <ion-icon name="close-circle-outline" class="m-1 text-red-500"></ion-icon>
                <div class="">Daily Backups </div>
            </div>
        </div>
        <div class="flex justify-center">
            <div
                class="bg-stone-900 text-white cursor-pointer m-4 px-8 py-1 rounded-2xl hover:bg-white hover:text-stone-900 font-medium">
                Subscribe</div>
        </div>
    </div>
    <div
        class="bg-white m-4 shadow-xl min-h-96 flex flex-col justify-center text-black hover:text-white hover:bg-stone-900 hover:scale-105 ">
        <div class="m-8 h-96 ">
            <div class=" m-2">Gold</div>
            <div class=" m-2 text-3xl">$9.99</div>
            <div class=" m-2 mt-8">For Developers</div>
            <div class="flex">
                <ion-icon name="checkmark-circle-outline" class="m-1 text-green-500"></ion-icon>
                <div class="">10 user</div>
            </div>
            <div class="flex">
                <ion-icon name="checkmark-circle-outline" class="m-1 text-green-500"></ion-icon>
                <div class="">100 downloads per day</div>
            </div>
            <div class="flex">
                <ion-icon name="checkmark-circle-outline" class="m-1 text-green-500"></ion-icon>
                <div class="">Daily content updates</div>
            </div>
            <div class="flex">
                <ion-icon name="checkmark-circle-outline" class="m-1 text-green-500"></ion-icon>
                <div class="">Daily Backups </div>
            </div>
            <div class="flex">
                <ion-icon name="close-circle-outline" class="m-1 text-red-500"></ion-icon>
                <div class="">Hourly Backups</div>
            </div>
        </div>
        <div class="flex justify-center">
            <div
                class="bg-stone-900 text-white cursor-pointer m-4 px-8 py-1 rounded-2xl hover:bg-white hover:text-stone-900 font-medium">
                Subscribe</div>
        </div>
    </div>
    <div
        class="bg-white m-4 shadow-xl min-h-96 flex flex-col justify-center text-black hover:text-white hover:bg-stone-900 hover:scale-105 ">
        <div class="m-8 h-96 ">
            <div class=" m-2">Platinum</div>
            <div class=" m-2 text-3xl">$14.99</div>
            <div class=" m-2 mt-8">For Business</div>
            <div class="flex">
                <ion-icon name="checkmark-circle-outline" class="m-1 text-green-500"></ion-icon>
                <div class="">1 user</div>
            </div>
            <div class="flex">
                <ion-icon name="checkmark-circle-outline" class="m-1 text-green-500"></ion-icon>
                <div class="">1000 downloads per day</div>
            </div>
            <div class="flex">
                <ion-icon name="checkmark-circle-outline" class="m-1 text-green-500"></ion-icon>
                <div class="">Hourly Backups</div>
            </div>
            <div class="flex">
                <ion-icon name="checkmark-circle-outline" class="m-1 text-green-500"></ion-icon>
                <div class="">Daily content updates</div>
            </div>
            <div class="flex">
                <ion-icon name="checkmark-circle-outline" class="m-1 text-green-500"></ion-icon>
                <div class="">24x7 Service</div>
            </div>
        </div>
        <div class="flex justify-center">
            <div
                class="bg-stone-900 text-white cursor-pointer m-4 px-8 py-1 rounded-2xl hover:bg-white hover:text-stone-900 font-medium">
                Subscribe</div>
        </div>
    </div>
</div>
</div>`;
