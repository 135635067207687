import { useRef, useState } from 'react';
import { toPng } from 'html-to-image';

const ScreenshotImageComponent = () => {
    const containerRef = useRef(null);
    const [screenshot, setScreenshot] = useState(null);
    const isSelf = false;

    const takeSelfScreenshot = () => {
        if (containerRef.current) {
            toPng(containerRef.current, { cacheBust: true })
                .then((dataUrl) => {
                    console.log(dataUrl);
                    // Далі ви можете зробити що завгодно з отриманим URL, наприклад, відобразити його в <img> тегу або зберегти на сервері
                })
                .catch((error) => {
                    console.error('Error taking screenshot:', error);
                });
        }
    };

    const download = async () => {
        const element = (window as any).editor.Canvas.getBody();
        const dataUrl = await toPng(element, { cacheBust: true });
        const a = document.createElement('a');
        a.setAttribute('download', 'info.png');
        a.setAttribute('href', dataUrl.replace('image/png', 'image/octet-stream'));
        a.click();
    };

    const takeScreenshot = () => {
        const element = (window as any).editor.Canvas.getBody();
        if (element) {
            toPng(element, { cacheBust: true })
                .then((dataUrl) => {
                    console.log(dataUrl);
                })
                .catch((error) => {
                    console.error('Error taking screenshot:', error);
                });
        }
    };

    const saveScreenshot = (imageData, filename) => {
        const formData = new FormData();
        const imageBlob = dataURItoBlob(imageData);
        formData.append('screenshot', imageBlob, filename);

        fetch('url_to_your_server_endpoint', {
            method: 'POST',
            body: formData,
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to upload screenshot');
                }
                return response.json();
            })
            .then((data) => {
                console.log('Screenshot uploaded successfully', data);
            })
            .catch((error) => {
                console.error('Error uploading screenshot', error);
            });
    };

    const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    };

    return (
        (isSelf && (
            <div>
                <div ref={containerRef}>
                    {/* Тут ваш вміст, який ви хочете заскрінити */}
                    <h1>Hello, world!</h1>
                    <p>This is a screenshot example.</p>
                </div>
                <button onClick={takeSelfScreenshot}>
                    <i className="fa fa-solid fa-camera"></i>
                </button>
            </div>
        )) ||
        (!isSelf && (
            <>
                <button className="ml-1" onClick={takeScreenshot}>
                    <i className="fa fa-solid fa-camera-retro"></i>
                </button>

                <a className="mx-2" href="#" onClick={() => download()}>
                    Download
                </a>
            </>
        ))
    );
};

export default ScreenshotImageComponent;
