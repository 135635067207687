import { source as bl1 } from './data/bl-1';
import { source as bl2 } from './data/bl-2';
import { source as bl3 } from './data/bl-3';
import { source as bl4 } from './data/bl-4';
import { source as bl5 } from './data/bl-5';
import { source as bl6 } from './data/bl-6';
import { source as bl7 } from './data/bl-7';
import { source as bl8 } from './data/bl-8';
import { source as bl9 } from './data/bl-9';
import { source as bl10 } from './data/bl-10';
import { source as bl11 } from './data/bl-11';
import { source as bl12 } from './data/bl-12';
import { source as bl13 } from './data/bl-13';
import { source as bl14 } from './data/bl-14';
import { source as bl15 } from './data/bl-15';
import { source as bl16 } from './data/bl-16';
import { source as bl17 } from './data/bl-17';
import { source as bl18 } from './data/bl-18';
import { source as bl19 } from './data/bl-19';
import { source as bl20 } from './data/bl-20';
import { source as bl21 } from './data/bl-21';
import { source as bl22 } from './data/bl-22';
import { source as bl23 } from './data/bl-23';
import { source as bl24 } from './data/bl-24';
import { source as bl25 } from './data/bl-25';
import { source as bl26 } from './data/bl-26';
import { source as bl27 } from './data/bl-27';
import { source as bl28 } from './data/bl-28';
import { source as bl29 } from './data/bl-29';
import { source as bl30 } from './data/bl-30';
import { source as bl31 } from './data/bl-31';
import { source as bl32 } from './data/bl-32';
import { source as bl33 } from './data/bl-33';
import { source as bl34 } from './data/bl-34';
import { source as bl35 } from './data/bl-35';
import { source as bl36 } from './data/bl-36';
import { source as bl37 } from './data/bl-37';
import { source as bl38 } from './data/bl-38';
import { source as bl39 } from './data/bl-39';
import { source as bl40 } from './data/bl-40';

import { source as a1s } from './data/icons/cta-1';

const getSvgHtml = (svg) => {
    if (typeof window === 'undefined') return '';
    svg.setAttribute('width', '100%');
    svg.setAttribute('height', '100%');
    return svg.outerHTML;
};

const sources = [
    {
        id: 'Button-1',
        class: '',
        label: a1s,
        content: bl1,
        category: 'Button',
    },
    {
        id: 'Button-2',
        class: '',
        label: a1s,
        content: bl2,
        category: 'Button',
    },
    {
        id: 'Button-3',
        class: '',
        label: a1s,
        content: bl3,
        category: 'Button',
    },
    {
        id: 'Button-4',
        class: '',
        label: a1s,
        content: bl4,
        category: 'Button',
    }
    ,
    {
        id: 'Button-5',
        class: '',
        label: a1s,
        content: bl5,
        category: 'Button',
    }
    ,
    {
        id: 'Button-6',
        class: '',
        label: a1s,
        content: bl6,
        category: 'Button',
    }
    ,
    {
        id: 'Button-7',
        class: '',
        label: a1s,
        content: bl7,
        category: 'Button',
    },
    {
        id: 'Button-8',
        class: '',
        label: a1s,
        content: bl8,
        category: 'Button',
    },
    {
        id: 'Button-9',
        class: '',
        label: a1s,
        content: bl9,
        category: 'Button',
    },
    {
        id: 'Button-10',
        class: '',
        label: a1s,
        content: bl10,
        category: 'Button',
    },
    {
        id: 'Button-11',
        class: '',
        label: a1s,
        content: bl11,
        category: 'Button',
    },
    {
        id: 'Button-12',
        class: '',
        label: a1s,
        content: bl12,
        category: 'Button',
    },
    {
        id: 'Button-13',
        class: '',
        label: a1s,
        content: bl13,
        category: 'Button',
    },
    {
        id: 'Button-14',
        class: '',
        label: a1s,
        content: bl14,
        category: 'Button',
    },
    {
        id: 'Button-15',
        class: '',
        label: a1s,
        content: bl15,
        category: 'Button',
    },
    {
        id: 'Button-16',
        class: '',
        label: a1s,
        content: bl16,
        category: 'Button',
    },
    {
        id: 'Button-17',
        class: '',
        label: a1s,
        content: bl17,
        category: 'Button',
    },
    {
        id: 'Button-18',
        class: '',
        label: a1s,
        content: bl18,
        category: 'Button',
    },
    {
        id: 'Button-19',
        class: '',
        label: a1s,
        content: bl19,
        category: 'Button',
    },
    {
        id: 'Button-20',
        class: '',
        label: a1s,
        content: bl20,
        category: 'Button',
    },
    {
        id: 'Button-21',
        class: '',
        label: a1s,
        content: bl21,
        category: 'Button',
    },
    {
        id: 'Button-22',
        class: '',
        label: a1s,
        content: bl22,
        category: 'Button',
    },
    {
        id: 'Button-23',
        class: '',
        label: a1s,
        content: bl23,
        category: 'Button',
    },
    {
        id: 'Button-24',
        class: '',
        label: a1s,
        content: bl24,
        category: 'Button',
    },
    {
        id: 'Button-25',
        class: '',
        label: a1s,
        content: bl25,
        category: 'Button',
    },
    {
        id: 'Button-26',
        class: '',
        label: a1s,
        content: bl26,
        category: 'Button',
    },
    {
        id: 'Button-27',
        class: '',
        label: a1s,
        content: bl27,
        category: 'Button',
    },
    {
        id: 'Button-28',
        class: '',
        label: a1s,
        content: bl28,
        category: 'Button',
    },
    {
        id: 'Button-29',
        class: '',
        label: a1s,
        content: bl29,
        category: 'Button',
    },
    {
        id: 'Button-30',
        class: '',
        label: a1s,
        content: bl30,
        category: 'Button',
    },
    {
        id: 'Button-31',
        class: '',
        label: a1s,
        content: bl31,
        category: 'Button',
    },
    {
        id: 'Button-32',
        class: '',
        label: a1s,
        content: bl32,
        category: 'Button',
    },
    {
        id: 'Button-33',
        class: '',
        label: a1s,
        content: bl33,
        category: 'Button',
    },
    {
        id: 'Button-34',
        class: '',
        label: a1s,
        content: bl34,
        category: 'Button',
    },
    {
        id: 'Button-35',
        class: '',
        label: a1s,
        content: bl35,
        category: 'Button',
    },
    {
        id: 'Button-36',
        class: '',
        label: a1s,
        content: bl36,
        category: 'Button',
    },
    {
        id: 'Button-37',
        class: '',
        label: a1s,
        content: bl37,
        category: 'Button',
    },
    {
        id: 'Button-38',
        class: '',
        label: a1s,
        content: bl38,
        category: 'Button',
    },
    {
        id: 'Button-39',
        class: '',
        label: a1s,
        content: bl39,
        category: 'Button',
    },
    {
        id: 'Button-40',
        class: '',
        label: a1s,
        content: bl40,
        category: 'Button',
    }
];

export default (editor, options: any = {}) => {
    const bm = editor.Blocks;

    sources.forEach((s) => {
        bm.add(s.id, {
            media: getSvgHtml(editor.$(s.label).get(0)),
            attributes: { class: `${s.class}` },
            content: s.content,
            category: { label: s.category, open: s.category === options.openCategory },
        });
    });
};
