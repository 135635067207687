export const source = ` <div class="flex justify-center bg-gradient-to-r from-blue-700 via-blue-800 to-cyan-600 ">

<div class="flex flex-col justify-center  my-10 w-[90%] space-y-28 lg:space-y-24 max-w-7xl  ">
    <div class="flex flex-col justify-center items-center text-center">
        <div class="text-xl md:text-3xl text-white font-bold uppercase">Contact Us</div>
        <div class="text-sm md:text-xl text-white font-medium">Get in touch and let us know how we can help.
        </div>

    </div>
    <div class="flex flex-col justify-center items-center lg:flex-row space-y-28 lg:space-y-0  lg:space-x-10">
        <div
            class="bg-white shadow-lg flex flex-col justify-center rounded-lg items-center py-4 h-56 md:w-[80%] lg:w-fit">
            <div class="-mt-10 ">
                <ion-icon name="bar-chart-outline"
                    class="text-3xl md:text-5xl rounded-full p-4 bg-white text-cyan-400">
                </ion-icon>
            </div>
            <div class="font-semibold text-2xl">Sales</div>
            <p class="text-center text-lg p-5">We would love to talk about how we can work together.</p>
            <p
                class="text-center text-sm px-6 bg-blue-500 py-2 rounded-3xl hover:bg-cyan-500 p-5 text-white font-medium">
                <a href="#">Contact Sales</a>
            </p>

        </div>
        <div
            class="bg-white shadow-lg flex flex-col justify-center rounded-lg items-center py-4 h-56 md:w-[80%] lg:w-fit">
            <div class="-mt-10  ">
                <ion-icon name="help-outline"
                    class="text-3xl md:text-5xl rounded-full p-4 bg-white text-cyan-400">
                </ion-icon>
            </div>
            <div class="font-semibold text-2xl">Help & Support</div>
            <p class="text-center text-lg p-5">We are here to help with any questions or code.</p>
            <p
                class="text-center text-sm px-6 bg-blue-500 py-2 rounded-3xl hover:bg-cyan-500 p-5 text-white font-medium">
                <a href="#">Get Support</a>
            </p>

        </div>
        <div
            class="bg-white shadow-lg flex flex-col justify-center rounded-lg items-center py-4 h-56 md:w-[80%] lg:w-fit">
            <div class="-mt-10  ">
                <ion-icon name="camera-outline"
                    class="text-3xl md:text-5xl rounded-full p-4 bg-white text-cyan-400">
                </ion-icon>
            </div>
            <div class="font-semibold text-2xl">Media & Press</div>
            <p class="text-center text-lg p-5">Get Stripe news, company info, and media resources.</p>
            <p
                class="text-center text-sm px-6 bg-blue-500 py-2 rounded-3xl hover:bg-cyan-500 p-5 text-white font-medium">
                <a href="#">Visit Newsroom</a></p>

        </div>
    </div>
</div>
</div>`;
