
import Navbar from './Navbar';
import GeneralMenu from './GeneralMenu';
import Sidebar from './Sidebar';
import MainContent from './MainContent';
import { WidthProvider } from '../../providers/WidthProvider';

function Application() { 

  return (
    <WidthProvider>
    <div className="flex flex-col h-screen bg-gray-100 text-gray-900 dark:bg-gray-800 dark:text-white transition-colors duration-300">
      <Navbar />
      <GeneralMenu />
      <div className="flex overflow-hidden">
        <Sidebar />
        <MainContent />
      </div>
      {/* <Footer /> */}
    </div>
  </WidthProvider>
  );
}

export default Application;
