export const source = ` 
<section class="bg-gray-100 py-20 relative overflow-hidden">
<div class="absolute top-0 left-0 w-64 h-64 bg-blue-300 rounded-full -translate-x-1/2 -translate-y-1/2 opacity-20"></div>
<div class="absolute bottom-0 right-0 w-96 h-96 bg-green-300 rounded-full translate-x-1/3 translate-y-1/3 opacity-20"></div>
<div class="container mx-auto px-4 relative z-10">
  <h2 class="text-4xl font-bold mb-12 text-center text-gray-800">What Our Clients Say</h2>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
    <div class="bg-white p-6 rounded-lg shadow-lg">
      <p class="text-gray-600 mb-4">"This product has transformed our workflow. Highly recommended!"</p>
      <p class="font-semibold text-gray-800">- Jane Doe, CEO</p>
    </div>
    <div class="bg-white p-6 rounded-lg shadow-lg">
      <p class="text-gray-600 mb-4">"Incredible features and outstanding support. A game-changer for our team."</p>
      <p class="font-semibold text-gray-800">- John Smith, CTO</p>
    </div>
  </div>
</div>
</section>
`;
