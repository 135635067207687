export const source = `  <div class="flex items-center justify-center bg-gray-300 ">
<div class="flex-col items-center justify-center ">
    <div class="flex">
    <div class="flex lg:flex-row flex-col p-4 space-x-4 space-y-4 max-w-7xl justify-around w-full h-auto lg:h-60 items-center ">
        <div class="border rounded h-40 w-[100%] md:w-72 flex items-center justify-center ml-4 lg:px-0 px-6 bg-white shadow-xl md:mt-4">
                <div class="flex-col space-y-2 items-center px-0 md:px-6">
                   <div class="flex items-center justify-between space-x-6">
                       <div class="flex items-center space-x-1 ">
                           <div class="text-lg font-medium text-violet-500"><ion-icon name="chatbubble-ellipses-outline"></ion-icon></div>
                           <div class="text-sm font-medium text-gray-500">Responses</div>
                        </div>
                        <div class="text-xs bg-gray-200 px-2 py-0.5 rounded-2xl text-gray-400 font-medium">30 Days</div>
                    </div>
                    <div class="text-3xl font-bold">1427</div>
                    <div class="font-bold text-red-500 flex items-center space-x-1">
                        <div class="text-xl"><ion-icon name="trending-down-outline"></ion-icon></div>
                        <div class="text-sm">2.5% less</div>
                    </div>
                </div>
        </div>
        <div class="border rounded h-40 w-[100%] md:w-72 flex items-center justify-center ml-4 lg:px-0 px-6 bg-white shadow-xl">
                <div class="flex-col space-y-2 items-center px-0 md:px-6">
                   <div class="flex items-center justify-between space-x-6">
                       <div class="flex items-center space-x-1 ">
                           <div class="text-lg font-medium text-violet-500"><ion-icon name="briefcase-outline"></ion-icon></div>
                           <div class="text-sm font-medium text-gray-500">Projects</div>
                        </div>
                        <div class="text-xs bg-gray-200 px-2 py-0.5 rounded-2xl text-gray-400 font-medium">30 Days</div>
                    </div>
                    <div class="text-3xl font-bold">127</div>
                    <div class="font-bold text-green-500 flex items-center space-x-1">
                        <div class="text-xl"><ion-icon name="trending-up-outline"></ion-icon></div>
                        <div class="text-sm">4.9% more</div>
                    </div>
                </div>
        </div>
        <div class="border rounded h-40 w-[100%] md:w-72 flex items-center justify-center ml-4 lg:px-0 px-6 bg-white shadow-xl">
                <div class="flex-col space-y-2 items-center px-0 md:px-6">
                   <div class="flex items-center justify-between space-x-6">
                       <div class="flex items-center space-x-1 ">
                                    <div class="text-lg font-medium text-violet-500"><ion-icon name="document-outline"></ion-icon></div>
                           <div class="text-sm font-medium text-gray-500">Reports</div>
                        </div>
                        <div class="text-xs bg-gray-200 px-2 py-0.5 rounded-2xl text-gray-400 font-medium">30 Days</div>
                    </div>
                    <div class="text-3xl font-bold">456</div>
                    <div class="font-bold text-green-500 flex items-center space-x-1">
                        <div class="text-xl"><ion-icon name="trending-up-outline"></ion-icon></div>
                        <div class="text-sm">3.7% more</div>
                    </div>
                </div>
        </div>
        
       
        </div>
        
    </div>
    </div>

</div> 
`;
