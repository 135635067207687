export const TextComponent = (editor, commonTraits) => {
    editor.DomComponents.addType('text-component', {
         isComponent: (el) => {
            if (el.tagName === 'BUTTON') {
                return { type: 'button', tagName: 'button' }
            }
            else if (el.tagName === 'A' && el.getAttribute('role') === 'button') {
                return { type: 'a',  tagName: 'a' }
            }
        },
        model: {
            defaults: {
                tagName: 'p',
                content: '',
                traits: [
                    { type: 'text', label: 'Text', name: 'content', changeProp: true },
                    ...commonTraits,
                ],
                attributes: { class: 'text-component-class', type: 'text-component' },
                // Властивості за замовчуванням
                stylable: ['color', 'margin', 'padding'], // Вказуєте, які CSS властивості доступні для стилювання
            },
            init() {
                this.listenTo(this, 'change:content', this.updateContent);
                this.listenTo(this, 'change:style', () => {
                    // Do something when the style changes
                    console.log('Style changed!');
                  });
            },
            updateContent() {
                const content = this.get('content');
                this.set('content', content);
                // editor.getSelected().toHTML()
            }
        },
        view: {
            
            events: {
                dblclick: 'onActive',
                focusout: 'onDisable',
            },
            onActive() {
                this.el.contentEditable = true; 
            },
            onDisable() {
                const { el, model } = this;
                el.contentEditable = false;
                model.set('content', el.innerHTML)
            },
        }
    });

    // Додавання компонента до менеджера блоків, щоб його можна було легко перетягнути на холст
    editor.BlockManager.add('text-component', {
        label: 'Text Component',
        category: 'Basic',
        content: {
            type: 'text-component',
            content: 'Sample text content' // Вказуємо текст, який буде в компоненті за замовчуванням
        },
        media: `<svg class="gjs-block-svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path class="gjs-block-svg-path" d="M22,9 C22,8.4 21.5,8 20.75,8 L3.25,8 C2.5,8 2,8.4 2,9 L2,15 C2,15.6 2.5,16 3.25,16 L20.75,16 C21.5,16 22,15.6 22,15 L22,9 Z M21,15 L3,15 L3,9 L21,9 L21,15 Z" fill-rule="nonzero"></path>
              <rect class="gjs-block-svg-path" x="4" y="11.5" width="16" height="1"></rect>
            </svg>`,
    });
};
