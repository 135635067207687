export const source = ` 
<section class="py-20 bg-gray-900 text-white" data-section="pricing-cards">
<div class="container mx-auto px-4">
  <h2 class="text-3xl font-bold text-center mb-12">Оберіть свій план MetaFlow3D</h2>
  <div class="flex flex-wrap justify-center -mx-4">
    <div class="w-full md:w-1/3 px-4 mb-8">
      <div class="bg-gray-800 rounded-lg p-8 h-full">
        <h3 class="text-2xl font-bold mb-4">Стартер</h3>
        <p class="text-4xl mb-6">$49<span class="text-sm">/міс</span></p>
        <ul class="mb-6">
          <li class="mb-2">✓ Базове 3D моделювання</li>
          <li class="mb-2">✓ 5 проектів</li>
          <li class="mb-2">✓ Базова підтримка</li>
        </ul>
        <button class="w-full bg-blue-600 py-2 rounded-lg hover:bg-blue-700 transition duration-300">Почати</button>
      </div>
    </div>
    <div class="w-full md:w-1/3 px-4 mb-8">
      <div class="bg-blue-600 rounded-lg p-8 h-full relative">
        <div class="absolute top-0 right-0 bg-yellow-400 text-gray-900 py-1 px-4 rounded-tr-lg rounded-bl-lg font-bold">Популярний</div>
        <h3 class="text-2xl font-bold mb-4">Про</h3>
        <p class="text-4xl mb-6">$99<span class="text-sm">/міс</span></p>
        <ul class="mb-6">
          <li class="mb-2">✓ Розширене 3D моделювання</li>
          <li class="mb-2">✓ Необмежені проекти</li>
          <li class="mb-2">✓ Пріоритетна підтримка</li>
        </ul>
        <button class="w-full bg-white text-blue-600 py-2 rounded-lg hover:bg-gray-100 transition duration-300">Обрати Про</button>
      </div>
    </div>
    <!-- Додайте ще один блок для плану "Ентерпрайз" -->
  </div>
</div>
</section>
`;
