export const source = `
<header class="header-block">
    <div class="flex justify-center">
        <nav class="self-center w-full max-w-7xl nav-block">
            <div class="flex flex-col lg:flex-row justify-around items-center">
                <h1 class="uppercase pl-5 py-4 text-lg font-sans font-bold" data-header-logo="logo-text">hero</h1>
                <ul class="hidden lg:flex items-center text-[18px] font-semibold pl-32" data-header-menu="menu-items">
                    <li class="hover:underline underline-offset-4 decoration-2 decoration-white py-2 rounded-lg px-5">
                        <a href="#" style="color: #ffffff;">Home</a>
                    </li>
                    <li class="hover:underline underline-offset-4 decoration-2 decoration-white py-2 rounded-lg px-5">
                        <a href="#" style="color: #ffffff;">Contact</a>
                    </li>
                    <li class="hover:underline underline-offset-4 decoration-2 decoration-white py-2 rounded-lg px-5">
                        <a href="#" style="color: #ffffff;">Services</a>
                    </li>
                    <li class="hover:underline underline-offset-4 decoration-2 decoration-white py-2 rounded-lg px-5">
                        <a href="#" style="color: #ffffff;">About</a>
                    </li>
                    <li class="hover:underline underline-offset-4 decoration-2 decoration-white py-2 rounded-lg px-5">
                        <a href="#" style="color: #ffffff;">Pricing</a>
                    </li>
                </ul>
                <div class="text-center text-base pr-5 inline-flex">
                    <a href="#" class="w-8 h-8 inline-block rounded-full pt-[6px] hover:text-blue-500" style="color: #ffffff;"><i class="fa fa-twitter"></i></a>
                    <a href="#" class="w-8 h-8 inline-block rounded-full pt-[5px] hover:text-blue-500" style="color: #ffffff;"><i class="fa fa-instagram"></i></a>
                    <a href="#" class="w-8 h-8 inline-block rounded-full pt-[5px] hover:text-blue-500" style="color: #ffffff;"><i class="fa fa-facebook"></i></a>
                    <a href="#" class="w-8 h-8 inline-block rounded-full pt-[5px] hover:text-blue-500" style="color: #ffffff;"><i class="fa fa-google"></i></a>
                    <a href="#" class="w-8 h-8 inline-block rounded-full pt-[5px] hover:text-blue-500" style="color: #ffffff;"><i class="fa fa-linkedin"></i></a>
                </div>
            </div>
        </nav>
    </div>

    <div class="flex justify-center">
        <div class="flex flex-col justify-center">
            <div class="flex flex-col max-w-7xl justify-center items-center p-2 mt-10">
                <div class="flex flex-col w-[90%] md:w-3/4 text-center items-center justify-center space-y-3">
                    <div class="text-5xl font-bold" data-header-title="main-title">Clean Green Crypto Energy for Crypto Mining</div>
                    <div class="text-xl font-semibold text-gray-400" data-header-subtitle="main-subtitle">Help save the planet with KWATT tokenized electricity</div>
                    <div class="md:text-lg">
                        <input
                            class="bg-gray-200/70 border drop-shadow-sm shadow-gray-700 rounded-2xl placeholder-slate-400 px-4 py-1 focus:outline-none"
                            type="email"
                            placeholder="E-mail Address"
                        />
                        <button
                            class="p-1 m-2 rounded-2xl text-white bg-blue-500 px-4 font-semibold hover:bg-blue-900 hover:transform ease-in-out duration-300"
                        >
                            Subscribe
                        </button>
                    </div>
                </div>
                <div class="w-3/4 h-60 md:h-96 my-4 rounded-lg overflow-hidden">
                    <img data-header-image src="https://source.unsplash.com/500x400/?cryptocurrency,electricity" class="h-full w-full" alt="" />
                </div>
            </div>
        </div>
    </div>
</header>

`;
/* const cryptoLandingData = {
    headerLogo: "hero",
    menuItems: [
        { name: "Home", link: "#" },
        { name: "Contact", link: "#" },
        { name: "Services", link: "#" },
        { name: "About", link: "#" },
        { name: "Pricing", link: "#" }
    ],
    socialMedia: [
        { name: "twitter", icon: "fa-twitter", link: "#" },
        { name: "instagram", icon: "fa-instagram", link: "#" },
        { name: "facebook", icon: "fa-facebook", link: "#" },
        { name: "google", icon: "fa-google", link: "#" },
        { name: "linkedin", icon: "fa-linkedin", link: "#" }
    ],
    headerContent: {
        title: {
            text: "Clean Green Crypto Energy for Crypto Mining",
            dataAttribute: "main-title"
        },
        subtitle: {
            text: "Help save the planet with KWATT tokenized electricity",
            dataAttribute: "main-subtitle"
        }
    },
    subscribeForm: {
        placeholder: "E-mail Address",
        buttonText: "Subscribe"
    },
    mainImage: {
        src: "https://source.unsplash.com/500x400/?cryptocurrency,electricity",
        alt: "Cryptocurrency and electricity illustration"
    }
}; 
//js
const headerData = {
    logo: "MetaFlow3D",
    menuItems: ["AI Templates", "Pricing", "Documentation"],
    title: "Welcome to MetaFlow3D",
    subtitle: "Innovative AI Solutions"
};

// Оновлюємо логотип
$('[data-header-logo]').each(function(element) {
    element.textContent = headerData.logo; // Змінюємо текст на новий
});

// Оновлюємо меню
$('[data-header-menu]').each(function(menuElement) {
    menuElement.innerHTML = ''; // Очищуємо поточний вміст
    headerData.menuItems.forEach(item => {
        const li = document.createElement('li');
        li.className = "hover:underline underline-offset-4 decoration-2 decoration-white py-2 rounded-lg px-5";
        li.innerHTML = `<a href="#">${item}</a>`;
        menuElement.appendChild(li);
    });
});

// Оновлюємо заголовок та підзаголовок
$('[data-header-title]').each(function(titleElement) {
    titleElement.textContent = headerData.title;
});

$('[data-header-subtitle]').each(function(subtitleElement) {
    subtitleElement.textContent = headerData.subtitle;
});

*/