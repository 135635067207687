export const source = `
<!-- Gradient -->
<style>
.gradient-bg {
    background: radial-gradient(circle, rgb(60, 39, 164), rgb(4, 26, 4)); 
}
</style>
</head>
<section class="gradient-bg min-h-screen flex items-center justify-center p-4">
  <div class="w-full max-w-4xl aspect-video relative overflow-hidden">  
     <div class="relative z-10 w-full h-full flex items-center justify-center">
       <h2 class="text-white text-4xl font-bold">Ваш контент тут</h2>
     </div>
   </div>
</section>
`;
