export const source = `<style> .body__lp4 {
    margin: 0 30px;
    padding: 0;
    background: url('Images/Shape.png') right top no-repeat, #151719;
}

header {
    max-width: 994px;
    margin: 27px auto;
}

nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.logo {
    display: flex;
    align-items: center;
}

.links {
    display: flex;
    align-content: center;
}

nav .links a {
    color: #C5D2DC;
    text-decoration: none;
    font-family: Inter, sans-serif;
    margin: auto 48px;
}

nav .links button {
    background-color: #5D5DFF;
    font-family: Inter, sans-serif;
    color: #C5D2DC;
    border: none;
    width: 96px;
    height: 40px;
    font-size: 16px;
    border-radius: 2px;
}

nav .links button:hover {
    cursor: pointer;
    background-color: #5151db;
}

h1 {
    color: #D9E3EA;
    font-family: Inter, sans-serif;
    font-weight: 800;
    font-size: 52px;
    letter-spacing: -0.5px;
    margin: 0;
}

h2 {
    color: #D9E3EA;
    font-size: 40px;
    font-family: Inter, sans-serif;
    letter-spacing: -0.5px;
    font-weight: 800;
    margin: 0;
}

h3 {
    color: #D9E3EA;
    font-size: 32px;
    font-family: Inter, sans-serif;
    letter-spacing: -0.25px;
    font-weight: 700;
    margin: 0;
}

h4 {
    color: #D9E3EA;
    font-size: 24px;
    font-family: Inter, sans-serif;
    letter-spacing: -0.5px;
    font-weight: 700;
    margin: 0;
    margin-top: 15px;
}

h5 {
    font-size: 20px;
    color: #5D5DFF;
    font-family: Architects-Daughter, sans-serif;
    letter-spacing: 0.25px;
    font-weight: 400;
    margin-bottom: 10px;
}

main {
    max-width: 1140px;
    margin: 80px auto;
}

.hero {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.text-wrapper-1 {
    display: flex;
    justify-content: center;
}

.text-wrapper-1 p {
    font-size: 20px;
    max-width: 670px;
}

p {
    color: #9BA9B4;
    font-family: Inter, sans-serif;
    letter-spacing: -0.25px;
    font-size: 18px;
    font-weight: 400;
}

.group-btns {
    display: flex;
    justify-content: center;
}

.group-btns button:first-child {
    background-color: #5D5DFF;
    font-family: Inter, sans-serif;
    color: #ffffff;
    border: none;
    width: 167px;
    height: 40px;
    font-size: 16px;
    border-radius: 2px;
    margin-top: 32px;
    margin-right: 6px;
}

.group-btns button:first-child:hover {
    cursor: pointer;
    background-color: #5151db;
}

.group-btns button:last-child {
    background-color: #33363A;
    font-family: Inter, sans-serif;
    color: #C5D2DC;
    border: none;
    width: 149px;
    height: 40px;
    font-size: 16px;
    border-radius: 2px;
    margin-top: 32px;
    margin-left: 6px;
}

.group-btns button:last-child:hover {
    cursor: pointer;
    background-color: #27292c;
}

.video {
    max-width: 900px;
    margin: auto;
}

.video img {
    margin-top: 64px;
    max-width: 100%;
    height: auto;
    max-height: 100%;
}

.part-1 {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 128px;
    padding-bottom: 80px;
    border-bottom: 1px solid #33363A;
}

.blocks-wrapper-1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.blocks-wrapper-2 {
    margin: auto;
}

.blocks-wrapper-3 {
    margin: auto;
}

.container-1 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.text-wrapper-2 {
    display: flex;
    justify-content: center;
}

.text-wrapper-2 p {
    font-size: 20px;
    max-width: 800px;
}

.block {
    max-width: 320px;
    max-height: 700px;
    margin-top: 80px;
    padding: 0 20px;
}

.block p {
    font-size: 18px;
}

.part-2 {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 70px;
    padding-bottom: 80px;
    border-bottom: 1px solid #33363A
}

.part-3 {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 70px;
}

.text-wrapper-3 {
    display: flex;
    justify-content: center;
}

.text-wrapper-3 p {
    font-size: 20px;
    max-width: 700px;
}

.part-2 button {
    width: 186px;
    height: 30px;
    background-color: #C6F6D5;
    color: #36A269;
    border: none;
    border-radius: 15px;
    font-size: 14px;
    font-weight: 600;
    margin: auto;
    margin-bottom: 16px;
}

.text-block {
    max-width: 450px;
    text-align: left;
}

.image-block img {
    margin-top: 64px;
    max-width: 100%;
    height: auto;
    max-height: 100%;
}

.image-block {
    width: 500px;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.text-block ul {
    padding-left: 20px;
}

.text-block ul li {
    list-style-image: url('Images/Path.png');
    font-size: 18px;
    color: #9BA9B4;
    font-family: Inter, sans-serif;
    margin-top: 8px;
}

.text-wrapper-4 {
    display: flex;
    justify-content: center;
}

.text-wrapper-4 p {
    font-size: 20px;
    max-width: 750px;
    margin-bottom: 0;
}

.tab {
    width: 304px;
    background-color: #25282C;
    text-align: left;
    padding: 24px 24px;
    margin-top: 80px;
}

.tab p {
    border-bottom: 2px solid #97979725;
    padding-bottom: 24px;
}

.bottom-text {
    margin-top: 20px;
}

.name {
    color: #D9E3EA;
    font-family: Inter, sans-serif;
    letter-spacing: -0.25px;
    font-size: 16px;
}

.some-text {
    color: #5D5DFF;
    font-family: Inter, sans-serif;
    letter-spacing: -0.25px;
    font-size: 16px;
}

.some-text::before {
    content: "/ ";
    color: #33363A;
}

footer {
    max-width: 1140px;
    margin: 80px auto;
}

.subscribe {
    max-width: 100%;
    display: flex;
    justify-content: space-around;
    position: relative;
    height: auto;
    flex-wrap: wrap;
    align-items: center;
    padding: 65px 20px;
    background: url('Images/Fill 1.png') no-repeat right -100px, #5D5DFF;
}

.info-block {
    margin: 0 15px;
}

input[type="email"] {
    width: 295px;
    height: 48px;
    background-color: #4B4ACF;
    border: none;
    border-radius: 3px;
    margin-top: 20px;
    padding-left: 16px;
}

input[type="email"]::placeholder {
    padding-left: 8px;
    font-family: Inter, sans-serif;
    font-size: 16px;
    color: #ABABFF;
    letter-spacing: -0.25px;
    font-weight: 400;
}

input[type="email"]:focus {
    outline: none;
}

form {
    text-align: center;
}

.form-wrapper input {
    font-family: Inter, sans-serif;
    font-size: 16px;
    color: #ABABFF;
    letter-spacing: -0.25px;
    font-weight: 400;
}

input[type="submit"] {
    width: 139px;
    height: 48px;
    background-color: #F4F4FF;
    border: none;
    color: #5D5DFF;
    font-size: 16px;
    letter-spacing: -0.25px;
    font-weight: 600;
    font-family: Inter, sans-serif;
    border-radius: 3px;
    margin: 30px 8px;
    padding: 0;
}

input[type="submit"]:hover {
    background-color: #dfdfe9;
    cursor: pointer;
}

.additional-info {
    margin-top: 64px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}

.left-column {
    max-width: 352px;
    display: flex;
    flex-direction: column;
}

.right-columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.column ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.column ul li:first-child {
    color: #D9E3EA;
    font-weight: 500;
    font-family: Inter, sans-serif;
    margin: 0;
}

.column ul li {
    margin: 8px 0;
}

.column ul li a {
    font-size: 14px;
    color: #9BA9B4;
    font-family: Inter, sans-serif;
    font-weight: 400;
    text-decoration: none;
}

.column ul li a:hover {
    text-decoration: underline;
}

.social-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.info {
    font-size: 14px;
    color: #9BA9B4;
    font-family: Inter, sans-serif;
    font-weight: 400;
    margin-top: 49px;
}

.icon-group a:first-child~a {
    margin-left: 16px;
}

.icon-group {
    margin-top: 49px;
}

.columns-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 500px;
    justify-content: space-between;
}

#btn-to-top {
    position: fixed;
    bottom: 10px;
    right: 15px;
    z-index: 10;
    display: none;
}

#btn-to-top:hover {
    cursor: pointer;
    opacity: 0.8;
}

.sign-up-form {
    display: none;
    opacity: 0;
    justify-content: center;
}

.sign-up-form-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin-top: 80px;
}

label {
    font-size: 14px;
    color: #ffffff;
    font-family: Inter, sans-serif;
    font-weight: 500;
    margin: 16px 0 6px 0;
}

.sign-up {
    background-color: #5D5DFF;
    font-family: Inter, sans-serif;
    color: #C5D2DC;
    border: none;
    height: 48px;
    font-size: 16px;
    border-radius: 2px;
}

.sign-up:hover {
    cursor: pointer;
    background-color: #5151db;
}

.sign-up-form-wrapper input {
    background-color: #151719;
    height: 48px;
    border: 1.5px solid #33363A;
    border-radius: 10px;
}

.sign-up-form-wrapper input::placeholder {
    padding-left: 8px;
    color: #707D86;
}

.subtext {
    margin-top: 24px;
    text-align: center;
}

.form-text-1 {
    font-size: 16px;
    display: inline;
    margin: 24px 5px;
    text-align: center;
    color: #9BA9B4;
    line-height: 25px;
    background-color: #151719;
    position: relative;
    z-index: -1;
}

.form-text-1::before {
    content: '';
    display: block;
    height: 1px;
    background-color: #33363A;
    width: 23%;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: -2;
}

.form-text-1::after {
    content: '';
    display: block;
    height: 1px;
    background-color: #33363A;
    width: 23%;
    position: absolute;
    top: 50%;
    right: 0;
    z-index: -2;
}

.form-text-1 span {
    background-color: #151719;
    z-index: 1;
    padding: 0 5px;
}

.form-text-2 {
    font-size: 14px;
    display: inline;
    margin: 24px 5px;
    text-align: center;
    color: #707D86;
    line-height: 25px;
}

.sign-up-form a {
    display: inline;
    font-size: 16px;
    color: #9BA9B4;
}

.sign-up-form-wrapper p a:hover {
    text-decoration: none;
}

.subtext p {
    display: inline;
    font-size: 16px;
    margin-right: 10px;
}

.subtext a {
    text-decoration: none;
    color: #5D5DFF;
    font-family: Inter, sans-serif;
    font-weight: 400;
    letter-spacing: -0.25px;
}

.subtext a:hover {
    text-decoration: underline;
}

.sign-up-form input {
    font-family: Inter, sans-serif;
    font-size: 16px;
    color: #707D86;
    letter-spacing: -0.25px;
    font-weight: 400;
    padding-left: 8px;
}

.sign-up-form input:focus {
    outline: 1.5px solid #5D5DFF;
}

.btn-google {
    color: rgb(255, 255, 255);
    font-family: Inter, sans-serif;
    height: 48px;
    font-size: 16px;
    letter-spacing: -0.25px;
    font-weight: 400;
    border: none;
    border-radius: 3px;
    background: url(Images/Path-2.png) no-repeat 4%, url(Images/Rectangle.png) no-repeat 12%, #151719;
}

.btn-google:hover {
    background-color: #E53D3E;
    cursor: pointer;
}

/* font */
@font-face {
    font-family: Architects-Daughter;
    src: url(Fonts/ArchitectsDaughter-Regular.ttf);
} </style>`;