export const source = `
<section class="flex h-screen" data-section="hero-split">
<div class="w-1/2 bg-gray-900 flex items-center justify-center">
  <div class="text-white p-12">
    <h1 class="text-4xl font-bold mb-4">Трансформуйте ваші ідеї в 3D реальність</h1>
    <p class="mb-6">MetaFlow3D - ваш партнер у створенні вражаючих 3D проектів</p>
    <a href="#" class="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700">Дізнатися більше</a>
  </div>
</div>
<div class="w-1/2 bg-cover bg-center" style="background-image: url('path-to-your-image.jpg');"></div>
</section>
`;
