export const source =`

<!-- header -->
<header class="w-full px-6 bg-white">
    <div class="container mx-auto max-w-4xl md:flex justify-between items-center">
        <a href="#" class="block py-6 w-full text-center md:text-left md:w-auto text-gray-dark no-underline flex justify-center items-center">
            Your Logo
        </a>
        <div class="w-full md:w-auto text-center md:text-right">
            <!-- Login / Register -->
        </div>
    </div>
</header>
<!-- /header -->

<!-- nav -->
<nav class="w-full bg-white md:pt-0 px-6 relative z-20 border-t border-b border-gray-light">
    <div class="container mx-auto max-w-4xl md:flex justify-between items-center text-sm md:text-md md:justify-start">
        <div class="w-full md:w-1/2 text-center md:text-left py-4 flex flex-wrap justify-center items-stretch md:justify-start md:items-start">
            <a href="#" class="px-2 md:pl-0 md:mr-3 md:pr-3 text-gray-700 no-underline md:border-r border-gray-light">Home</a>
            <a href="#" class="px-2 md:pl-0 md:mr-3 md:pr-3 text-gray-700 no-underline md:border-r border-gray-light">Products</a>
            <a href="#" class="px-2 md:pl-0 md:mr-3 md:pr-3 text-gray-700 no-underline md:border-r border-gray-light">About Us</a>
            <a href="#" class="px-2 md:pl-0 md:mr-3 md:pr-3 text-gray-700 no-underline md:border-r border-gray-light">News</a>
            <a href="#" class="px-2 md:pl-0 md:mr-3 md:pr-3 text-gray-darkest no-underline">Contact</a>
        </div>
        <div class="w-full md:w-1/2 text-center md:text-right">
            <!-- extra links -->
        </div>
    </div>
</nav>
<!-- /nav -->

<!-- blog -->
<div class="w-full bg-white">

    <!-- title -->
    <div class="text-center px-6 py-12 mb-6 bg-gray-100 border-b">
        <h1 class=" text-xl md:text-4xl pb-4">Blog</h1>
        <p class="leading-loose text-gray-dark">
            Catch up on the latest news and updates.
        </p>
    </div>
    <!-- /title -->

    <div class="container max-w-4xl mx-auto md:flex items-start py-8 px-12 md:px-0">

        <!-- articles -->
        <div class="w-full md:pr-12 mb-12">
            <article class="mb-12">
                <h2 class="mb-4">
                    <a href="#" class="text-black text-xl md:text-2xl no-underline hover:underline">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    </a>
                </h2>

                <div class="mb-4 text-sm text-gray-700">
                    by <a href="#" class="text-gray-700">Joe Bloggs</a> on 19th March 2019
                    <span class="font-bold mx-1"> | </span>
                    <a href="#" class="text-gray-700">Uncategorised</a>
                    <span class="font-bold mx-1"> | </span>
                    <a href="#" class="text-gray-700">2 Comments</a>
                </div>

                <p class="text-gray-700 leading-normal">
                    Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                    proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>

            </article>

            <article class="mb-12">
                <h2 class="mb-4">
                    <a href="#" class="text-black text-xl md:text-2xl no-underline hover:underline">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    </a>
                </h2>

                <div class="mb-4 text-sm text-gray-700">
                    by <a href="#" class="text-gray-700">Joe Bloggs</a> on 19th March 2019
                    <span class="font-bold mx-1"> | </span>
                    <a href="#" class="text-gray-700">Uncategorised</a>
                    <span class="font-bold mx-1"> | </span>
                    <a href="#" class="text-gray-700">2 Comments</a>
                </div>

                <p class="text-gray-700 leading-normal">
                    Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                    proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>

            </article>

            <article class="mb-12">
                <h2 class="mb-4">
                    <a href="#" class="text-black text-xl md:text-2xl no-underline hover:underline">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    </a>
                </h2>

                <div class="mb-4 text-sm text-gray-700">
                    by <a href="#" class="text-gray-700">Joe Bloggs</a> on 19th March 2019
                    <span class="font-bold mx-1"> | </span>
                    <a href="#" class="text-gray-700">Uncategorised</a>
                    <span class="font-bold mx-1"> | </span>
                    <a href="#" class="text-gray-700">2 Comments</a>
                </div>

                <p class="text-gray-700 leading-normal">
                    Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                    proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>

            </article>

            <article class="mb-12">
                <h2 class="mb-4">
                    <a href="#" class="text-black text-xl md:text-2xl no-underline hover:underline">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    </a>
                </h2>

                <div class="mb-4 text-sm text-gray-700">
                    by <a href="#" class="text-gray-700">Joe Bloggs</a> on 19th March 2019
                    <span class="font-bold mx-1"> | </span>
                    <a href="#" class="text-gray-700">Uncategorised</a>
                    <span class="font-bold mx-1"> | </span>
                    <a href="#" class="text-gray-700">2 Comments</a>
                </div>

                <p class="text-gray-700 leading-normal">
                    Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                    proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>

            </article>

            <div class="flex justify-between text-xs">
                <a href="#" class="bg-gray text-white no-underline py-2 px-3 rounded">Previous</a>
                <a href="#" class="bg-black text-white no-underline py-2 px-3 rounded">Next</a>
            </div>
        </div>
        <!--/ articles -->

        <!-- sidebar -->
        <div class="w-full md:w-64">
            
            <aside class="rounded shadow overflow-hidden mb-6">
                <h3 class="text-sm bg-gray-100 text-gray-700 py-3 px-4 border-b">Categories</h3>

                <div class="p-4">
                    <ul class="list-reset leading-normal">
                        <li><a href="#" class="text-gray-darkest text-sm">Uncategorised</a></li>
                        <li><a href="#" class="text-gray-darkest text-sm">Food &amp; Drink</a></li>
                        <li><a href="#" class="text-gray-darkest text-sm">Garden</a></li>
                        <li><a href="#" class="text-gray-darkest text-sm">Tools</a></li>
                    </ul>
                </div>
            </aside>
            
            <aside class="rounded shadow overflow-hidden mb-6">
                <h3 class="text-sm bg-gray-100 text-gray-700 py-3 px-4 border-b">Latest Posts</h3>

                <div class="p-4">
                    <ul class="list-reset leading-normal">
                        <li><a href="#" class="text-gray-darkest text-sm">Lorem ipsum dolor sit amet.</a></li>
                        <li><a href="#" class="text-gray-darkest text-sm">Sit amet, consectetur adipisicing elit.</a></li>
                        <li><a href="#" class="text-gray-darkest text-sm">Lorem ipsum dolor sit amet.</a></li>
                        <li><a href="#" class="text-gray-darkest text-sm">Sit amet, consectetur adipisicing elit.</a></li>
                    </ul>
                </div>
            </aside>

        </div>
        <!-- /sidebar -->

    </div>

</div>
<!-- /blog -->


<!-- footer -->
<footer class="w-full bg-white px-6 border-t">
    <div class="container mx-auto max-w-4xl py-6 flex flex-wrap md:flex-no-wrap justify-between items-center text-sm">
        &copy;2019 Your Company. All rights reserved.
        <div class="pt-4 md:p-0 text-center md:text-right text-xs">
            <a href="#" class="text-black no-underline hover:underline">Privacy Policy</a>
            <a href="#" class="text-black no-underline hover:underline ml-4">Terms &amp; Conditions</a>
            <a href="#" class="text-black no-underline hover:underline ml-4">Contact Us</a>
        </div>
    </div>
</footer>
<!-- /footer -->`;