export const source = `  <div class="flex justify-center ">

<div class="flex flex-col justify-center  my-10 w-[90%]  max-w-7xl  ">
    <div class="flex flex-col justify-center items-center md:items-start  my-6">

        <div class="text-xl md:text-3xl text-blue-900 font-bold uppercase">Contact Us</div>
        <div class="text-sm md:text-xl text-blue-900 font-medium">We are here to help.</div>

    </div>
    <div class="flex   flex-col justify-center items-center lg:flex-row ">
        <div
            class="bg-gray-200 md:w-1/3 shadow-lg flex flex-col justify-center rounded-lg items-center py-4 h-56 md:w-[80%] lg:w-fit  my-6 mx-2">
            <div class="-mt-10 ">
                <ion-icon name="call-outline"
                    class="text-3xl md:text-5xl rounded-full p-4 bg-white text-white bg-blue-900 -mt-8">
                </ion-icon>
            </div>
            <div class="flex flex-col items-start px-8 text-blue-900">
                <div class="font-semibold text-2xl m-1">Call Us</div>
                <p class="  text-lg  m-1">Call us to solve your queries at any time.</p>
                <p class="  text-lg  m-1">+1 255-8997-469</p>

            </div>
        </div>
        <div
            class="bg-gray-200 md:w-1/3 shadow-lg flex flex-col justify-center rounded-lg items-center py-4 h-56 md:w-[80%] lg:w-fit my-6 mx-2">
            <div class="-mt-10 ">
                <ion-icon name="chatbubble-ellipses-outline"
                    class="text-3xl md:text-5xl rounded-full p-4 bg-white text-white bg-blue-900 -mt-8">
                </ion-icon>
            </div>
            <div class="flex flex-col items-start px-8 text-blue-900">
                <div class="font-semibold text-2xl m-1">Chat Live</div>
                <p class="  text-lg  m-1">We are available for customer support 24X7.</p>
                <p
                    class="  text-sm m-1 px-6 bg-blue-900 py-2 rounded hover:bg-blue-700 p-5 text-white font-medium">
                    <a href="#">Chat Now</a>
                </p>
            </div>
        </div>
        <div
            class="bg-gray-200 md:w-1/3 shadow-lg flex flex-col justify-center rounded-lg items-center py-4 h-56 md:w-[80%] lg:w-fit my-6 mx-2">
            <div class="-mt-10 ">
                <ion-icon name="mail-outline"
                    class="text-3xl md:text-5xl rounded-full p-4 bg-white text-white bg-blue-900 -mt-8">
                </ion-icon>
            </div>
            <div class="flex flex-col items-start px-8 text-blue-900">
                <div class="font-semibold text-2xl m-1">Chat Live</div>
                <p class="  text-lg  m-1">We are available for chat to solve your problems.</p>
                <p
                    class="  text-sm m-1 px-6 bg-blue-900 py-2 rounded hover:bg-blue-700 p-5 text-white font-medium">
                    <a href="#">Get Started</a>
                </p>
            </div>
        </div>

    </div>
</div>
</div>`;
