export const source = `    <div class="flex justify-center ">
<div class="flex md:flex-row flex-col justify-center max-w-7xl m-4 ">
    <div class="lg:w-1/2 bg-black ">
        <div class="flex justify-center flex-col items-center">
            <div class="text-white m-6 text-xl z-200"> Contact Us </div>
            <div class="text-white m-6 z-200"> We are always here to help out whatever way we can :) </div>
            <div class="text-white m-6 z-200">
                <div class="flex m-2">
                    <ion-icon name="person-outline" class="m-2"></ion-icon> <input placeholder="Name"
                        class="bg-gray-800 p-2 rounded-3xl">
                </div>
                <div class="flex m-2">
                    <ion-icon name="mail-outline" class="m-2"></ion-icon> <input placeholder="E-mail"
                        class="bg-gray-800 p-2 rounded-3xl">
                </div>
                <div class="flex m-2">
                    <ion-icon name="call-outline" class="m-2"></ion-icon> <input placeholder="Phone"
                        class="bg-gray-800 p-2 rounded-3xl">
                </div>
                <div class="flex m-2">
                    <ion-icon name="create-outline" class="m-2"></ion-icon> <input
                        placeholder="Type your message" class="bg-gray-800 p-2 rounded-3xl">
                </div>
                <div class="block m-2justify-center">
                    <div class="bg-blue-700 p-2 rounded-3xl hover:bg-blue-800 cursor-pointer ">
                        <div class="flex justify-center"> Send </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="w-3/4 lg:w-2/3 ">
        <div class="w-3/4 lg:w-1/2 overflow-contain h-min"> <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387193.315540303!2d-74.26054823358196!3d40.697147766721685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1656264173526!5m2!1sen!2sin"
                width="375" height="667" style="border:0;" allowfullscreen="" loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe> </div>
    </div>
</div>
</div>`;
