export const source = 
` <section class="bg-gray-900 py-20 relative overflow-hidden">
<!-- Фоновий градієнт -->
<div class="absolute inset-0 bg-gradient-to-br from-gray-900 to-gray-800 opacity-50"></div>

<div class="container mx-auto px-4 relative z-10">
  <div class="max-w-4xl mx-auto">
    <h2 class="text-3xl md:text-4xl lg:text-5xl font-bold text-white mb-6 leading-tight">
      Achieve Reliable And Precise Outcomes With
      <span class="text-blue-500">Full Support</span> For Both Images And Videos.
    </h2>

    <div class="relative mt-8 rounded-lg overflow-hidden shadow-2xl">
      <img src="/api/placeholder/1200/600" alt="High-quality 3D render of a sports car in mountains" class="w-full object-cover" />

      <!-- Overlay for better text readability -->
      <div class="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-60"></div>

      <div class="absolute bottom-0 left-0 p-6 md:p-8">
        <p class="text-white text-lg md:text-xl font-semibold mb-2">Photorealistic Rendering</p>
        <p class="text-gray-300 text-sm md:text-base">Our advanced algorithms ensure stunning visual quality in both still images and dynamic videos.</p>
      </div>
    </div>
  </div>
</div>

<!-- Декоративні елементи -->
<div class="absolute top-10 right-10 w-20 h-20 border-t-2 border-r-2 border-blue-500 opacity-20"></div>
<div class="absolute bottom-10 left-10 w-20 h-20 border-b-2 border-l-2 border-blue-500 opacity-20"></div>
</section>`;
