export const source = `    <div class="flex justify-center ">

<div class="flex flex-col justify-center w-[90%]  max-w-7xl  ">
    <div class="flex flex-col justify-center items-center md:items-start  my-4">

        <div class="text-xl md:text-3xl text-blue-900 font-bold uppercase">Contact Us</div>
    </div>
    <div class="flex   flex-col items-start  w-full">
        <div class="text-2xl font-bold text-blue-900 my-2">Tailblocks
        </div>
        <div class="flex flex-col md:flex-row items-start md:items-center justify-start w-full md:w-3/4">
            <div class="flex m-2 md:mx-8">
                <ion-icon name="phone-portrait-outline" class="text-5xl text-blue-900"></ion-icon>
                <div class="mx-2">
                    <div class="text-xl font-semibold text-blue-900">Give us a Call</div>
                    <div class="text-blue-900 font-medium">+1 966-8997-325</div>
                </div>
            </div>
            <div class="flex  m-2 md:mx-8">
                <ion-icon name="mail-outline" class="text-5xl text-blue-900"></ion-icon>
                <div class="mx-2">
                    <div class="text-xl font-semibold text-blue-900">Send us an email</div>
                    <div class="text-blue-900 font-medium">admin@tailblocks.org</div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex   flex-col items-start  w-full ">
        <div class="text-2xl font-bold text-blue-900 my-2">New Website
        </div>
        <div class="flex flex-col md:flex-row items-start md:items-center justify-start w-full md:w-3/4">
            <div class="flex m-2 md:mx-8">
                <ion-icon name="phone-portrait-outline" class="text-5xl text-blue-900"></ion-icon>
                <div class="mx-2">
                    <div class="text-xl font-semibold text-blue-900">Give us a Call</div>
                    <div class="text-blue-900 font-medium">+1 966-8997-325</div>
                </div>
            </div>
            <div class="flex  m-2 md:mx-8">
                <ion-icon name="mail-outline" class="text-5xl text-blue-900"></ion-icon>
                <div class="mx-2">
                    <div class="text-xl font-semibold text-blue-900">Send us an email</div>
                    <div class="text-blue-900 font-medium">admin@tailblocks.org</div>
                </div>
            </div>
            <div class="flex  m-2 md:mx-8">
                <ion-icon name="location-outline" class="text-5xl text-blue-900"></ion-icon>
                <div class="mx-2">
                    <div class="text-xl font-semibold text-blue-900">Come see us</div>
                    <div class="text-blue-900 font-medium">13/B Wall Street, NY</div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>`;
