export const source = `<script src="https://cdn.tailwindcss.com"></script>
<script src="https://use.fontawesome.com/03f8a0ebd4.js"></script>
<div class="flex items-center justify-center">
    <div class="m-2 max-w-5xl">
        <div class="flex flex-col items-center justify-around md:flex-row">
            <div class="items-center m-1 justify-items-start w-60"> <img class="flex m-1"
                    src="https://dummyimage.com/150x50" />
                <div class="text-gray-500 md:text-center"> Lorem ipsum dolor sit amet consectetur adipisicing elit.
                </div>
            </div>
            <div class="items-center m-1 justify-items-start w-60">
                <h1 class="flex flex-col text-xl text-blue-600 font-medium"> Product </h1> <a href="#"
                    class="flex hover:text-blue-600 text-gray-500">Features</a> <a href="#"
                    class="flex hover:text-blue-600 text-gray-500">Integrations</a> <a href="#"
                    class="flex hover:text-blue-600 text-gray-500">Shopify Integrations</a> <a href="#"
                    class="flex hover:text-blue-600 text-gray-500">Pricing</a> <a href="#"
                    class="flex hover:text-blue-600 text-gray-500">Chrome Extensions</a>
            </div>
            <div class="items-center m-1 justify-start w-60">
                <h1 class="flex flex-col text-xl text-blue-600 font-medium"> Company </h1> <a href="#"
                    class="flex hover:text-blue-600 text-gray-500">Terms</a> <a href="#"
                    class="flex hover:text-blue-600 text-gray-500">Customers</a> <a href="#"
                    class="flex hover:text-blue-600 text-gray-500">Blog</a> <a href="#"
                    class="flex hover:text-blue-600 text-gray-500">Terms of Services</a> <a href="#"
                    class="flex hover:text-blue-600 text-gray-500">Privacy Policy</a>
            </div>
            <div class="items-center m-1 w-60">
                <h1 class="flex flex-col text-xl text-blue-600 font-medium"> Support </h1> <a href="#"
                    class="flex hover:text-blue-600 text-gray-500">Community</a> <a href="#"
                    class="flex hover:text-blue-600 text-gray-500">Support Customers</a> <a href="#"
                    class="flex hover:text-blue-600 text-gray-500">Documentations</a> <a href="#"
                    class="flex hover:text-blue-600 text-gray-500">Service Providers</a> <a href="#"
                    class="flex hover:text-blue-600 text-gray-500">Email Contact</a>
            </div>
            <div class="items-center m-1 w-60 md:-mt-16">
                <h1 class="flex flex-col text-xl text-blue-600 font-medium"> Contact Us </h1> <a href="#"
                    class="flex hover:text-blue-600 text-gray-500">suppport@admin.com</a> <a href="#"
                    class="flex hover:text-blue-600 text-gray-500">(+251)987-6543-210</a>
            </div>
        </div>
        <div class="mt-2 flex flex-row justify-between p-2 bg-black w-full">
            <div class="flex"> <a href="#"
                    class="w-9 h-9 rounded-full text-white hover:text-blue-600 pt-1 inline-block"><i
                        class="fa fa-twitter p-1"></i></a> <a href="#"
                    class="w-9 h-9 rounded-full text-white hover:text-blue-600 pt-1 inline-block"><i
                        class="fa fa-instagram p-1"></i></a> <a href="#"
                    class="w-9 h-9 rounded-full text-white hover:text-blue-600 pt-1 inline-block"><i
                        class="fa fa-facebook p-1"></i></a> <a href="#"
                    class="w-9 h-9 rounded-full text-white hover:text-blue-600 pt-1 inline-block"><i
                        class="fa fa-linkedin p-1"></i></a> <a href="#"
                    class="w-9 h-9 rounded-full text-white hover:text-blue-600 pt-1 inline-block"><i
                        class="fa fa-quora p-1"></i></a> </div>
        </div>
    </div>
</div> `;
