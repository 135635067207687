export const source = ` <div class="flex justify-center">
<div class="flex flex-col justify-center">
    <div class="flex flex-col md:flex-row max-w-7xl justify-center items-center ">
        <div class="overflow-hidden w-full m-4 flex justify-center">
            <div
                class="flex flex-col md:flex-row items-center shadow-md h-full text-white bg-orange-500 hover:bg-white hover:text-black">
                <div class=" w-1/2 overflow-hidden ">
                    <div class="flex flex-col justify-center h-full p-2">
                        <div class="font-bold text-3xl m-2">Find the best place for your Startup. </div>
                        <div class=" m-2 hover:text-orange-500"><a href="#">Learn More</a></div>
                    </div>
                </div>
                <div class=" md:w-1/2 overflow-hidden "> <img src="https://source.unsplash.com/700x600/?building"
                        alt="" class="" /> </div>
            </div>
        </div>
    </div>
</div>
</div>`;
