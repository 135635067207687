export const source = `
<!-- Industries Section -->
    <section class="bg-gray-900 py-20 relative overflow-hidden" data-section="industries">
        <div class="container mx-auto px-4 relative z-10">
            <h2 class="text-2xl md:text-3xl text-white mb-8">Perfect for businesses in diverse industries seeking efficiency...</h2>
            <div class="grid grid-cols-2 md:grid-cols-3 gap-4 mb-8" data-element="industry-buttons">
                <!-- Industry buttons here -->
            </div>
            <div class="rounded-lg overflow-hidden shadow-2xl mb-8" data-element="industry-image">
                <!-- Main industry image here -->
            </div>
            <div class="relative" data-element="industry-carousel">
                <!-- Industry image carousel here -->
            </div>
        </div>
    </section>
`;
