export const source = `   <div class="flex justify-center">
<div
    class="flex  justify-center  shadow max-w-7xl w-[90%]  bg-gradient-to-bl from-violet-600 via-purple-600 to-violet-500 m-4">
   
    <div class="h-[90%] w-full mt-7">
        <div class="mb-6 text-white">
           <a href="#" class="flex justify-end mr-8 space-x-2 hover:underline underline-offset-4">
               <h1>Signup</h1>
               <i class="fa-solid fa-arrow-right-long mt-[6px]"></i>
           </a> 

        </div>
        <div class=" flex flex-col justify-center items-center space-y-2">
            <div>
                <h1 class="text-xl font-semibold text-white">Here you can Login</h1>
            </div>
            <div>
                <h1 class="text-sm font-semibold text-gray-200">Let's join us :)</h1>
            </div>
        </div>
        <div class="flex flex-col justify-center items-center mt-7 space-y-6 md:space-y-8">
            <div class="space-y-2">
                <h1 class="text-base text-gray-200">Email</h1>
                <input type="email" placeholder=""
                    class="  rounded-md px-5 py-2 bg-gray-300 text-gray-600 focus:outline-none font-semibold md:w-72 lg:w-[340px]">
            </div>
            <div class="space-y-2">
                <h1 class="text-base text-gray-200">Password</h1>
                <input type="password" placeholder=""
                    class="  rounded-md px-5 py-2 bg-gray-300 text-gray-600 focus:outline-none font-semibold md:w-72 lg:w-[340px]">
            </div>
        </div>
        <div class="text-center mt-10">
            <button
                class="uppercase px-24 md:px-[118px] lg:px-[140px] py-2 rounded-md text-white bg-gradient-to-b from-violet-700 via-violet-600 to-violet-700 hover:brightness-105  font-medium ">login</button>
        </div>
        <div class="text-center my-6">
            <a href="#" class="text-sm font-medium text-gray-200 hover:text-white hover:underline underline-offset-4">Forgot your
                Password ?</a>
        </div>
    </div>
</div>
</div>`;
