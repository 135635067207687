import { useState } from "react";
import axios from "axios";
import ModalCustom from "../partial/ModalCustom";

const MessageSender = ({ projectId, setMessage }) => {
  const [messageData, setMessageData] = useState({
    content: "",
    format: "simple",
    destination_type: "channel",
    destination: "",
    keyboard: [[]],
  });

  const [isOpenKeyboardHint, setIsOpenKeyboardHint] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const sanitizeHtml = (html: string): string => {
    const allowedTags = ["b", "i", "u", "s", "a", "code", "pre"];
    const doc = new DOMParser().parseFromString(html, "text/html");
    const sanitized = doc.body.innerHTML;
    return allowedTags
      .reduce((acc, tag) => {
        const regex = new RegExp(`<\/?${tag}(\\s+[^>]*)?>`, "gi");
        return acc.replace(regex, (match) => match.toLowerCase());
      }, sanitized)
      .replace(/<br\s*\/?>/gi, "\n");
  };

  const handleMessageDataChange = (e) => {
    const { name, value } = e.target;
    setMessageData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addKeyboardRow = () => {
    setMessageData((prev) => ({
      ...prev,
      keyboard: [...prev.keyboard, []],
    }));
  };

  const addKeyboardButton = (rowIndex) => {
    setMessageData((prev) => ({
      ...prev,
      keyboard: prev.keyboard.map((row, i) => (i === rowIndex ? [...row, { text: "👍 Лайк", callback_data: "like_post" }] : row)),
    }));
  };

  const updateKeyboardButton = (rowIndex, buttonIndex, field, value) => {
    setMessageData((prev) => ({
      ...prev,
      keyboard: prev.keyboard.map((row, i) => (i === rowIndex ? row.map((button, j) => (j === buttonIndex ? { ...button, [field]: value } : button)) : row)),
    }));
  };

  const removeKeyboardButton = (rowIndex, buttonIndex) => {
    setMessageData((prev) => ({
      ...prev,
      keyboard: prev.keyboard.map((row, i) => (i === rowIndex ? row.filter((_, j) => j !== buttonIndex) : row)).filter((row) => row.length > 0),
    }));
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    let content = messageData.content;
    if (messageData.format === "html") {
      content = sanitizeHtml(content);
    }
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${apiUrl}/telegram/send-message`,
        {
          ...messageData,
          content,
          project_id: projectId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setMessage("Message sent successfully to Telegram");
    } catch (error) {
      console.error("Error sending message:", error);
      setMessage("Error sending message to Telegram");
    }
  };

  const closeKeyboardHint = () => setIsOpenKeyboardHint(false);
  const showKeyboardHint = () => setIsOpenKeyboardHint(true);

  const keyboardHintExample = `{
  "keyboard": [
    [
      {"text": "👍 Лайк", "callback_data": "like_post"},
      {"text": "💬 Коментарі", "url": "https://t.me/your_discussion_group"}
    ],
    [
      {"text": "😊", "callback_data": "reaction_smile"},
      {"text": "🤔", "callback_data": "reaction_think"},
      {"text": "👏", "callback_data": "reaction_clap"}
    ]
  ],
  "notification_enabled": false,
  "custom_message": "Ваше повідомлення тут"
}`;

  return (
    <div className="mb-8">
      <h2 className="text-xl font-semibold mb-4">Send Message</h2>
      <form onSubmit={sendMessage}>
        <div className="mb-4">
          <label className="block mb-2">Message Content:</label>
          <textarea
            name="content"
            value={messageData.content}
            onChange={handleMessageDataChange}
            className="w-full p-2 border rounded"
            rows={4}
            required
          ></textarea>
          {messageData.format === "html" && (
            <p className="mt-2 text-sm text-gray-600">
              Supported HTML tags: &lt;b&gt;, &lt;i&gt;, &lt;u&gt;, &lt;s&gt;, &lt;a href=""&gt;, &lt;code&gt;, &lt;pre&gt;, &lt;br&gt;
            </p>
          )}
        </div>
        <div className="mb-4">
          <label className="block mb-2">Format:</label>
          <select name="format" value={messageData.format} onChange={handleMessageDataChange} className="w-full p-2 border rounded">
            <option value="simple">Simple</option>
            <option value="formatted">Formatted (Markdown)</option>
            <option value="html">HTML</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block mb-2">Destination Type:</label>
          <select name="destination_type" value={messageData.destination_type} onChange={handleMessageDataChange} className="w-full p-2 border rounded">
            <option value="channel">Channel</option>
            <option value="group">Group</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block mb-2">Destination:</label>
          <input
            type="text"
            name="destination"
            value={messageData.destination}
            onChange={handleMessageDataChange}
            className="w-full p-2 border rounded"
            placeholder="karambda, -4564997132"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Keyboard:</label>
          <a href="#" onClick={showKeyboardHint} className="text-blue-500 ml-2">
            (Приклад налаштувань)
          </a>
          {messageData.keyboard.map((row, rowIndex) => (
            <div key={rowIndex} className="mb-2 flex items-center">
              {row.map((button, buttonIndex) => (
                <div key={buttonIndex} className="mr-2 flex items-center">
                  <input
                    type="text"
                    value={button.text}
                    onChange={(e) => updateKeyboardButton(rowIndex, buttonIndex, "text", e.target.value)}
                    placeholder="Button text"
                    className="p-1 border rounded mr-1"
                  />
                  <input
                    type="text"
                    value={button.callback_data}
                    onChange={(e) => updateKeyboardButton(rowIndex, buttonIndex, "callback_data", e.target.value)}
                    placeholder="Callback data"
                    className="p-1 border rounded mr-1"
                  />
                  <button type="button" onClick={() => removeKeyboardButton(rowIndex, buttonIndex)} className="bg-red-500 text-white px-2 py-1 rounded">
                    X
                  </button>
                </div>
              ))}
              <button type="button" onClick={() => addKeyboardButton(rowIndex)} className="bg-green-500 text-white px-2 py-1 rounded">
                + Button
              </button>
            </div>
          ))}
          <button type="button" onClick={addKeyboardRow} className="bg-blue-500 text-white px-2 py-1 rounded mt-2">
            + Row
          </button>
        </div>
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
          Send Message
        </button>
      </form>

      {isOpenKeyboardHint && (
        <ModalCustom isOpen={isOpenKeyboardHint} closeModal={closeKeyboardHint} text="Приклад налаштувань клавіатури" zIndex={10}>
          <div className="p-4">
            <h3 className="text-lg font-semibold mb-2">Приклад налаштувань для additional_settings:</h3>
            <pre className="bg-gray-100 p-2 rounded overflow-x-auto">{keyboardHintExample}</pre>
            <p className="mt-4">Цей приклад демонструє, як додати кнопки до повідомлення. Зверніть увагу:</p>
            <ul className="list-disc list-inside mt-2">
              <li>Кнопки групуються в рядки всередині масиву "keyboard".</li>
              <li>Кожна кнопка має "text" (текст, який буде відображатися) та "callback_data" або "url".</li>
              <li>"callback_data" використовується для кнопок, які відправляють дані назад боту.</li>
              <li>"url" використовується для кнопок, які відкривають посилання.</li>
              <li>Ви можете додати інші налаштування, такі як "notification_enabled" та "custom_message".</li>
            </ul>
            <p className="mt-4">Примітка: переконайтеся, що ваш бот має права адміністратора і може редагувати повідомлення.</p>
          </div>
        </ModalCustom>
      )}
    </div>
  );
};

export default MessageSender;
