export const source = ` 
<section class="bg-gray-100 py-20" data-section="testimonials-social">
<div class="container mx-auto px-4">
  <h2 class="text-3xl font-bold text-center mb-12">MetaFlow3D в соціальних мережах</h2>
  <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
    <div class="bg-white p-6 rounded-lg shadow">
      <div class="flex items-center mb-4">
        <img src="twitter-icon.svg" alt="Twitter" class="w-6 h-6 mr-2">
        <span class="font-bold">@user1</span>
      </div>
      <p class="text-gray-600">Just finished my first project with #MetaFlow3D. Mind = Blown! 🚀 #3Ddesign</p>
    </div>
    <!-- Додайте ще 2 подібних блоки з відгуками з інших соціальних мереж -->
  </div>
</div>
</section>
`;
