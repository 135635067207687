export const source =`
<title>Skyscraper Template</title> 
<div class="font-sans font-thin">

    <!-- HEADER / -->
    <div class="relative z-10 w-full h-24 px-12 bg-white flex justify-between">

        <a href="#" class="block h-full w-12 flex items-center text-lg md:text-2xl font-bold tracking-widest text-gray-700 uppercase hover:text-gray-400">skyscraper</a>
        
        <a href="#" class="relative block px-3 h-32 bg-blue-900 text-white text-center tracking-widest uppercase text-xs font-bold pt-8 pb-6 flex flex-col items-center justify-between hover:bg-gray-900">
            <span>menu</span>
            <div class="flex items-end justify-center">
                <span class="block h-5 w-1 bg-yellow-600"></span>
                <span class="block h-8 w-1 bg-yellow-600 mx-1"></span>
                <span class="block h-5 w-1 bg-yellow-600"></span>
            </div>
        </a>

    </div>
    <!-- / HEADER -->

    <div class="-mt-24 pt-24 w-full h-screen bg-white flex flex-wrap">
        
        <div class="px-12 md:-px-0 w-full md:w-4/12 bg-white flex items-center justify-start md:justify-end">
            <div class="shadow-xl md:shadow-none px-12 py-12 md:pl-0 md:pr-24 bg-white md:-mr-64 relative z-10">
                <h1 class="mb-8 text-3xl md:text-5xl text-gray-800 text-bold uppercase font-bold tracking-widest leading-tight">
                    Luxury<br />house<br />shares
                </h1>
                <h2 class="text-lg md:text-2xl font-serif text-gray-500">Exceptional Contemporary Living</h2>

                <span class="hidden md:block absolute top-0 right-0 w-48 h-4 bg-blue-900 -mr-24 mt-56"></span>
            </div>
        </div>
        
        <div class="-mt-64 md:mt-0 w-full md:flex-1">
            <img src="https://images.unsplash.com/photo-1545022388-43a762e088b0?w=1400" class="w-full h-full object-cover" />
        </div>

    </div>


    <div class="bg-gray-800 px-12 py-24 flex items-center">

        <div class="hidden md:block w-3/12"></div>

        <div class="flex-1 relative">
            <h2 class="mb-8 uppercase text-white text-2xl font-bold tracking-widest">Our Expertise</h2>
            <p class="font-serif text-gray-500 text-base md:text-xl max-w-2xl leading-loose">RJ Investments brings luxury living to the rental market through bespoke renovations, at affordable prices with quality property management.</p>

            <span class="absolute top-0 left-0 w-4 h-full bg-gray-300 -ml-20"></span>
        </div>

    </div>


    <!-- SPACER / -->
    <div class="w-full h-24 border-black bg-white flex justify-end">
        <div class="w-64 bg-gray-800"></div>
    </div>
    <!-- / SPACER -->

    <div class="w-full flex items-center justify-start relative">
        <div class="w-auto md:w-3/12"></div>

        <div class="w-full md:w-8/12 bg-gray-100 py-12 relative flex">

            <a href="#" class="block relative z-10 bg-white h-full w-full px-12 md:px-0 md:w-6/12 py-24 md:-ml-24">
                <h3 class="mb-2 uppercase text-gray-800 text-3xl font-bold tracking-widest">The Portfolio</h3>
                <p class="text-lg md:text-xl font-serif text-gray-400">Completed Builds</p>
            </a>

            <img src="https://images.unsplash.com/photo-1489171078254-c3365d6e359f?w=1200" class="absolute top-0 left-0 block w-full h-full object-cover" />

        </div>

        <div class="w-1/12 absolute top-0 right-0 h-full bg-gray-800"></div>
    </div>


    <!-- SPACER / -->
    <div class="w-full h-12 border-black bg-white flex justify-end">
        <div class="w-64 bg-gray-800"></div>
    </div>
    <!-- / SPACER -->


    <div class="w-full flex items-center justify-start relative">
        <div class="w-auto md:w-3/12"></div>

        <div class="w-full md:w-8/12 bg-gray-100 py-12 relative flex">

            <a href="#" class="block relative z-10 bg-white h-full w-full px-12 md:px-0 md:w-6/12 py-24 md:-ml-24">
                <h3 class="mb-2 uppercase text-gray-800 text-3xl font-bold tracking-widest">The Projects</h3>
                <p class="text-lg md:text-xl font-serif text-gray-400">Current Developments</p>
            </a>

            <img src="https://images.unsplash.com/photo-1537726235470-8504e3beef77?w=1200" class="absolute top-0 left-0 block w-full h-full object-cover" />

        </div>

        <div class="w-1/12 absolute top-0 right-0 h-full bg-gray-800"></div>
    </div>


    <!-- SPACER / -->
    <div class="w-full h-12 border-black bg-white flex justify-end">
        <div class="w-64 bg-gray-800"></div>
    </div>
    <!-- / SPACER -->


    <div class="w-full flex items-center justify-start relative">
        <div class="w-auto md:w-3/12"></div>

        <div class="w-full md:w-8/12 bg-gray-100 py-12 relative flex">

            <a href="#" class="block relative z-10 bg-white h-full w-full px-12 md:px-0 md:w-6/12 py-24 md:-ml-24">
                <h3 class="mb-2 uppercase text-gray-800 text-3xl font-bold tracking-widest">Our Properies</h3>
                <p class="text-lg md:text-xl font-serif text-gray-400">Complete Collection</p>
            </a>

            <img src="https://images.unsplash.com/photo-1545060894-7b45236d00b3?w=1200" class="absolute top-0 left-0 block w-full h-full object-cover" />

        </div>

        <div class="w-1/12 absolute top-0 right-0 h-full bg-gray-800"></div>
    </div>


    <!-- SPACER / -->
    <div class="w-full h-24 border-black bg-white flex justify-end">
        <div class="w-64 bg-gray-800"></div>
    </div>
    <!-- / SPACER -->


    <div class="bg-gray-800">
        
        <div class="mx-auto max-w-5xl flex flex-wrap justify-center items-center text-center py-20">
            
            <a href="#" class="w-full md:w-1/3 px-12">
                <h4 class="mb-2 uppercase text-gray-100 text-lg font-bold tracking-widest">Investment Options</h4>
                <p class="text-sm md:text-lg font-serif text-gray-400">Get More</p>
            </a>
            
            <a href="#" class="w-full md:w-1/3 py-12 px-12 md:border-l-4 md:border-r-4 md:border-gray-700">
                <h4 class="mb-2 uppercase text-gray-100 text-lg font-bold tracking-widest">Accomodation</h4>
                <p class="text-sm md:text-lg font-serif text-gray-400">Future Tenants</p>
            </a>
            
            <a href="#" class="w-full md:w-1/3 px-12">
                <h4 class="mb-2 uppercase text-gray-100 text-lg font-bold tracking-widest">Land Development</h4>
                <p class="text-sm md:text-lg font-serif text-gray-400">The Homes</p>
            </a>

        </div>

    </div>

    
    <!-- FOOTER / -->
    <div class="bg-gray-800 pb-24">
        <div class="bg-white max-w-6xl mx-auto py-12">
            <div class="px-12 md:px-24">
                <a href="#" class="block mb-2 uppercase text-gray-800 text-lg font-bold tracking-widest hover:text-yellow-600">0800 123 456</a>
                <a href="#" class="block uppercase text-gray-800 text-lg font-bold tracking-widest hover:text-yellow-600">hello@digizu.co.uk</a>
            </div>

            <div class="flex py-12">
                <div class="flex-1 border-b-8 border-gray-200"></div>
                <div class="w-32"></div>
                <div class="flex-1 border-b-8 border-gray-200"></div>
            </div>

            <div class="flex flex-wrap justify-between px-24 text-sm font-serif text-gray-500">
                <div class="w-full md:flex-1">&copy; 2019 copyright</div>
                <div class="w-full md:flex-1 md:text-right">Powered by Tailwind</div>
            </div>
        </div>
    </div>
    <!-- / FOOTER -->


</div>
`;