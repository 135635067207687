export const source = `  <!-- ====== Avatars Section Start -->
<section class="bg-white py-[75px] dark:bg-dark">
  <div class="mx-auto px-4 sm:container">
    <div class="flex items-end justify-center space-x-2 sm:space-x-5">
      <div class="h-6 w-6">
        <img
          src="../images/avatar/image-01.jpg"
          alt="avatar"
          class="h-full w-full object-cover object-center"
        />
      </div>
      <div class="h-[38px] w-[38px]">
        <img
          src="../images/avatar/image-02.jpg"
          alt="avatar"
          class="h-full w-full object-cover object-center"
        />
      </div>
      <div class="h-[42px] w-[42px]">
        <img
          src="../images/avatar/image-03.jpg"
          alt="avatar"
          class="h-full w-full object-cover object-center"
        />
      </div>
      <div class="h-[52px] w-[52px]">
        <img
          src="../images/avatar/image-04.jpg"
          alt="avatar"
          class="h-full w-full object-cover object-center"
        />
      </div>
      <div class="h-20 w-20">
        <img
          src="../images/avatar/image-05.jpg"
          alt="avatar"
          class="h-full w-full object-cover object-center"
        />
      </div>
    </div>
  </div>
</section>
<!-- ====== Avatars Section End -->`;
