export const source =`
<div class="bg-white font-serif">

<header class="flex flex-wrap items-center justify-between px-12 h-32 -mb-32 relative">

    <ul class="order-last md:order-first flex-1 flex justify-center md:justify-start list-reset">
        <li>
            <a href="#" class="text-sm text-grey-darker no-underline hover:text-black">Home</a>
        </li>
        <li class="ml-8">
            <a href="#" class="text-sm text-grey-darker no-underline hover:text-black">About</a>
        </li>
        <li class="ml-8">
            <a href="#" class="text-sm text-grey-darker no-underline hover:text-black">Contact</a>
        </li>
    </ul>
    
    <div class="w-full md:w-auto flex justify-center">
        <a href="#" class="block text-center text-black text-lg no-underline">
            <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0xMyA5aDlsLTE0IDE1IDMtOWgtOWwxNC0xNS0zIDl6bS04LjY5OSA1aDguMDg2bC0xLjk4NyA1Ljk2MyA5LjI5OS05Ljk2M2gtOC4wODZsMS45ODctNS45NjMtOS4yOTkgOS45NjN6Ii8+PC9zdmc+" alt="Mobilise" class="w-10 h-auto" />
        </a>
    </div>

    <ul class="flex-1 flex justify-center md:justify-end list-reset">
        <li class="ml-8 hidden md:inline">
            <a href="#" class="text-sm font-bold px-4 py-3 no-underline text-white bg-black hover:bg-gray-800">Subscribe now</a>
        </li>
    </ul>

</header>

<div class="w-full flex flex-wrap md:h-screen pt-32">
    <div class="pt-6 md:pt-0 w-full md:flex-1 md:order-last">
        <img src="https://images.unsplash.com/photo-1523275335684-37898b6baf30?w=1400" class="w-full h-64 md:h-full object-cover" />
    </div>
    <div class="w-full p-6 pb-12 md:p-12 md:w-5/12 flex justify-center items-center relative">
        <div class="w-full relative text-center py-12 px-12 md:p-0 md:text-right">
            <h1 class="text-5xl mb-4">Mobilise Wrist</h1>
            <h2 class="text-2xl mb-4">Ut vel nunc a est auctor lacinia.</h2>
            <p class="leading-loose tracking-wide text-gray-700">Curabitur convallis aliquet dignissim. Nulla at risus feugiat, egestas lacus at, pharetra risus. Aliquam vitae semper leo, quis ornare massa. Aenean dignissim facilisis imperdiet.</p>
        </div>
    </div>
</div>


<div class="w-full -mt-6 pt-32 pb-24 px-12 text-center bg-black text-white">
    <h2 class="text-4xl mb-6">Subscribe to join us</h2>
    <p class="font-sans text-sm mx-auto max-w-xl leading-loose mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
    <p class="font-sans text-sm mx-auto max-w-xl leading-loose">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore. </p>

    <form action="" method="POST">

        <div class="pt-10 mb-6 flex justify-center items-end text-left">

            <div class="p-4">
                <input type="text" name="" placeholder="First name" class="font-sans text-sm px-2 h-10 bg-transparent border-b-2" />
            </div>

            <div class="p-4">
                <input type="email" name="" placeholder="Email address" class="font-sans text-sm px-2 h-10 bg-transparent border-b-2" />
            </div>

        </div>

        <button class="mx-auto bg-white text-black px-6 py-3 flex items-center justify-center text-sm hover:bg-gray-400">Subscribe</button>

    </form>

</div>


<div class="py-24 px-12">

    <div class="container mx-auto flex flex-wrap">
        <div class="w-full md:w-1/2 md:pr-4 flex flex-wrap mb-12 md:mb-0">
            <div class="p-2 w-1/2">
                <img src="https://images.unsplash.com/photo-1523268755815-fe7c372a0349?w=800" class="w-full h-64 object-cover" />
            </div>
            <div class="p-2 w-1/2">
                <img src="https://images.unsplash.com/photo-1491336477066-31156b5e4f35?w=800" class="w-full h-64 object-cover" />
            </div>
            <div class="p-2 w-full">
                <img src="https://images.unsplash.com/photo-1497382706140-605ee76b3b55?w=800" class="w-full h-64 object-cover" />
            </div>
        </div>
        <div class="w-full md:w-1/2 md:pl-4">
            <h2 class="text-4xl mb-10">Phasellus ante turpis,<br />fermentum non</h2>
            <div class="max-w-lg">
                <p class="mb-6 text-gray-700">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Iaculis vulputate tellus. Etiam vel placerat lorem, eget ornare nibh. Ut in commodo magna. Quisque vitae fermentum quam. Mauris venenatis id enim at porta. Etiam molestie lorem non odio hendrerit.</p>
                <p class="mb-6 text-gray-700">At vulputate ligula consequat. Morbi sollicitudin mollis erat, in tempus nisi. Quisque vehicula vitae sem in ornare. Vivamus id odio ligula.</p>
                <p class="mb-10 text-gray-700">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Iaculis vulputate tellus. Etiam vel placerat lorem, eget ornare nibh. Ut in commodo magna. Quisque vitae fermentum quam. Mauris venenatis id enim at porta. Etiam molestie lorem non odio hendrerit.</p>
                <a href="#" class="inline-block bg-black text-white px-6 py-3 text-sm hover:bg-gray-800">Find out more</a>

            </div>
        </div>
    </div>

</div>

<div class="bg-black text-white text-center text-sm py-12">
    <p>Copyright &copy; 2019 Mobilise</p>
</div>

</div>`;