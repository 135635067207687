export const source = `<div class="flex items-center justify-center">
<div class="m-2 max-w-5xl">
    <div class="flex flex-col justify-around md:flex-row items-center">
        <div class="text-gray-500 font-semibold m-1">Join Our Newsletter</div>
        <div> <input type="text" name="Email Address" placeholder="Email Address"
                class="m-1 bg-gray-300 text-gray-600 h-8" /> <button
                class="bg-blue-600 p-1 rounded-xl text-white hover:bg-white hover:text-blue-600 ease-in duration-300">
                Subscribe </button> </div>
        <div> <a href="#"
                class="w-9 h-9 rounded-full bg-gray-300 hover:bg-blue-600 hover:text-white mx-1 pt-1 inline-block"><i
                    class="fa fa-twitter ml-2 p-1"></i></a> <a href="#"
                class="w-9 h-9 rounded-full bg-gray-300 hover:bg-blue-600 hover:text-white mx-1 pt-1 inline-block"><i
                    class="fa fa-instagram ml-2 p-1"></i></a> <a href="#"
                class="w-9 h-9 rounded-full bg-gray-300 hover:bg-blue-600 hover:text-white mx-1 pt-1 inline-block"><i
                    class="fa fa-facebook ml-2 p-1"></i></a> <a href="#"
                class="w-9 h-9 rounded-full bg-gray-300 hover:bg-blue-600 hover:text-white mx-1 pt-1 inline-block"><i
                    class="fa fa-linkedin ml-2 p-1"></i></a> <a href="#"
                class="w-9 h-9 rounded-full bg-gray-300 hover:bg-blue-600 hover:text-white mx-1 pt-1 inline-block"><i
                    class="fa fa-quora ml-2 p-1"></i></a> </div>
    </div>
    <hr class="mt-2" />
    <div class="flex flex-col items-center justify-around md:flex-row">
        <div class="items-center m-1 justify-items-start w-60">
            <h1 class="flex flex-col text-xl text-blue-600 font-medium"> Product </h1> <a href="#"
                class="flex hover:text-blue-600 text-gray-500">Features</a> <a href="#"
                class="flex hover:text-blue-600 text-gray-500">Integrations</a> <a href="#"
                class="flex hover:text-blue-600 text-gray-500">Shopify Integrations</a> <a href="#"
                class="flex hover:text-blue-600 text-gray-500">Pricing</a> <a href="#"
                class="flex hover:text-blue-600 text-gray-500">Chrome Extensions</a>
        </div>
        <div class="items-center m-1 justify-start w-60">
            <h1 class="flex flex-col text-xl text-blue-600 font-medium"> Company </h1> <a href="#"
                class="flex hover:text-blue-600 text-gray-500">Terms</a> <a href="#"
                class="flex hover:text-blue-600 text-gray-500">Customers</a> <a href="#"
                class="flex hover:text-blue-600 text-gray-500">Blog</a> <a href="#"
                class="flex hover:text-blue-600 text-gray-500">Terms of Services</a> <a href="#"
                class="flex hover:text-blue-600 text-gray-500">Privacy Policy</a>
        </div>
        <div class="items-center m-1 w-60">
            <h1 class="flex flex-col text-xl text-blue-600 font-medium"> Support </h1> <a href="#"
                class="flex hover:text-blue-600 text-gray-500">Community</a> <a href="#"
                class="flex hover:text-blue-600 text-gray-500">Support Customers</a> <a href="#"
                class="flex hover:text-blue-600 text-gray-500">Documentations</a> <a href="#"
                class="flex hover:text-blue-600 text-gray-500">Service Providers</a> <a href="#"
                class="flex hover:text-blue-600 text-gray-500">Email Contact</a>
        </div>
        <div class="items-center m-1 w-60 md:-mt-16">
            <h1 class="flex flex-col text-xl text-blue-600 font-medium"> Contact Us </h1> <a href="#"
                class="flex hover:text-blue-600 text-gray-500">suppport@admin.com</a> <a href="#"
                class="flex hover:text-blue-600 text-gray-500">(+251)987-6543-210</a>
        </div>
    </div>
    <div class="m-2 ml-16 mr-28 flex flex-col justify-between p-2 md:flex-row"> <img class="flex m-1"
            src="https://dummyimage.com/150x50" />
        <div class="flex"> <a href="#" class="m-1 hover:text-blue-600 text-gray-500">DMCA</a>
            <div class="m-1 text-gray-500">|</div> <a href="#"
                class="m-1 hover:text-blue-600 text-gray-500">Security Statement </a>
        </div>
    </div>
</div>
</div>`;
