export const source = `
<!-- Header Section --> 
<header data-header="header" class="bg-gray-900 text-white relative z-10">
<div class="relative z-10 px-6 py-4 flex justify-between items-center">
  <div class="flex items-center">
     <div class="w-10 h-10 rounded-full bg-gradient-to-r from-pink-500 to-cyan-500 mr-3">
  </div>
  <span class="text-2xl font-bold" data-header-logo="logo">MetaFlow3D...</span>
  </div>
  <nav data-header-menuitems="menuItems" class="flex gap-6 items-center">
  <a href="#" data-header-menuitems-item="item" class="text-gray-400 hover:text-white">Всі шаблони</a>
  <a href="#" data-header-menuitems-item="item" class="text-gray-400 hover:text-white">Ціни</a>
  <a href="#" data-header-menuitems-item="item" class="text-gray-400 hover:text-white">Зв'язатися з нами</a>
  <a href="#" data-header-cta="cta" class="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700">Спробувати зараз</a>
  </nav>
</div>
</header>
<script>
console.log('Good');
</script>
`; 