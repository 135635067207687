export const source = ` 
<section class="py-20" data-section="pricing-simple">
<div class="container mx-auto px-4">
  <h2 class="text-3xl font-bold text-center mb-12">Оберіть свій план</h2>
  <div class="flex flex-wrap justify-center">
    <div class="w-full md:w-1/3 px-4 mb-8">
      <div class="bg-white rounded-lg shadow-lg p-8">
        <h3 class="text-2xl font-bold text-center mb-4">Базовий</h3>
        <p class="text-4xl text-center mb-6">$29<span class="text-gray-500 text-base">/міс</span></p>
        <ul class="mb-6">
          <li class="mb-2 flex items-center"><svg class="w-4 h-4 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg> Функція 1</li>
          <li class="mb-2 flex items-center"><svg class="w-4 h-4 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg> Функція 2</li>
          <li class="mb-2 flex items-center"><svg class="w-4 h-4 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg> Функція 3</li>
        </ul>
        <button class="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition duration-300">Обрати план</button>
      </div>
    </div>
    <!-- Додайте ще 2 подібних блоки для інших планів -->
  </div>
</div>
</section>
`;
