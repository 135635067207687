export const source =`
<h1 class="px-12 py-24 text-center font-serif text-4xl">Horizontal Masonry Layout</h1>

<div class="px-12 flex flex-wrap gap-4">
  <img class="w-auto h-72 flex-grow object-cover" src="https://images.unsplash.com/photo-1457129962825-adcaea7406c3?auto=format&fit=crop&w=800&q=80" />
  <img class="w-auto h-72 flex-grow object-cover" src="https://images.unsplash.com/photo-1433838552652-f9a46b332c40?auto=format&fit=crop&w=800&q=80" />
  <img class="w-auto h-72 flex-grow object-cover" src="https://images.unsplash.com/photo-1532274402911-5a369e4c4bb5?auto=format&fit=crop&w=800&q=80" />
  <img class="w-auto h-72 flex-grow object-cover" src="https://images.unsplash.com/photo-1500817487388-039e623edc21?auto=format&fit=crop&w=800&q=80" />
  <img class="w-auto h-72 flex-grow object-cover" src="https://images.unsplash.com/photo-1413752362258-7af2a667b590?auto=format&fit=crop&w=800&q=80" />
  <img class="w-auto h-72 flex-grow object-cover" src="https://images.unsplash.com/photo-1506744038136-46273834b3fb?auto=format&fit=crop&w=800&q=80" />
  <img class="w-auto h-72 flex-grow object-cover" src="https://images.unsplash.com/photo-1559827291-72ee739d0d9a?auto=format&fit=crop&w=800&q=80" />
</div>`;