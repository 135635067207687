export const source = `    <!-- ====== Table Section Start -->
<section class="bg-white py-20 lg:py-[120px] dark:bg-dark">
  <div class="container mx-auto">
    <div class="-mx-4 flex flex-wrap">
      <div class="w-full px-4">
        <div class="max-w-full overflow-x-auto">
          <table class="w-full table-auto">
            <thead>
              <tr class="bg-primary text-center">
                <th
                  class="w-1/6 min-w-[160px] border-l border-transparent px-3 py-4 text-lg font-medium text-white lg:px-4 lg:py-7"
                >
                  TLD
                </th>
                <th
                  class="w-1/6 min-w-[160px] px-3 py-4 text-lg font-medium text-white lg:px-4 lg:py-7"
                >
                  Duration
                </th>
                <th
                  class="w-1/6 min-w-[160px] px-3 py-4 text-lg font-medium text-white lg:px-4 lg:py-7"
                >
                  Registration
                </th>
                <th
                  class="w-1/6 min-w-[160px] px-3 py-4 text-lg font-medium text-white lg:px-4 lg:py-7"
                >
                  Renewal
                </th>
                <th
                  class="w-1/6 min-w-[160px] px-3 py-4 text-lg font-medium text-white lg:px-4 lg:py-7"
                >
                  Transfer
                </th>
                <th
                  class="w-1/6 min-w-[160px] border-r border-transparent px-3 py-4 text-lg font-medium text-white lg:px-4 lg:py-7"
                >
                  Register
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  class="border-b border-l border-[#E8E8E8] bg-[#F3F6FF] px-2 py-5 text-center text-base font-medium text-dark dark:border-dark dark:bg-dark-3 dark:text-dark-7"
                >
                  .com
                </td>
                <td
                  class="border-b border-[#E8E8E8] bg-white px-2 py-5 text-center text-base font-medium text-dark dark:border-dark dark:bg-dark-2 dark:text-dark-7"
                >
                  1 Year
                </td>
                <td
                  class="border-b border-[#E8E8E8] bg-[#F3F6FF] px-2 py-5 text-center text-base font-medium text-dark dark:border-dark dark:bg-dark-3 dark:text-dark-7"
                >
                  $75.00
                </td>
                <td
                  class="border-b border-[#E8E8E8] bg-white px-2 py-5 text-center text-base font-medium text-dark dark:border-dark dark:bg-dark-2 dark:text-dark-7"
                >
                  $5.00
                </td>
                <td
                  class="border-b border-[#E8E8E8] bg-[#F3F6FF] px-2 py-5 text-center text-base font-medium text-dark dark:border-dark dark:bg-dark-3 dark:text-dark-7"
                >
                  $10.00
                </td>
                <td
                  class="border-b border-r border-[#E8E8E8] bg-white px-2 py-5 text-center text-base font-medium text-dark dark:border-dark dark:bg-dark-2 dark:text-dark-7"
                >
                  <a
                    href="javascript:void(0)"
                    class="inline-block rounded-md border border-primary px-6 py-2.5 font-medium text-primary hover:bg-primary hover:text-white"
                  >
                    Sign Up
                  </a>
                </td>
              </tr>
              <tr>
                <td
                  class="border-b border-l border-[#E8E8E8] bg-[#F3F6FF] px-2 py-5 text-center text-base font-medium text-dark dark:border-dark dark:bg-dark-3 dark:text-dark-7"
                >
                  .com
                </td>
                <td
                  class="border-b border-[#E8E8E8] bg-white px-2 py-5 text-center text-base font-medium text-dark dark:border-dark dark:bg-dark-2 dark:text-dark-7"
                >
                  1 Year
                </td>
                <td
                  class="border-b border-[#E8E8E8] bg-[#F3F6FF] px-2 py-5 text-center text-base font-medium text-dark dark:border-dark dark:bg-dark-3 dark:text-dark-7"
                >
                  $75.00
                </td>
                <td
                  class="border-b border-[#E8E8E8] bg-white px-2 py-5 text-center text-base font-medium text-dark dark:border-dark dark:bg-dark-2 dark:text-dark-7"
                >
                  $5.00
                </td>
                <td
                  class="border-b border-[#E8E8E8] bg-[#F3F6FF] px-2 py-5 text-center text-base font-medium text-dark dark:border-dark dark:bg-dark-3 dark:text-dark-7"
                >
                  $10.00
                </td>
                <td
                  class="border-b border-r border-[#E8E8E8] bg-white px-2 py-5 text-center text-base font-medium text-dark dark:border-dark dark:bg-dark-2 dark:text-dark-7"
                >
                  <a
                    href="javascript:void(0)"
                    class="inline-block rounded-md border border-primary px-6 py-2.5 font-medium text-primary hover:bg-primary hover:text-white"
                  >
                    Sign Up
                  </a>
                </td>
              </tr>
              <tr>
                <td
                  class="border-b border-l border-[#E8E8E8] bg-[#F3F6FF] px-2 py-5 text-center text-base font-medium text-dark dark:border-dark dark:bg-dark-3 dark:text-dark-7"
                >
                  .com
                </td>
                <td
                  class="border-b border-[#E8E8E8] bg-white px-2 py-5 text-center text-base font-medium text-dark dark:border-dark dark:bg-dark-2 dark:text-dark-7"
                >
                  1 Year
                </td>
                <td
                  class="border-b border-[#E8E8E8] bg-[#F3F6FF] px-2 py-5 text-center text-base font-medium text-dark dark:border-dark dark:bg-dark-3 dark:text-dark-7"
                >
                  $75.00
                </td>
                <td
                  class="border-b border-[#E8E8E8] bg-white px-2 py-5 text-center text-base font-medium text-dark dark:border-dark dark:bg-dark-2 dark:text-dark-7"
                >
                  $5.00
                </td>
                <td
                  class="border-b border-[#E8E8E8] bg-[#F3F6FF] px-2 py-5 text-center text-base font-medium text-dark dark:border-dark dark:bg-dark-3 dark:text-dark-7"
                >
                  $10.00
                </td>
                <td
                  class="border-b border-r border-[#E8E8E8] bg-white px-2 py-5 text-center text-base font-medium text-dark dark:border-dark dark:bg-dark-2 dark:text-dark-7"
                >
                  <a
                    href="javascript:void(0)"
                    class="inline-block rounded-md border border-primary px-6 py-2.5 font-medium text-primary hover:bg-primary hover:text-white"
                  >
                    Sign Up
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- ====== Table Section End -->`;
