export const source = ` 
<section class="py-20" data-section="features-alternating">
<div class="container mx-auto px-4">
  <div class="flex flex-wrap items-center mb-20">
    <div class="w-full md:w-1/2 mb-10 md:mb-0">
      <img src="feature1.jpg" alt="Feature 1" class="rounded-lg shadow-lg">
    </div>
    <div class="w-full md:w-1/2 md:pl-10">
      <h3 class="text-2xl font-bold mb-4">Інтуїтивний інтерфейс</h3>
      <p class="text-gray-600 mb-6">Наш користувацький інтерфейс розроблений для максимальної продуктивності та легкості використання.</p>
      <a href="#" class="text-blue-600 hover:underline">Дізнатися більше</a>
    </div>
  </div>
  <!-- Повторіть з протилежним розташуванням для наступної особливості -->
</div>
</section>
`;
