import { useEffect, useState } from "react";
import axios from "axios";

const GameManagement = ({ projectId, setMessage }) => {
  const [gameSettings, setGameSettings] = useState({
    game_short_name: "",
    game_url: "",
    destination_type: "channel",
    destination: "",
  });
  const [gameScore, setGameScore] = useState({
    user_id: "",
    score: 0,
    force: false,
    disable_edit_message: false,
    chat_id: "",
    message_id: "",
  });
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleGameSettingsChange = (e) => {
    const { name, value, type, checked } = e.target;
    setGameSettings((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleGameScoreChange = (e) => {
    const { name, value, type, checked } = e.target;
    setGameScore((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const sendGame = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${apiUrl}/telegram/send-game`,
        {
          ...gameSettings,
          project_id: projectId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setMessage("Game sent successfully to Telegram");
    } catch (error) {
      console.error("Error sending game:", error);
      setMessage("Error sending game to Telegram");
    }
  };

  const setScore = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${apiUrl}/telegram/set-game-score`,
        {
          ...gameScore,
          project_id: projectId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setMessage("Game score set successfully");
    } catch (error) {
      console.error("Error setting game score:", error);
      setMessage("Error setting game score");
    }
  };

  useEffect(() => {
    fetchGameInfo();
  }, [projectId]);

  const fetchGameInfo = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${apiUrl}/telegram/webapp/info?project_id=${projectId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setGameSettings({
        game_short_name: response.data.game_short_name || "",
        game_url: response.data.game_url || "",
        destination_type: "channel",
        destination: "",
      });
    } catch (error) {
      console.error("Error fetching game info:", error);
      setMessage("Error fetching game info");
    }
  };

  return (
    <div className="mb-8">
      <h2 className="text-xl font-semibold mb-4">Game Management</h2>

      <form onSubmit={sendGame} className="mb-6">
        <h3 className="text-lg font-semibold mb-2">Send Game</h3>
        <div className="mb-4">
          <label className="block mb-2">Game Short Name:</label>
          <input
            type="text"
            name="game_short_name"
            value={gameSettings.game_short_name}
            onChange={handleGameSettingsChange}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Game URL:</label>
          <input type="url" name="game_url" value={gameSettings.game_url} onChange={handleGameSettingsChange} className="w-full p-2 border rounded" required />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Destination Type:</label>
          <select name="destination_type" value={gameSettings.destination_type} onChange={handleGameSettingsChange} className="w-full p-2 border rounded">
            <option value="channel">Channel</option>
            <option value="group">Group</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block mb-2">Destination:</label>
          <input
            type="text"
            name="destination"
            value={gameSettings.destination}
            onChange={handleGameSettingsChange}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded">
          Send Game
        </button>
      </form>

      <form onSubmit={setScore}>
        <h3 className="text-lg font-semibold mb-2">Set Game Score</h3>
        <div className="mb-4">
          <label className="block mb-2">User ID:</label>
          <input type="text" name="user_id" value={gameScore.user_id} onChange={handleGameScoreChange} className="w-full p-2 border rounded" required />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Score:</label>
          <input type="number" name="score" value={gameScore.score} onChange={handleGameScoreChange} className="w-full p-2 border rounded" required />
        </div>
        <div className="mb-4">
          <label className="flex items-center">
            <input type="checkbox" name="force" checked={gameScore.force} onChange={handleGameScoreChange} className="mr-2" />
            Force
          </label>
        </div>
        <div className="mb-4">
          <label className="flex items-center">
            <input type="checkbox" name="disable_edit_message" checked={gameScore.disable_edit_message} onChange={handleGameScoreChange} className="mr-2" />
            Disable Edit Message
          </label>
        </div>
        <div className="mb-4">
          <label className="block mb-2">Chat ID:</label>
          <input type="text" name="chat_id" value={gameScore.chat_id} onChange={handleGameScoreChange} className="w-full p-2 border rounded" required />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Message ID:</label>
          <input type="text" name="message_id" value={gameScore.message_id} onChange={handleGameScoreChange} className="w-full p-2 border rounded" required />
        </div>
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
          Set Game Score
        </button>
      </form>
    </div>
  );
};

export default GameManagement;
