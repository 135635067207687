export const source = `<div class="flex justify-center bg-blue-900">
<div class="self-center top-0 w-full max-w-7xl ">
    <div class="flex justify-between items-center border-b border-white">
        <div class="flex mx-2"> <a href="#" class="flex">
                <div class="mx-2 my-4 text-2xl text-white hover:border-b border-white hover:font-bold font-medium">
                    Explore</div>
            </a> </div>
        <ul class="hidden md:flex items-center text-[18px] font-semibold pr-10">
            <li class="text-white hover:border-b border-white hover:font-bold font-medium mx-4 my-1"><a
                    href="#">Home</a></li>
            <li class="text-white hover:border-b border-white hover:font-bold font-medium mx-4 my-1"><a
                    href="#">About</a></li>
            <li class="text-white hover:border-b border-white hover:font-bold font-medium mx-4 my-1"><a
                    href="#">Tour</a></li>
            <li class="text-white hover:border-b border-white hover:font-bold font-medium mx-4 my-1"><a
                    href="#">Hotels</a></li>
            <li class="text-white hover:border-b border-white hover:font-bold font-medium mx-4 my-1"><a
                    href="#">Blog</a></li>
            <li class="text-white hover:border-b border-white hover:font-bold font-medium mx-4 my-1"><a
                    href="#">Contact</a></li>
            <li class="text-white hover:border-b border-white hover:font-bold font-medium mx-4 my-1"><a href="#">
                    <ion-icon name="search" class="text-2xl mt-2"></ion-icon>
                </a></li>
        </ul> <button class="block p-3 mx-10 md:hidden hover:bg-gray-200 rounded-xl group">
            <div class="w-5 my-[3px] h-[3px] bg-gray-600 mb-[2px]"></div>
            <div class="w-5 my-[3px] h-[3px] bg-gray-600 mb-[2px]"></div>
            <div class="w-5 my-[3px] h-[3px] bg-gray-600"></div>
            <div
                class="absolute top-0 -right-full opacity-0 w-[60%] border bg-white group-focus:right-0 group-focus:opacity-100 transition-all ease-in duration-300 ">
                <ul class="flex flex-col items-center text-[18px] pt-[60px]">
                    <li class="text-stone-600 hover:text-blue-600 hover:font-bold font-medium mx-4 my-1"> <a
                            href="#">Home</a> </li>
                    <li class="text-stone-600 hover:text-blue-600 hover:font-bold font-medium mx-4 my-1"> <a
                            href="#">About</a> </li>
                    <li class="text-stone-600 hover:text-blue-600 hover:font-bold font-medium mx-4 my-1"> <a
                            href="#">Tour</a> </li>
                    <li class="text-stone-600 hover:text-blue-600 hover:font-bold font-medium mx-4 my-1"> <a
                            href="#">Hotels</a> </li>
                    <li class="text-stone-600 hover:text-blue-600 hover:font-bold font-medium mx-4 my-1"> <a
                            href="#">Blog</a> </li>
                    <li class="text-stone-600 hover:text-blue-600 hover:font-bold font-medium mx-4 my-1"> <a
                            href="#">Contact</a> </li>
                </ul>
            </div>
        </button>
    </div>
</div>
</div>`;
