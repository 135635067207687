export const source = `
<!-- Footer Section -->
<footer class="bg-gray-900 py-12" data-section="footer">
    <div class="container mx-auto px-4">
        <div class="flex flex-wrap justify-between items-center">
            <div class="w-full lg:w-1/2 mb-8 lg:mb-0" data-element="footer-description">
                <!-- Footer description here -->
            </div>
            <div class="w-full lg:w-1/3" data-element="footer-subscribe">
                <!-- Subscribe form here -->
            </div>
        </div>
        <div class="flex flex-wrap justify-between items-center mt-8 pt-8 border-t border-gray-800">
            <div class="w-full md:w-auto mb-4 md:mb-0" data-element="footer-links">
                <!-- Footer links here -->
            </div>
            <div class="w-full md:w-auto flex justify-center md:justify-end space-x-4" data-element="footer-social">
                <!-- Social media icons here -->
            </div>
        </div>
        <div class="mt-8 text-center text-gray-400 text-sm" data-element="footer-copyright">
            © 2024 AI Platform. All rights reserved.
        </div>
    </div>
</footer>
`;
