export const source = `
<!-- Workflows Section -->
<section class="bg-gray-900 py-16 relative overflow-hidden" data-section="workflows">
    <div class="container mx-auto px-4 relative z-10">
        <div class="flex items-center mb-8">
            <span class="text-blue-500 text-sm font-semibold mr-2 px-2.5 py-0.5 rounded border border-blue-500">CATALOGUE</span>
            <h2 class="text-4xl font-bold text-white">Our <span class="text-blue-500">Workflows</span></h2>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6" data-element="workflow-cards">
            <!-- Workflow cards here -->
        </div>
    </div>
</section>
`;
