export const source = ` 
<section class="py-20" data-section="pricing-comparison">
<div class="container mx-auto px-4">
  <h2 class="text-3xl font-bold text-center mb-12">Порівняйте наші плани</h2>
  <div class="overflow-x-auto">
    <table class="w-full bg-white shadow-lg rounded-lg overflow-hidden">
      <thead class="bg-gray-800 text-white">
        <tr>
          <th class="py-3 px-4 text-left">Функції</th>
          <th class="py-3 px-4 text-center">Базовий</th>
          <th class="py-3 px-4 text-center">Про</th>
          <th class="py-3 px-4 text-center">Ентерпрайз</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="py-3 px-4 border-b">3D моделювання</td>
          <td class="py-3 px-4 border-b text-center">✓</td>
          <td class="py-3 px-4 border-b text-center">✓</td>
          <td class="py-3 px-4 border-b text-center">✓</td>
        </tr>
        <!-- Додайте більше рядків для порівняння функцій -->
      </tbody>
      <tfoot class="bg-gray-100">
        <tr>
          <td class="py-3 px-4 font-bold">Ціна</td>
          <td class="py-3 px-4 text-center">$29/міс</td>
          <td class="py-3 px-4 text-center">$79/міс</td>
          <td class="py-3 px-4 text-center">Зв'яжіться з нами</td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
</section>
`;
