export const source = `<div class="flex items-center justify-center text-center">
<div
  class="flex flex-col items-center justify-center rounded-tr-full rounded-bl-full w-full bg-white"
>
  <div class="flex flex-col p-2 m-2 max-w-7xl">
    <div class="text-xl md:text-3xl font-medium">Our Team</div>

    <div
      class="flex items-center justify-center lg:flex-row flex-col space-x-4 space-y-4 p-2"
    >
      <div
        class="flex-col px-6 py-2 w-[90%] md:w-[50%] lg:w-1/4"
      >
        <div
          class="h-40 w-40 border-2 border-yellow-400 rounded-full overflow-hidden bg-gray-200"
        >
          <img
            src="https://source.unsplash.com/300x300/?girl"
            alt=""
            class=""
          />
        </div>

        <div
          class="text-md text-white cursor-pointer w-[50%] bg-yellow-400 lg:rotate-90 mr-40 mt-8 lg:mb-16 pr-4 pl-1 py-1"
        >
          Designer
        </div>
        <div
          class="text-lg font-medium text-stone-600 cursor-pointer hover:text-stone-400"
        >
          Elina Gilbert
        </div>
        <div class="text-left m-2">
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Exercitationem deserunt, dolorum illo consequatur, natus aut,
          esse dignissimos rem facilis ipsa numquam
        </div>
      </div>
      <div
        class="flex-col px-6 py-2 w-[90%] md:w-[50%] lg:w-1/4"
      >
        <div
          class="h-40 w-40 border-2 border-green-400 rounded-full overflow-hidden bg-gray-200"
        >
          <img
            src="https://source.unsplash.com/300x300/?boy"
            alt=""
            class=""
          />
        </div>

        <div
          class="text-md text-white cursor-pointer w-[50%] bg-green-400 lg:rotate-90 mr-40 mt-8 lg:mb-16 pr-4 pl-1 py-1"
        >
          Front-end
        </div>
        <div
          class="text-lg font-medium text-stone-600 cursor-pointer hover:text-stone-400"
        >
          John Doe
        </div>
        <div class="text-left m-2">
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Exercitationem deserunt, dolorum illo consequatur, natus aut,
          esse dignissimos rem facilis ipsa numquam
        </div>
      </div>
      <div
        class="flex-col px-6 py-2 w-[90%] md:w-[50%] lg:w-1/4"
      >
        <div
          class="h-40 w-40 border-2 border-cyan-400 rounded-full overflow-hidden bg-gray-200"
        >
          <img
            src="https://source.unsplash.com/300x300/?boy,man"
            alt=""
            class=""
          />
        </div>

        <div
          class="text-md text-white cursor-pointer w-[50%] bg-cyan-400 lg:rotate-90 mr-40 mt-8 lg:mb-16 pr-4 pl-1 py-1"
        >
          CEO
        </div>
        <div
          class="text-lg font-medium text-stone-600 cursor-pointer hover:text-stone-400"
        >
          Arkay
        </div>
        <div class="text-left m-2">
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Exercitationem deserunt, dolorum illo consequatur, natus aut,
          esse dignissimos rem facilis ipsa numquam
        </div>
      </div>
      <div
        class="flex-col px-6 py-2 w-[90%] md:w-[50%] lg:w-1/4"
      >
        <div
          class="h-40 w-40 border-2 border-fuchsia-400 rounded-full overflow-hidden bg-gray-200"
        >
          <img
            src="https://source.unsplash.com/300x300/?girl"
            alt=""
            class=""
          />
        </div>

        <div
          class="text-md text-white cursor-pointer w-[50%] bg-fuchsia-400 lg:rotate-90 mr-40 mt-8 lg:mb-16 pr-4 pl-1 py-1"
        >
          Deployer
        </div>
        <div
          class="text-lg font-medium text-stone-600 cursor-pointer hover:text-stone-400"
        >
          Ashley Adams
        </div>
        <div class="text-left m-2">
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Exercitationem deserunt, dolorum illo consequatur, natus aut,
          esse dignissimos rem facilis ipsa numquam
        </div>
      </div>
    </div>
  </div>
</div>
</div>`;
