export const source = `
<section class="bg-white py-20 relative overflow-hidden">
<div class="absolute inset-0 bg-gray-100 transform -skew-y-6"></div>
<div class="container mx-auto px-4 relative z-10">
  <h2 class="text-4xl font-bold mb-12 text-center text-gray-800">How It Works</h2>
  <div class="flex flex-col md:flex-row justify-between items-center space-y-8 md:space-y-0 md:space-x-8">
    <div class="bg-white p-6 rounded-lg shadow-lg">
      <h3 class="text-xl font-semibold mb-4">1. Sign Up</h3>
      <p class="text-gray-600">Create your account in just a few clicks.</p>
    </div>
    <div class="bg-white p-6 rounded-lg shadow-lg">
      <h3 class="text-xl font-semibold mb-4">2. Customize</h3>
      <p class="text-gray-600">Tailor the platform to your specific needs.</p>
    </div>
    <div class="bg-white p-6 rounded-lg shadow-lg">
      <h3 class="text-xl font-semibold mb-4">3. Launch</h3>
      <p class="text-gray-600">Start using the platform and boost your productivity.</p>
    </div>
  </div>
</div>
</section>
`;
