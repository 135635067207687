export const source = ` <div class="flex justify-center">
<div class="flex flex-col lg:flex-row items-center max-w-7xl w-[90%] m-4">
    <div style="background-image: url(https://source.unsplash.com/random/500x400/?girl)"
        class="bg-no-repeat bg-cover bg-center lg:bg-left flex flex-col w-[90%] lg:w-[40%] h-96 m-2 brightness-110 hover:brightness-90 ">
        <div class="absolute inset-0 bg-gray-900 bg-opacity-50">
            <div class="flex flex-col items-center justify-center p-2 text-gray-300">
                <div class="font-bold text-lg m-2 mt-[30%]"> <a href="">Everything has beauty, but not everyone sees
                        it.</a></div>
                <div class=" m-2 text-sm"><a href="">There is no one definition of beauty. Beauty standards vary
                        from culture to culture and change over time. In Western cultures, beauty standards have
                        traditionally been based on ideals of youth and femininity. </a></div>
            </div>
        </div>
    </div>
    <div style="background-image: url(https://source.unsplash.com/random/500x400/?forest)"
        class="bg-no-repeat bg-cover bg-center lg:bg-left flex flex-col w-[90%] lg:w-[40%] h-96 m-2 brightness-110 hover:brightness-90 ">
        <div class="absolute inset-0 bg-gray-900 bg-opacity-50">
            <div class="flex flex-col items-center justify-center p-2 text-gray-300">
                <div class="font-bold text-lg m-2 mt-[30%]"> <a href="">There is no Wi-Fi in the forest, but I
                        promise you will find a better connection</a></div>
                <div class=" m-2 text-sm"><a href="">There is something about mountains that is both humbling and
                        invigorating. Their very presence seems to demand respect and even a certain amount of
                        reverence. </a></div>
            </div>
        </div>
    </div>
    <div style="background-image: url(https://source.unsplash.com/random/500x400/?galaxy)"
        class="bg-no-repeat bg-cover bg-center lg:bg-left flex flex-col w-[90%] lg:w-[40%] h-96 m-2 brightness-110 hover:brightness-90 ">
        <div class="absolute inset-0 bg-gray-900 bg-opacity-50">
            <div class="flex flex-col items-center justify-center p-2 text-gray-300">
                <div class="font-bold text-lg m-2 mt-[30%]"> <a href="">The twinkle in your eyes is light the
                        brightest stars on the clearest night.</a></div>
                <div class=" m-2 text-sm"><a href="">There is something magical about the universe. It is so vast
                        and mysterious, and it is always expanding. Every day, we are learning more about it and
                        discovering new things.</a></div>
            </div>
        </div>
    </div>
</div>
</div>`;
