export const source = `  <!-- ====== Gallery Section Start -->
<section class="bg-white py-20 dark:bg-dark">
  <div class="container">
    <div class="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
      <div class="overflow-hidden rounded-xl">
        <img
          src="../images/galleries/gallery-02/image-1.jpg"
          alt="gallery image"
          class="w-full object-cover object-center"
        />
      </div>
      <div class="overflow-hidden rounded-xl">
        <img
          src="../images/galleries/gallery-02/image-2.jpg"
          alt="gallery image"
          class="w-full object-cover object-center"
        />
      </div>
      <div class="overflow-hidden rounded-xl">
        <img
          src="../images/galleries/gallery-02/image-3.jpg"
          alt="gallery image"
          class="w-full object-cover object-center"
        />
      </div>
      <div class="overflow-hidden rounded-xl">
        <img
          src="../images/galleries/gallery-02/image-4.jpg"
          alt="gallery image"
          class="w-full object-cover object-center"
        />
      </div>
      <div class="overflow-hidden rounded-xl">
        <img
          src="../images/galleries/gallery-02/image-5.jpg"
          alt="gallery image"
          class="w-full object-cover object-center"
        />
      </div>
      <div class="overflow-hidden rounded-xl">
        <img
          src="../images/galleries/gallery-02/image-6.jpg"
          alt="gallery image"
          class="w-full object-cover object-center"
        />
      </div>
      <div class="overflow-hidden rounded-xl">
        <img
          src="../images/galleries/gallery-02/image-7.jpg"
          alt="gallery image"
          class="w-full object-cover object-center"
        />
      </div>
      <div class="overflow-hidden rounded-xl">
        <img
          src="../images/galleries/gallery-02/image-8.jpg"
          alt="gallery image"
          class="w-full object-cover object-center"
        />
      </div>
      <div class="overflow-hidden rounded-xl">
        <img
          src="../images/galleries/gallery-02/image-9.jpg"
          alt="gallery image"
          class="w-full object-cover object-center"
        />
      </div>
    </div>
  </div>
</section>
<!-- ====== Gallery Section End -->`;