export const source = `
<section class="relative h-screen overflow-hidden" data-section="hero-parallax">
<div class="absolute inset-0 z-0">
  <img src="background.jpg" alt="Background" class="w-full h-full object-cover transform scale-110" style="transform: translateY(-5%);">
</div>
<div class="relative z-10 flex items-center justify-center h-full">
  <div class="text-center text-white">
    <h1 class="text-5xl font-bold mb-4">Занурення в світ 3D з MetaFlow3D</h1>
    <p class="text-xl mb-8">Створюйте, візуалізуйте, вражайте</p>
    <a href="#" class="bg-transparent border-2 border-white text-white px-8 py-3 rounded-full hover:bg-white hover:text-gray-900 transition duration-300">Почати подорож</a>
  </div>
</div>
</section>
`;
