export const source = ` <div class="flex justify-center  p-4">
<div class="flex flex-col justify-center items-center ">
    <div class="text-stone-600  font-medium">Testimonials</div>
    <div class="text-green-500 text-3xl md:text-5xl font-medium">What Our Clients Say</div>
    <div class="text-stone-600 my-4 md:font-medium text-center w-3/4 md:w-1/2">We place huge value on our
        relationships and have seen the benefit they bring to our business. Customer feedback is vital in
        helping Tailblocks.</div>
    <div class="flex flex-col md:flex-row max-w-7xl justify-center items-center ">

        <div class="overflow-hidden w-full m-4 flex justify-center rounded-lg  md:w-[33%] px-8">

            <div class="flex flex-col md:flex-row items-center justify-center  ">
                <div class="  items-center justify-center flex py-2">
                    <div class="flex flex-col  items-center justify-center text-center">
                        <img src="https://source.unsplash.com/100x100/?man,boy" alt="" class="rounded-full" />
                        <div class="text-stone-500  m-2"> Tailblocks provides best Tailwind CSS Components and
                            Blocks to create an unique websites within minutes. It has upto 60+ free components
                            for front-end Web Development.</div>
                        <div class="font-bold text-green-500">John Doe</div>
                        <div class="text-sm font-medium text-stone-500 hover:text-green-500"><a href="#">Board
                                Director of Tailblocks</a></div>
                    </div>
                </div>

            </div>
        </div>

        <div class="overflow-hidden w-full m-4 flex justify-center rounded-lg  md:w-[33%] px-8">

            <div class="flex flex-col md:flex-row items-center justify-center  ">
                <div class="  items-center justify-center flex py-2">
                    <div class="flex flex-col  items-center justify-center text-center">
                        <img src="https://source.unsplash.com/100x100/?girl" alt="" class="rounded-full" />
                        <div class="text-stone-500  m-2"> Tailblocks provides best Tailwind CSS Components and
                            Blocks to create an unique websites within minutes. It has upto 60+ free components
                            for front-end Web Development.</div>
                        <div class="font-bold text-green-500">Emily Watson</div>
                        <div class="text-sm font-medium text-stone-500 hover:text-green-500"><a
                                href="#">Secretary</a></div>
                    </div>
                </div>

            </div>
        </div>

        <div class="overflow-hidden w-full m-4 flex justify-center rounded-lg  md:w-[33%] px-8">

            <div class="flex flex-col md:flex-row items-center justify-center  ">
                <div class="  items-center justify-center flex py-2">
                    <div class="flex flex-col  items-center justify-center text-center">
                        <img src="https://source.unsplash.com/100x100/?boy" alt="" class="rounded-full" />
                        <div class="text-stone-500  m-2"> Tailblocks provides best Tailwind CSS Components and
                            Blocks to create an unique websites within minutes. It has upto 60+ free components
                            for front-end Web Development.</div>
                        <div class="font-bold text-green-500">Paul Wesley</div>
                        <div class="text-sm font-medium text-stone-500 hover:text-green-500"><a href="#">Sales
                                Manager, Tailblocks</a></div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>
</div>`;
