export const source = `  <div class="flex justify-center ">
<div class="flex flex-col justify-center  my-10 w-[90%] space-y-28 lg:space-y-24 max-w-7xl">
    <div class="flex flex-col justify-center items-center ">
        <div class="text-xl md:text-3xl text-orange-500 font-bold uppercase">testimonials</div>
        <div class="border-b-4 border-orange-500 -mt-2"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</div>
        <div class="text-black text-sm text-center mt-5">Subscribe Easy Tutorial YouTube channel to watch more
            videos
        </div>
    </div>
    <div class="flex flex-col justify-center items-center lg:flex-row space-y-28 lg:space-y-0  lg:space-x-10">
        <div
            class="bg-slate-200 shadow-lg flex flex-col justify-center items-center py-4 h-56 md:w-[80%] lg:w-fit">
            <div class="-mt-20 ">
                <img src="https://source.unsplash.com/150x150/?man" class="rounded-full " alt="">
            </div>
            <p class="text-center text-sm p-5">Tailblockss provides best Tailwind CSS Components and Blocks to
                create an unique websites within minutes.</p>
            <div class="flex flex-col lg:flex-row justify-center text-center lg:space-x-3">
                <h1 class="text-orange-500 text-lg font-semibold">Antonio Starc</h1>
                <p class="text-black text-sm lg:mt-[5px]">Co-Founder at xyz</p>
            </div>
        </div>
        <div
            class="bg-slate-200 shadow-lg flex flex-col justify-center items-center py-4 h-56 md:w-[80%] lg:w-fit">
            <div class="-mt-20 ">
                <img src="https://source.unsplash.com/150x150/?woman" class="rounded-full " alt="">
            </div>
            <p class="text-center text-sm p-5">Tailblockss provides best Tailwind CSS Components and Blocks to
                create an unique websites within minutes.</p>
            <div class="flex flex-col lg:flex-row justify-center text-center lg:space-x-3">
                <h1 class="text-orange-500 text-lg font-semibold">Maria Starc</h1>
                <p class="text-black text-sm lg:mt-[5px]">Co-Founder at xyz</p>
            </div>
        </div>
        <div
            class="bg-slate-200 shadow-lg flex flex-col justify-center items-center py-4 h-56 md:w-[80%] lg:w-fit">
            <div class="-mt-20 ">
                <img src="https://source.unsplash.com/150x150/?girl" class="rounded-full " alt="">
            </div>
            <p class="text-center text-sm p-5">Tailblockss provides best Tailwind CSS Components and Blocks to
                create an unique websites within minutes.</p>
            <div class="flex flex-col lg:flex-row justify-center text-center lg:space-x-3">
                <h1 class="text-orange-500 text-lg font-semibold">Jessica Starc</h1>
                <p class="text-black text-sm lg:mt-[5px]">Co-Founder at xyz</p>
            </div>
        </div>

    </div>
</div>
</div>`;
