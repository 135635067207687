export const source = `
<!-- FAQ Section -->
    <section class="bg-gray-900 py-20 relative overflow-hidden" data-section="faq">
        <div class="container mx-auto px-4 relative z-10">
            <div class="flex items-center mb-8">
                <span class="text-purple-500 text-sm font-semibold mr-2 px-2.5 py-0.5 rounded border border-purple-500">FAQ</span>
                <h2 class="text-4xl font-bold text-white"><span class="text-purple-500">Frequently</span> Asked</h2>
            </div>
            <div class="space-y-4" data-element="faq-items">
                <!-- FAQ items here -->
            </div>
        </div>
    </section>
`;
