export const source = `
<!-- Testimonials Section -->
    <section class="bg-gray-900 py-20 relative overflow-hidden" data-section="testimonials">
        <div class="container mx-auto px-4 relative z-10">
            <div class="flex items-center mb-12">
                <span class="text-green-500 text-sm font-semibold mr-2 px-2.5 py-0.5 rounded border border-green-500">TESTIMONIALS</span>
                <h2 class="text-4xl font-bold text-white">Words From Our Lovely Clients</h2>
            </div>
            <div class="flex space-x-6 overflow-x-auto pb-6" id="testimonials-container" data-element="testimonial-cards">
                <!-- Testimonial cards here -->
            </div>
            <div class="flex justify-center mt-6 space-x-4" data-element="testimonial-navigation">
                <!-- Navigation buttons here -->
            </div>
        </div>
    </section>
`;
