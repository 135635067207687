import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GrapesJsEditor from "../../GrapesJsEditor";
import { FormControl, MenuItem, Select } from "@mui/material";
import ScreenshotComponent from "../partial/ScreenshotComponent";
import Menu from "../partial/Menu";
import useAxios from "../../Hooks/useAxios";
import { toast } from "react-toastify";

const apiUrl = process.env.REACT_APP_API_URL;

const Dashboard = () => {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState<{ email: string; name: string } | null>(null);
  const { data, error, sendRequest } = useAxios(`${apiUrl}/usetdetail`);
  /*  */
  const drag = [
    { value: "none", name: "none" },
    { value: "absolute", name: "design" },
    { value: "translate", name: "design_2" },
  ];

  const [selectedDrag, setSelectedDrag] = useState(drag[0].value); // Стан для обраного режиму drag
  const editor = (window as any).editor;
  const handleChange = (event) => {
    setSelectedDrag(event.target.value);
    (window as any).editor.setDragMode(event.target.value);
  };

  const setZoom = (operation) => {
    const stepZoom = 10;
    const zoom = editor.Canvas.getZoom();
    editor.Canvas.setZoom(operation === "plus" ? zoom + stepZoom : zoom - stepZoom);
  };

  const setBodyStyle = () => {
    editor.setStyle("body { overflow-x: auto; overflow-y: auto;}");
  };

  /*  */
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }

    sendRequest(
      "get",
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }, []);

  useEffect(() => {
    if (data) {
      setUserDetails(data);
    }
  }, [data]);

  useEffect(() => {
    const errorNow = error;
    if (errorNow) {
      toast.error("Error:", errorNow.message);
    }
  }, [error]);

  return (
    <div className="container_bord">
      <div className="row justify-content-center">
        <div className="">
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
              <a className="navbar-brand" href="#">
                Dashboard
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon">dd</span>
              </button>
              <ScreenshotComponent />
              <FormControl size="small">
                <Select value={selectedDrag} onChange={handleChange}>
                  {drag.map((option) => (
                    <MenuItem value={option.value} key={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div>
                {" "}
                <span className="mx-2">Zoom:</span>
                <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l" type="button" onClick={() => setZoom("plus")}>
                  <i className="fa-regular fa-plus"></i>
                </button>
                <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r" type="button" onClick={() => setZoom("minus")}>
                  <i className="fa-solid fa-minus"></i>
                </button>
              </div>
              <div>
                <button className="bg-gray-500 hover:bg-gray-600 text-gray-800 font-bold py-2 px-4 rounded-l" type="button" onClick={() => setBodyStyle()}>
                  Body Style
                </button>
              </div>
              <div className="navbar-collapse">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="#">
                      Home
                    </a>
                  </li>

                  {userDetails && (
                    <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        User Details
                      </a>
                      <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li>
                          <a className="dropdown-item" href="#">
                            Name: {userDetails.name}
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Email: {userDetails.email}
                          </a>
                        </li>
                      </ul>
                    </li>
                  )}
                  <li className="nav-item">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        localStorage.removeItem("token");
                        navigate("/");
                      }}
                    >
                      Logout
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div className="flex">
            <div className="w-1/6  bg-slate-900  border-r border-white">
              <Menu title="Dashboard" />
            </div>
            <main className="w-5/6">
              <GrapesJsEditor />
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
