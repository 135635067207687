const CanvasZoom = () => {
  const editor = (window as any).editor;

  const setZoom = (operation) => {
    const stepZoom = 10;
    const zoom = editor.Canvas.getZoom();
    editor.Canvas.setZoom(operation === "plus" ? zoom + stepZoom : zoom - stepZoom);
  };

  return (
    <div>
      {" "}
      <span className="mx-2">Zoom:</span>
      <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l" type="button" onClick={() => setZoom("plus")}>
        <i className="fa-regular fa-plus"></i>
      </button>
      <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r" type="button" onClick={() => setZoom("minus")}>
        <i className="fa-solid fa-minus"></i>
      </button>
    </div>
  );
};

export default CanvasZoom;
