export const source = `	<div class="bg-gray-200 px-6 py-12">

<h2 class="text-center mb-12">Large Buttons</h2>

<div class="container mx-auto flex flex-wrap justify-around">

    <button class="block mb-4 px-6 py-4 text-lg font-bold no-underline hover:shadow-lg hover:shadow-lg bg-green-600 text-white">Large Button</button>
    <button class="block mb-4 px-6 py-4 text-lg font-bold no-underline hover:shadow-lg bg-red-600 text-white">Large Button</button>
    <button class="block mb-4 px-6 py-4 text-lg font-bold no-underline hover:shadow-lg bg-blue-600 text-white">Large Button</button>
    <button class="block mb-4 px-6 py-4 text-lg font-bold no-underline hover:shadow-lg bg-yellow-600 text-white">Large Button</button>
    <button class="block mb-4 px-6 py-4 text-lg font-bold no-underline hover:shadow-lg bg-purple-600 text-white">Large Button</button>

</div>

</div>

<div class="bg-gray-100 px-6 py-12">

<h2 class="text-center mb-12">Medium Buttons</h2>

<div class="container mx-auto flex flex-wrap justify-around">

    <button class="block mb-4 px-4 py-3 text-sm font-bold no-underline hover:shadow-md bg-green-600 text-white">Medium Button</button>
    <button class="block mb-4 px-4 py-3 text-sm font-bold no-underline hover:shadow-md bg-red-600 text-white">Medium Button</button>
    <button class="block mb-4 px-4 py-3 text-sm font-bold no-underline hover:shadow-md bg-blue-600 text-white">Medium Button</button>
    <button class="block mb-4 px-4 py-3 text-sm font-bold no-underline hover:shadow-md bg-yellow-600 text-white">Medium Button</button>
    <button class="block mb-4 px-4 py-3 text-sm font-bold no-underline hover:shadow-md bg-purple-600 text-white">Medium Button</button>

</div>

</div>

<div class="bg-gray-200 px-6 py-12">

<h2 class="text-center mb-12">Small Buttons</h2>

<div class="container mx-auto flex flex-wrap justify-around">

    <button class="block mb-4 px-3 py-2 text-xs font-bold no-underline hover:shadow bg-green-600 text-white">Small Button</button>
    <button class="block mb-4 px-3 py-2 text-xs font-bold no-underline hover:shadow bg-red-600 text-white">Small Button</button>
    <button class="block mb-4 px-3 py-2 text-xs font-bold no-underline hover:shadow bg-blue-600 text-white">Small Button</button>
    <button class="block mb-4 px-3 py-2 text-xs font-bold no-underline hover:shadow bg-yellow-600 text-white">Small Button</button>
    <button class="block mb-4 px-3 py-2 text-xs font-bold no-underline hover:shadow bg-purple-600 text-white">Small Button</button>

</div>

</div>

<div class="bg-gray-100 px-6 py-12">

<h2 class="text-center mb-12">Large Pill Buttons</h2>

<div class="container mx-auto flex flex-wrap justify-around">

    <button class="block mb-4 px-6 py-4 text-lg font-bold rounded-full no-underline hover:shadow-lg hover:shadow-lg bg-green-600 text-white">Large Button</button>
    <button class="block mb-4 px-6 py-4 text-lg font-bold rounded-full no-underline hover:shadow-lg bg-red-600 text-white">Large Button</button>
    <button class="block mb-4 px-6 py-4 text-lg font-bold rounded-full no-underline hover:shadow-lg bg-blue-600 text-white">Large Button</button>
    <button class="block mb-4 px-6 py-4 text-lg font-bold rounded-full no-underline hover:shadow-lg bg-yellow-600 text-white">Large Button</button>
    <button class="block mb-4 px-6 py-4 text-lg font-bold rounded-full no-underline hover:shadow-lg bg-purple-600 text-white">Large Button</button>

</div>

</div>

<div class="bg-gray-200 px-6 py-12">

<h2 class="text-center mb-12">Medium Pill Buttons</h2>

<div class="container mx-auto flex flex-wrap justify-around">

    <button class="block mb-4 px-4 py-3 text-sm font-bold rounded-full no-underline hover:shadow-md bg-green-600 text-white">Medium Button</button>
    <button class="block mb-4 px-4 py-3 text-sm font-bold rounded-full no-underline hover:shadow-md bg-red-600 text-white">Medium Button</button>
    <button class="block mb-4 px-4 py-3 text-sm font-bold rounded-full no-underline hover:shadow-md bg-blue-600 text-white">Medium Button</button>
    <button class="block mb-4 px-4 py-3 text-sm font-bold rounded-full no-underline hover:shadow-md bg-yellow-600 text-white">Medium Button</button>
    <button class="block mb-4 px-4 py-3 text-sm font-bold rounded-full no-underline hover:shadow-md bg-purple-600 text-white">Medium Button</button>

</div>

</div>

<div class="bg-gray-100 px-6 py-12">

<h2 class="text-center mb-12">Small Pill Buttons</h2>

<div class="container mx-auto flex flex-wrap justify-around">

    <button class="block mb-4 px-3 py-2 text-xs font-bold rounded-full no-underline hover:shadow bg-green-600 text-white">Small Button</button>
    <button class="block mb-4 px-3 py-2 text-xs font-bold rounded-full no-underline hover:shadow bg-red-600 text-white">Small Button</button>
    <button class="block mb-4 px-3 py-2 text-xs font-bold rounded-full no-underline hover:shadow bg-blue-600 text-white">Small Button</button>
    <button class="block mb-4 px-3 py-2 text-xs font-bold rounded-full no-underline hover:shadow bg-yellow-600 text-white">Small Button</button>
    <button class="block mb-4 px-3 py-2 text-xs font-bold rounded-full no-underline hover:shadow bg-purple-600 text-white">Small Button</button>

</div>

</div>

<div class="bg-gray-200 px-6 py-12">

<h2 class="text-center mb-12">Large Rounded Buttons</h2>

<div class="container mx-auto flex flex-wrap justify-around">

    <button class="block mb-4 px-6 py-4 text-lg font-bold rounded no-underline hover:shadow-lg hover:shadow-lg bg-green-600 text-white">Large Button</button>
    <button class="block mb-4 px-6 py-4 text-lg font-bold rounded no-underline hover:shadow-lg bg-red-600 text-white">Large Button</button>
    <button class="block mb-4 px-6 py-4 text-lg font-bold rounded no-underline hover:shadow-lg bg-blue-600 text-white">Large Button</button>
    <button class="block mb-4 px-6 py-4 text-lg font-bold rounded no-underline hover:shadow-lg bg-yellow-600 text-white">Large Button</button>
    <button class="block mb-4 px-6 py-4 text-lg font-bold rounded no-underline hover:shadow-lg bg-purple-600 text-white">Large Button</button>

</div>

</div>

<div class="bg-gray-100 px-6 py-12">

<h2 class="text-center mb-12">Medium Rounded Buttons</h2>

<div class="container mx-auto flex flex-wrap justify-around">

    <button class="block mb-4 px-4 py-3 text-sm font-bold rounded no-underline hover:shadow-md bg-green-600 text-white">Medium Button</button>
    <button class="block mb-4 px-4 py-3 text-sm font-bold rounded no-underline hover:shadow-md bg-red-600 text-white">Medium Button</button>
    <button class="block mb-4 px-4 py-3 text-sm font-bold rounded no-underline hover:shadow-md bg-blue-600 text-white">Medium Button</button>
    <button class="block mb-4 px-4 py-3 text-sm font-bold rounded no-underline hover:shadow-md bg-yellow-600 text-white">Medium Button</button>
    <button class="block mb-4 px-4 py-3 text-sm font-bold rounded no-underline hover:shadow-md bg-purple-600 text-white">Medium Button</button>

</div>

</div>

<div class="bg-gray-200 px-6 py-12">

<h2 class="text-center mb-12">Small Rounded Buttons</h2>

<div class="container mx-auto flex flex-wrap justify-around">

    <button class="block mb-4 px-3 py-2 text-xs font-bold rounded no-underline hover:shadow bg-green-600 text-white">Small Button</button>
    <button class="block mb-4 px-3 py-2 text-xs font-bold rounded no-underline hover:shadow bg-red-600 text-white">Small Button</button>
    <button class="block mb-4 px-3 py-2 text-xs font-bold rounded no-underline hover:shadow bg-blue-600 text-white">Small Button</button>
    <button class="block mb-4 px-3 py-2 text-xs font-bold rounded no-underline hover:shadow bg-yellow-600 text-white">Small Button</button>
    <button class="block mb-4 px-3 py-2 text-xs font-bold rounded no-underline hover:shadow bg-purple-600 text-white">Small Button</button>

</div>

</div>
`;