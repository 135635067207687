export const source = ` 
<section class="py-20 bg-blue-600 text-white" data-section="testimonials-rotating">
<div class="container mx-auto px-4">
  <h2 class="text-3xl font-bold text-center mb-12">Що говорять професіонали</h2>
  <div class="relative h-40">
    <div class="absolute inset-0 flex items-center justify-center transition-opacity duration-500 ease-in-out">
      <p class="text-2xl text-center">"MetaFlow3D - це майбутнє 3D дизайну."</p>
    </div>
    <!-- Додайте більше цитат, які будуть змінюватися за допомогою JavaScript -->
  </div>
  <p class="text-center mt-4 font-bold">- Різні користувачі MetaFlow3D</p>
</div>
</section>
`;
