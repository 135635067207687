export const source = `  <div class="flex justify-center bg-blue-500 p-4">
<div class="flex flex-col justify-center items-center ">
    <div class="text-white text-3xl font-medium">Our Trusted Clients</div>
    <div class="flex flex-col md:flex-row max-w-7xl justify-center items-center ">

        <div class="overflow-hidden w-full m-4 flex justify-center bg-white rounded-lg  md:w-[33%] px-8">

            <div class="flex flex-col md:flex-row items-center justify-center  ">
                <div class="  items-center justify-center flex py-2">
                    <div class="flex flex-col  items-center justify-center text-center">
                        <img src="https://source.unsplash.com/150x150/?man,boy" alt="" class="rounded-full" />
                        <div class="text-stone-600 font-medium m-2"> Tailblocks provides best Tailwind CSS
                            Components and Blocks to create an unique websites within minutes.</div>
                        <div class="font-bold">John Doe</div>
                        <div class="text-cyan-600 italic"><a href="#">Board Director of Tailblocks</a></div>
                    </div>
                </div>

            </div>
        </div>
        <div class="overflow-hidden w-full m-4 flex justify-center bg-white rounded-lg  md:w-[33%] px-8">

            <div class="flex flex-col md:flex-row items-center justify-center  ">
                <div class="  items-center justify-center flex py-2">
                    <div class="flex flex-col  items-center justify-center text-center">
                        <img src="https://source.unsplash.com/150x150/?girl" alt="" class="rounded-full" />
                        <div class="text-stone-600 font-medium m-2"> Tailblocks provides best Tailwind CSS
                            Components and Blocks to create an unique websites within minutes.</div>
                        <div class="font-bold">Jessie</div>
                        <div class="text-cyan-600 italic"><a href="#">MD of Tailblocks</a></div>
                    </div>
                </div>

            </div>
        </div>
        <div class="overflow-hidden w-full m-4 flex justify-center bg-white rounded-lg  md:w-[33%] px-8">

            <div class="flex flex-col md:flex-row items-center justify-center  ">
                <div class="  items-center justify-center flex py-2">
                    <div class="flex flex-col  items-center justify-center text-center">
                        <img src="https://source.unsplash.com/150x150/?girl,woman" alt=""
                            class="rounded-full" />
                        <div class="text-stone-600 font-medium m-2"> Tailblocks provides best Tailwind CSS
                            Components and Blocks to create an unique websites within minutes.</div>
                        <div class="font-bold">Davina Claire</div>
                        <div class="text-cyan-600 italic"><a href="#">Board Director of Tailblocks</a></div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
</div>`;
