export const source = `
<!-- FAQ Gradient -->
<style>
.gradient-bg {
    background: 
        radial-gradient(circle at 20% 50%, rgba(60, 39, 164, 0.3) 0%, rgba(4, 26, 4, 0) 50%),
        radial-gradient(circle at 80% 50%, rgba(60, 39, 164, 0.3) 0%, rgba(4, 26, 4, 0) 50%),
        linear-gradient(180deg, #1a202c 0%, #0f172a 100%);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}
.card {
    background-color: rgba(17, 24, 39, 0.8);
    border: 1px solid rgba(59, 130, 246, 0.2);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    backdrop-filter: blur(4px);
}
</style>
<section class="gradient-bg min-h-screen flex items-center justify-center p-4">
    <div class="w-full max-w-4xl aspect-video relative">
        
    </div>
</section>
  
`;
