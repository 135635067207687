export const source = ` 
<div class="flex justify-center">
<nav class="self-center fixed top-0 w-full max-w-7xl shadow bg-blue-900">
    <div class="flex justify-between items-center text-gray-800">
        <h1 class="md:order-6 uppercase pl-5 py-4 text-lg font-sans font-bold">tailwind</h1>
        <ul class="md:order-10 hidden md:flex items-center text-[18px] font-semibold ">
            <li class="hover:underline underline-offset-4 decoration-2 decoration-gray-800 py-2 rounded-lg px-5"><a
                    href="#">Home</a></li>
            <li class="hover:underline underline-offset-4 decoration-2 decoration-gray-800 py-2 rounded-lg px-5"><a
                    href="#">Contact</a></li>
            <li class="hover:underline underline-offset-4 decoration-2 decoration-gray-800 py-2 rounded-lg px-5"><a
                    href="#">Services</a></li>
            <li class="hover:underline underline-offset-4 decoration-2 decoration-gray-800 py-2 rounded-lg px-5"><a
                    href="#">About</a></li>
            <li class="hover:underline underline-offset-4 decoration-2 decoration-gray-800 py-2 rounded-lg px-5"><a
                    href="#">Pricing</a></li>
        </ul>
        <div class="text-gray-800 text-center text-lg pr-5 hidden lg:inline-flex md:order-9 lg:pl-60"> <a href="#"
                class="w-8 h-8 inline-block rounded-full pt-[6px] hover:text-blue-500"><i
                    class="fa fa-twitter"></i></a> <a href="#"
                class="w-8 h-8 inline-block rounded-full pt-[5px] hover:text-blue-500"><i
                    class="fa fa-instagram"></i></a> <a href="#"
                class="w-8 h-8 inline-block rounded-full pt-[5px] hover:text-blue-500"><i
                    class="fa fa-facebook"></i></a> <a href="#"
                class="w-8 h-8 inline-block rounded-full pt-[5px] hover:text-blue-500"><i
                    class="fa fa-google"></i></a> <a href="#"
                class="w-8 h-8 inline-block rounded-full pt-[5px] hover:text-blue-500"><i
                    class="fa fa-linkedin"></i></a> </div> <button
            class="block p-3 mx-10 md:hidden hover:bg-gray-300 rounded-xl group">
            <div class="w-5 my-[3px] h-[3px] bg-gray-800 mb-[2px]"></div>
            <div class="w-5 my-[3px] h-[3px] bg-gray-800 mb-[2px]"></div>
            <div class="w-5 my-[3px] h-[3px] bg-gray-800"></div>
            <div
                class="absolute top-0 -right-full opacity-0 h-screen w-[60%] border bg-gray-300 group-focus:right-0 group-focus:opacity-100 transition-all ease-in duration-300 ">
                <ul class="flex flex-col items-center text-[18px] pt-[60px]">
                    <li class="hover:bg-gray-200 hover:border border-white py-4 px-6 w-full"><a href="#">Home</a>
                    </li>
                    <li class="hover:bg-gray-200 hover:border border-white py-4 px-6 w-full"><a
                            href="#">Contacts</a></li>
                    <li class="hover:bg-gray-200 hover:border border-white py-4 px-6 w-full"><a
                            href="#">Services</a></li>
                    <li class="hover:bg-gray-200 hover:border border-white py-4 px-6 w-full"><a href="#">About</a>
                    </li>
                    <li class="hover:bg-gray-200 hover:border border-white py-4 px-6 w-full"><a href="#">Pricing</a>
                    </li>
                    <li class=" hover:bg-gray-200 py-2 px-9 mt-3 border-2 border-white"><a href="#">Register</a>
                    </li>
                </ul>
            </div>
        </button>
    </div>
</nav>
</div>`;
