export const source =`
<link href="https://cdn.jsdelivr.net/npm/tailwindcss/dist/tailwind.min.css" rel="stylesheet">

<div class="font-sans font-thin">

<div class="w-full h-screen relative text-white">
    <img src="https://images.unsplash.com/photo-1438109491414-7198515b166b?w=1800" class="absolute top-0 left-0 w-full h-full object-cover" />

    <header class="z-10 relative mx-auto max-w-6xl px-6 py-8 flex justify-between items-center">
        
        <a href="#" class="text-xl tracking-wider">fullscreen</a>
        
        <ul class="hidden md:flex uppercase text-sm text-gray-500">
            <li>
                <a href="#" class="text-white">Home</a>
            </li>
            <li class="ml-6">
                <a href="#">About</a>
            </li>
            <li class="ml-6">
                <a href="#">Blog</a>
            </li>
            <li class="ml-6">
                <a href="#">Contact</a>
            </li>
        </ul>

    </header>

    <div class="absolute top-0 left-0 w-full h-full flex justify-center items-center text-center px-12">
        <div>
            <h1 class="text-3xl md:text-6xl leading-tight mb-16">Duis aute irure dolor, <br class="hidden md:block" />reprehenderit in voluptate.</h1>
            <a href="#" class="border border-white py-4 px-8 hover:bg-white hover:text-black">Get started</a>
        </div>
    </div>

</div>



<div class="py-24 px-6">
    
    <div class="max-w-6xl mx-auto mb-12">
        
        <h2 class="mx-auto text-center text-2xl md:text-5xl max-w-3xl leading-tight text-gray-800 mb-12">Uniting fresh design and clean code to create beautiful websites.</h2>


        <p class="mb-6 text-gray-600 mx-auto max-w-lg text-center leading-relaxed">Donec id elit non mi porta gravida at eget metus. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Nullam quis risus eget urna mollis ornare vel eu leo. Cras mattis consectetur purus sit amet fermentum. Nulla vitae elit libero, a pharetra augue. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>


        <p class="text-gray-600 mx-auto max-w-lg text-center leading-relaxed">For more photography check our <a href="#" class="text-black">Instagram</a></p>

    </div>



    <div class="max-w-5xl mx-auto flex flex-wrap items-start">
        
        <div class="w-full md:w-1/2 p-4 mb-12">
            <img src="https://images.unsplash.com/photo-1506744038136-46273834b3fb?w=800" class="block w-full h-auto mb-12" />
            <a href="#" class="block text-2xl text-black text-center mb-6">About Us</a>
            <p class="mb-6 text-gray-600 text-center leading-relaxed text-sm">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.</p>
            <a href="#" class="block text-black text-center">Read more</a>
        </div>
        
        <div class="w-full md:w-1/2 p-4 mb-12">
            <img src="https://images.unsplash.com/photo-1523712999610-f77fbcfc3843?w=800" class="block w-full h-auto mb-12" />
            <a href="#" class="block text-2xl text-black text-center mb-6">What's New?</a>
            <p class="mb-6 text-gray-600 text-center leading-relaxed text-sm">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.</p>
            <a href="#" class="block text-black text-center">Read more</a>
        </div>

    </div>

</div>


<div class="bg-gray-900 text-gray-100 text-center flex justify-center px-6 py-12">
    
    <a href="#" class="mx-6 hover:text-gray-600">123 Address</a>
    <a href="#" class="mx-6 hover:text-gray-600">0800 123 456</a>
    <a href="#" class="mx-6 hover:text-gray-600">my@email.com</a>

</div>


</div>`;