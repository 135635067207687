export const source = `   <!-- ====== Avatars Section Start -->
<section class="bg-white py-[75px] dark:bg-dark">
  <div class="mx-auto px-4 sm:container">
    <div class="flex items-end justify-center space-x-2 sm:space-x-5">
      <div class="relative h-6 w-6 rounded-lg">
        <img
          src="../images/avatar/image-01.jpg"
          alt="avatar"
          class="h-full w-full rounded-lg object-cover object-center"
        />
        <span
          class="absolute -right-0.5 -top-0.5 block h-[7px] w-[7px] rounded-full border border-white bg-[#219653] dark:border-dark"
        ></span>
      </div>
      <div class="relative h-[38px] w-[38px] rounded-[15px]">
        <img
          src="../images/avatar/image-02.jpg"
          alt="avatar"
          class="h-full w-full rounded-[15px] object-cover object-center"
        />
        <span
          class="absolute -right-1 -top-1 block h-3 w-3 rounded-full border-2 border-white bg-[#219653] dark:border-dark"
        ></span>
      </div>
      <div class="relative h-[42px] w-[42px] rounded-[15px]">
        <img
          src="../images/avatar/image-03.jpg"
          alt="avatar"
          class="h-full w-full rounded-[15px] object-cover object-center"
        />
        <span
          class="absolute -right-[6px] -top-[6px] block h-[13px] w-[13px] rounded-full border-[2.3px] border-white bg-[#219653] dark:border-dark"
        ></span>
      </div>
      <div class="relative h-[52px] w-[52px] rounded-[15px]">
        <img
          src="../images/avatar/image-04.jpg"
          alt="avatar"
          class="h-full w-full rounded-[15px] object-cover object-center"
        />
        <span
          class="absolute -right-[6px] -top-[6px] block h-[15px] w-[15px] rounded-full border-[2.7px] border-white bg-[#219653] dark:border-dark"
        ></span>
      </div>
      <div class="relative h-20 w-20 rounded-[15px]">
        <img
          src="../images/avatar/image-05.jpg"
          alt="avatar"
          class="h-full w-full rounded-[15px] object-cover object-center"
        />
        <span
          class="absolute -right-2 -top-2 block h-[18px] w-[18px] rounded-full border-[3px] border-white bg-[#219653] dark:border-dark"
        ></span>
      </div>
    </div>
  </div>
</section>
<!-- ====== Avatars Section End -->`;
