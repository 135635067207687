export const source =`  <style>
@import url('https://fonts.googleapis.com/css?family=Lato');
html { font-family: 'Lato', sans-serif; }
</style>
</head>
<div class="bg-grey-lightest tracking-wide">
<nav id="header" class="fixed w-full z-10 pin-t bg-white border-b border-grey-light">
<div class="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-4">
   <div class="pl-4 flex items-center">
      <svg class="h-5 pr-3 fill-current text-purple" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
         <path d="M0 2C0 .9.9 0 2 0h16a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm14 12h4V2H2v12h4c0 1.1.9 2 2 2h4a2 2 0 0 0 2-2zM5 9l2-2 2 2 4-4 2 2-6 6-4-4z"/>
      </svg>
      <a class="text-black text-base no-underline hover:no-underline font-extrabold text-xl"  href="#"> 
      Help Article
      </a>
   </div>
   <div class="block lg:hidden pr-4">
      <button id="nav-toggle" class="flex items-center px-3 py-2 border rounded text-grey border-grey-dark hover:text-black hover:border-purple appearance-none focus:outline-none">
         <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
         </svg>
      </button>
   </div>
   <div class="w-full flex-grow lg:flex  lg:content-center lg:items-center lg:w-auto hidden lg:block mt-2 lg:mt-0 z-20" id="nav-content">
      <div class="flex-1 w-full mx-auto max-w-sm content-center py-4 lg:py-0">
         <div class="relative pull-right pl-4 pr-4 md:pr-0">
            <input type="search" placeholder="Search" class="w-full bg-grey-lightest text-sm text-grey-darkest transition border focus:outline-none focus:border-purple rounded py-1 px-2 pl-10 appearance-none leading-normal">
            <div class="absolute search-icon" style="top: 0.375rem;left: 1.75rem;">
               <svg class="fill-current pointer-events-none text-grey-darkest w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"></path>
               </svg>
            </div>
         </div>
      </div>
      <ul class="list-reset lg:flex justify-end items-center">
         <li class="mr-3 py-2 lg:py-0">
            <a class="inline-block py-2 px-4 text-black font-bold no-underline" href="#">Active</a>
         </li>
         <li class="mr-3 py-2 lg:py-0">
            <a class="inline-block text-grey-dark no-underline hover:text-black hover:underline py-2 px-4" href="#">link</a>
         </li>
         <li class="mr-3 py-2 lg:py-0">
            <a class="inline-block text-grey-dark no-underline hover:text-black hover:underline py-2 px-4" href="#">link</a>
         </li>
      </ul>
   </div>
</div>
</nav>
<!--Container-->
<div class="container w-full flex flex-wrap mx-auto px-2 pt-8 lg:pt-16 mt-16">
<div class="w-full lg:w-1/5 lg:px-6 text-xl text-grey-darkest leading-normal">
   <p class="text-base font-bold py-2 lg:pb-6 text-grey-darker">Menu</p>
   <div class="block lg:hidden sticky pin">
      <button id="menu-toggle" class="flex w-full justify-end px-3 py-3 bg-white lg:bg-transparent border rounded border-grey-dark hover:border-purple appearance-none focus:outline-none">
         <svg class="fill-current h-3 float-right" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
         </svg>
      </button>
   </div>
   <div class="w-full sticky pin hidden h-64 lg:h-auto overflow-x-hidden overflow-y-auto lg:overflow-y-hidden lg:block mt-0 border border-grey-light lg:border-transparent bg-white shadow lg:shadow-none lg:bg-transparent z-20" style="top:5em;" id="menu-content">
      <ul class="list-reset">
         <li class="py-2 md:my-0 hover:bg-purple-lightest lg:hover:bg-transparent">
            <a href="#" class="block pl-4 align-middle text-grey-darker no-underline hover:text-purple border-l-4 border-transparent lg:border-purple lg:hover:border-purple">
            <span class="pb-1 md:pb-0 text-sm text-black font-bold">Home</span>
            </a>
         </li>
         <li class="py-2 md:my-0 hover:bg-purple-lightest lg:hover:bg-transparent">
            <a href="#" class="block pl-4 align-middle text-grey-darker no-underline hover:text-purple border-l-4 border-transparent lg:hover:border-grey-light">
            <span class="pb-1 md:pb-0 text-sm">Tasks</span>
            </a>
         </li>
         <li class="py-2 md:my-0 hover:bg-purple-lightest lg:hover:bg-transparent">
            <a href="#" class="block pl-4 align-middle text-grey-darker no-underline hover:text-purple border-l-4 border-transparent lg:hover:border-grey-light">
            <span class="pb-1 md:pb-0 text-sm">Messages</span>
            </a>
         </li>
         <li class="py-2 md:my-0 hover:bg-purple-lightest lg:hover:bg-transparent">
            <a href="#" class="block pl-4 align-middle text-grey-darker no-underline hover:text-purple border-l-4 border-transparent lg:hover:border-grey-light">
            <span class="pb-1 md:pb-0 text-sm">Analytics</span>
            </a>
         </li>
         <li class="py-2 md:my-0 hover:bg-purple-lightest lg:hover:bg-transparent">
            <a href="#" class="block pl-4 align-middle text-grey-darker no-underline hover:text-purple border-l-4 border-transparent lg:hover:border-grey-light">
            <span class="pb-1 md:pb-0 text-sm">Payments</span>
            </a>
         </li>
      </ul>
   </div>
</div>
<div class="w-full lg:w-4/5 p-8 mt-6 lg:mt-0 text-black leading-normal bg-white border border-grey-light border-rounded">
   <!--Title-->
   <div class="font-sans">
      <span class="text-base text-purple font-bold">&laquo;</span> <a href="#" class="text-base md:text-sm text-purple font-bold no-underline hover:underline">Back Link</a>
      <h1 class="font-sans break-normal text-black pt-6 pb-2 text-xl">Help page title</h1>
      <hr class="border-b border-grey-light">
   </div>
   <!--Post Content-->
   <!--Lead Para-->
   <p class="py-6">
      👋 Welcome fellow <a class="text-purple no-underline hover:underline" href="https://www.tailwindcss.com">Tailwind CSS</a> fan.  This starter template provides a starting point to create your own helpdesk / faq / knowledgebase articles using Tailwind CSS and vanilla Javascript.
   </p>
   <p class="py-6">The basic help article layout is available and all using the default Tailwind CSS classes (although there are a few hardcoded style tags). If you are going to use this in your project, you will want to convert the classes into components.</p>
   <h1 class="py-2 font-sans">Heading 1</h1>
   <h2 class="py-2 font-sans">Heading 2</h2>
   <h3 class="py-2 font-sans">Heading 3</h3>
   <h4 class="py-2 font-sans">Heading 4</h4>
   <h5 class="py-2 font-sans">Heading 5</h5>
   <h6 class="py-2 font-sans">Heading 6</h6>
   <p class="py-6">Sed dignissim lectus ut tincidunt vulputate. Fusce tincidunt lacus purus, in mattis tortor sollicitudin pretium. Phasellus at diam posuere, scelerisque nisl sit amet, tincidunt urna. Cras nisi diam, pulvinar ut molestie eget, eleifend ac magna. Sed at lorem condimentum, dignissim lorem eu, blandit massa. Phasellus eleifend turpis vel erat bibendum scelerisque. Maecenas id risus dictum, rhoncus odio vitae, maximus purus. Etiam efficitur dolor in dolor molestie ornare. Aenean pulvinar diam nec neque tincidunt, vitae molestie quam fermentum. Donec ac pretium diam. Suspendisse sed odio risus. Nunc nec luctus nisi. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Duis nec nulla eget sem dictum elementum.</p>
   <ol>
      <li class="py-3">Maecenas accumsan lacus sit amet elementum porta. Aliquam eu libero lectus. Fusce vehicula dictum mi. In non dolor at sem ullamcorper venenatis ut sed dui. Ut ut est quam. Suspendisse quam quam, commodo sit amet placerat in, interdum a ipsum. Morbi sit amet tellus scelerisque tortor semper posuere.</li>
      <li class="py-3">Morbi varius posuere blandit. Praesent gravida bibendum neque eget commodo. Duis auctor ornare mauris, eu accumsan odio viverra in. Proin sagittis maximus pharetra. Nullam lorem mauris, faucibus ut odio tempus, ultrices aliquet ex. Nam id quam eget ipsum luctus hendrerit. Ut eros magna, eleifend ac ornare vulputate, pretium nec felis.</li>
      <li class="py-3">Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Nunc vitae pretium elit. Cras leo mauris, tristique in risus ac, tristique rutrum velit. Mauris accumsan tempor felis vitae gravida. Cras egestas convallis malesuada. Etiam ac ante id tortor vulputate pretium. Maecenas vel sapien suscipit, elementum odio et, consequat tellus.</li>
   </ol>
   <blockquote class="border-l-4 border-purple italic my-8 pl-8 md:pl-12">Example of blockquote - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at ipsum eu nunc commodo posuere et sit amet ligula.</blockquote>
   <p class="py-6">Example code block:</p>
   <pre class="bg-black rounded text-white font-mono text-base p-2 md:p-4">
       <code class="break-words whitespace-pre-wrap">
&lt;header class="site-header outer"&gt;
&lt;div class="inner"&gt;
{{&gt; "site-nav"}}
&lt;/div&gt;
&lt;/header&gt;
       </code>
   </pre>
   <!--/ Post Content-->
   <!-- Useful -->
   <hr class="border-b border-grey-light py-4">
   <div class="flex items-center">
      <svg class="h-16 fill-current text-grey-dark hover:shadow hover:bg-purple-lightest hover:text-green p-4 mr-2 border rounded" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
         <path d="M11 0h1v3l3 7v8a2 2 0 0 1-2 2H5c-1.1 0-2.31-.84-2.7-1.88L0 12v-2a2 2 0 0 1 2-2h7V2a2 2 0 0 1 2-2zm6 10h3v10h-3V10z"/>
      </svg>
      <svg class="h-16 fill-current text-grey-dark hover:shadow hover:bg-purple-lightest hover:text-red p-4 mr-2 border rounded" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
         <path d="M11 20a2 2 0 0 1-2-2v-6H2a2 2 0 0 1-2-2V8l2.3-6.12A3.11 3.11 0 0 1 5 0h8a2 2 0 0 1 2 2v8l-3 7v3h-1zm6-10V0h3v10h-3z"/>
      </svg>
      <div class="pl-4">
         <p class="text-grey-darkest font-bold">Did you find this article useful?</p>
         <p class="text-xs text-grey-dark pt-2">0 out of 0 found this useful</p>
      </div>
   </div>
   <!-- /Useful -->
</div>
<!--Back link -->
<div class="w-full lg:w-4/5 lg:ml-auto text-base md:text-sm text-grey px-4 py-6">
   <span class="text-base text-purple font-bold">&lt;<span> <a href="#" class="text-base md:text-sm text-purple font-bold no-underline hover:underline">Back to Help</a></p>
</div>
</div>
<!--/container-->
<footer class="bg-white border-t border-grey-light shadow">
<div class="container mx-auto flex py-8">
   <div class="w-full mx-auto flex flex-wrap">
      <div class="flex w-full lg:w-1/2 ">
         <div class="px-8">
            <h3 class="font-bold text-black">About</h3>
            <p class="py-4 text-grey-dark text-sm">
               Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vel mi ut felis tempus commodo nec id erat. Suspendisse consectetur dapibus velit ut lacinia. 
            </p>
         </div>
      </div>
      <div class="flex w-full lg:w-1/2 lg:justify-end lg:text-right">
         <div class="px-8">
            <h3 class="font-bold text-black">Social</h3>
            <ul class="list-reset items-center text-sm pt-3">
               <li>
                  <a class="inline-block text-grey-dark no-underline hover:text-black hover:underline py-1" href="#">Add social links</a>
               </li>
            </ul>
         </div>
      </div>
   </div>
</div>
</footer>
<script>
/*Toggle dropdown list*/
/*https://gist.github.com/slavapas/593e8e50cf4cc16ac972afcbad4f70c8*/

var navMenuDiv = document.getElementById("nav-content");
var navMenu = document.getElementById("nav-toggle");

var helpMenuDiv = document.getElementById("menu-content");
var helpMenu = document.getElementById("menu-toggle");

document.onclick = check;

function check(e){
  var target = (e && e.target) || (event && event.srcElement);

 
  //Nav Menu
  if (!checkParent(target, navMenuDiv)) {
    // click NOT on the menu
    if (checkParent(target, navMenu)) {
      // click on the link
      if (navMenuDiv.classList.contains("hidden")) {
        navMenuDiv.classList.remove("hidden");
      } else {navMenuDiv.classList.add("hidden");}
    } else {
      // click both outside link and outside menu, hide menu
      navMenuDiv.classList.add("hidden");
    }
  }
  
  //Help Menu
  if (!checkParent(target, helpMenuDiv)) {
    // click NOT on the menu
    if (checkParent(target, helpMenu)) {
      // click on the link
      if (helpMenuDiv.classList.contains("hidden")) {
        helpMenuDiv.classList.remove("hidden");
      } else {helpMenuDiv.classList.add("hidden");}
    } else {
      // click both outside link and outside menu, hide menu
      helpMenuDiv.classList.add("hidden");
    }
  }
  
}

function checkParent(t, elm) {
  while(t.parentNode) {
    if( t == elm ) {return true;}
    t = t.parentNode;
  }
  return false;
}


</script>
</div>`;