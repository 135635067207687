import Cookies from "./partials/Cookies";
import Header from "./partials/Header";
import Sections from "./partials/Sections";

function Home() {
  return (
    <div>
      <Header />
      <Sections />
      <Cookies />
    </div>
  );
}

export default Home;
