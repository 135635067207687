export const source = `  <!-- ====== Avatars Section Start -->
<section class="bg-white py-[75px] dark:bg-dark">
  <div class="mx-auto px-4 sm:container">
    <div class="flex items-end justify-center">
      <div
        class="h-[60px] w-[60px] rounded-full border-4 border-white dark:border-dark-3"
      >
        <img
          src="../images/avatar/image-01.jpg"
          alt="avatar"
          class="h-full w-full rounded-full object-cover object-center"
        />
      </div>
      <div
        class="-ml-5 h-[60px] w-[60px] rounded-full border-4 border-white dark:border-dark-3"
      >
        <img
          src="../images/avatar/image-02.jpg"
          alt="avatar"
          class="h-full w-full rounded-full object-cover object-center"
        />
      </div>
      <div
        class="-ml-5 h-[60px] w-[60px] rounded-full border-4 border-white dark:border-dark-3"
      >
        <img
          src="../images/avatar/image-03.jpg"
          alt="avatar"
          class="h-full w-full rounded-full object-cover object-center"
        />
      </div>
      <div
        class="-ml-5 h-[60px] w-[60px] rounded-full border-4 border-white dark:border-dark-3"
      >
        <img
          src="../images/avatar/image-04.jpg"
          alt="avatar"
          class="h-full w-full rounded-full object-cover object-center"
        />
      </div>
      <div
        class="-ml-5 h-[60px] w-[60px] rounded-full border-4 border-white dark:border-dark-3"
      >
        <img
          src="../images/avatar/image-05.jpg"
          alt="avatar"
          class="h-full w-full rounded-full object-cover object-center"
        />
      </div>
    </div>
  </div>
</section>
<!-- ====== Avatars Section End -->`;
