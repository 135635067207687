export const source =`
<title>Slate Template</title>

<div class="font-sans font-thin p-12 bg-gray-200">

    <header class="bg-white px-8 py-8 flex justify-between items-center">
        
        <a href="#" class="font-bold text-xl tracking-wider">slate.</a>
        
        <ul class="hidden md:flex uppercase text-sm">
            <li>
                <a href="#" class="text-gray-600">Home</a>
            </li>
            <li class="ml-8">
                <a href="#">About</a>
            </li>
            <li class="ml-8">
                <a href="#">Blog</a>
            </li>
            <li class="ml-8">
                <a href="#">Contact</a>
            </li>
        </ul>

    </header>

    <div class="w-full h-screen relative flex items-center">
        <img src="https://images.unsplash.com/photo-1497442995392-ee2f25825e2a?w=1800" class="absolute top-0 left-0 w-full h-full object-cover" />

        <div class="relative z-10 px-24 text-center">
            <h2 class="mb-2 text-white uppercase text-2xl md:text-5xl leading-tight tracking-wide font-normal">Shopping Basket</h2>
            <p class="text-white mb-6">Fresh organic food delivery</p>
            <a href="#" class="inline-block uppercase bg-white py-3 px-6 text-xs">Learn more</a>
        </div>
    </div>

    <div class="bg-gray-100 py-24 px-12 text-center">
        <h2 class="text-lg md:text-2xl mb-8">We promise locally sourced, responsibly cultivated and harvested products,<br class="hidden lg:block" /> bringing only the best to the table.</h2>
        <a href="#" class="text-xs uppercase text-gray-500 hover:text-gray-900">Shop all products</a>
    </div>

    <div class="bg-white py-12 lg:py-24 px-6 lg:px-24">
        <div class="flex flex-wrap -mx-2">
            
            <div class="w-full md:w-1/3 p-2 mb-12">
                <a href="#" class="block mb-8 bg-black hover:bg-gray-700">
                    <img src="https://images.unsplash.com/photo-1457296898342-cdd24585d095?w=800" class="w-full h-64 object-cover opacity-75" />
                </a>
                <a href="#" class="block text-base text-left uppercase tracking-wider text-gray-700">Homegrown Fruits</a>
            </div>
            
            <div class="w-full md:w-1/3 p-2 mb-12">
                <a href="#" class="block mb-8 bg-black hover:bg-gray-700">
                    <img src="https://images.unsplash.com/photo-1491720731493-223f97d92c21?w-800" class="w-full h-64 object-cover opacity-75" />
                </a>
                <a href="#" class="block text-base text-left uppercase tracking-wider text-gray-700">Fruit &amp; Herbal Tea</a>
            </div>
            
            <div class="w-full md:w-1/3 p-2 mb-12">
                <a href="#" class="block mb-8 bg-black hover:bg-gray-700">
                    <img src="https://images.unsplash.com/photo-1542210435-59f90e156f10?w=1000" class="w-full h-64 object-cover opacity-75" />
                </a>
                <a href="#" class="block text-base text-left uppercase tracking-wider text-gray-700">Homemade Cake</a>
            </div>

        </div>

        <div class="py-12">

            <h2 class="text-center uppercase text-2xl lg:text-4xl tracking-wider mb-12">About Us</h2>

            <p class="text-gray-700 mx-auto max-w-2xl text-center leading-relaxed mb-12">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo.</p>

        </div>

        <div class="w-full h-64">
            <img src="https://images.unsplash.com/photo-1477064996809-dae46985eee7?w=800" class="w-full h-full object-cover" />
        </div>

        <div class="flex flex-wrap pt-12">
            <div class="w-full lg:w-1/2">
                <h3 class="uppercase text-lg lg:text-xl tracking-wider mb-6">Contact Us</h3>
            </div>
            <div class="w-full lg:w-1/2 mb-12">
                <p class="text-sm text-gray-700 tracking-wider leading-loose mb-6">
                    123 Street Name, City A12 B89<br />
                    Phone 0800 123 456
                </p>
                <a href="#" class="text-xs uppercase text-gray-500 hover:text-gray-900">Send us an email</a>
            </div>
        </div>
    </div>

    <div class="bg-black text-gray-500 px-12 py-8 flex flex-wrap">
        
        <div class="w-full lg:w-1/2 flex justify-center lg:justify-start mb-6 lg:mb-0">
            <a href="#" class="hover:text-white">Home</a>
            <span class="px-4">|</span>
            <a href="#" class="hover:text-white">About</a>
            <span class="px-4">|</span>
            <a href="#" class="hover:text-white">Team</a>
            <span class="px-4">|</span>
            <a href="#" class="hover:text-white">Blog</a>
        </div>
        <div class="w-full lg:w-1/2 text-center lg:text-right">
            &copy; copyright 2019
        </div>

    </div>


</div>`;