export const source = `
<!-- Contact Section -->
    <section class="bg-gray-900 py-20 relative overflow-hidden" data-section="contact">
        <div class="container mx-auto px-4 relative z-10">
            <div class="flex flex-wrap -mx-4">
                <div class="w-full md:w-1/2 px-4 mb-8 md:mb-0" data-element="contact-info">
                    <!-- Contact information here -->
                </div>
                <div class="w-full md:w-1/2 px-4" data-element="contact-form">
                    <!-- Contact form here -->
                </div>
            </div>
        </div>
    </section>
`;
