export const source = ` 
<section class="py-20" data-section="testimonials-grid">
<div class="container mx-auto px-4">
  <h2 class="text-3xl font-bold text-center mb-12">Враження наших користувачів</h2>
  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
    <div class="bg-white p-6 rounded-lg shadow-lg">
      <div class="flex items-center mb-4">
        <img src="user1.jpg" alt="User" class="w-12 h-12 rounded-full mr-4">
        <div>
          <p class="font-bold">Аліса Сміт</p>
          <p class="text-sm text-gray-500">Архітектор</p>
        </div>
      </div>
      <p class="text-gray-600">"MetaFlow3D дозволяє мені втілювати мої архітектурні ідеї в життя з неймовірною швидкістю та точністю."</p>
    </div>
    <!-- Додайте ще 2-5 подібних блоків з відгуками -->
  </div>
</div>
</section>
`;
