export const source = ` 
<section class="bg-gray-900 text-white py-20" data-section="testimonials-video">
<div class="container mx-auto px-4">
  <h2 class="text-3xl font-bold text-center mb-12">Наші клієнти діляться досвідом</h2>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
    <div>
      <div class="aspect-w-16 aspect-h-9 mb-4">
        <iframe src="https://www.youtube.com/embed/VIDEO_ID" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
      </div>
      <p class="font-bold">Марк Джонсон, CEO Design Solutions</p>
    </div>
    <div>
      <div class="aspect-w-16 aspect-h-9 mb-4">
        <iframe src="https://www.youtube.com/embed/VIDEO_ID" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
      </div>
      <p class="font-bold">Емілі Чен, 3D Artist</p>
    </div>
  </div>
</div>
</section>
`;
