export const source = `
<div class="w-full flex flex-wrap justify-center items-center min-h-screen p-5 bg-gradient-to-b from-blue-200 to bg-blue-800 gap-10">
<div class="h-96 w-72 space-y-2 text-gray-400 rounded-lg px-5 py-5 border-2 border-blue-800 bg-[url(https://img.freepik.com/free-vector/white-abstract-background_23-2148806276.jpg)]">
    <h3 class="text-blue-600 font-bold">Start-Up</h3>
    <h3 class="text-blue-600 font-extrabold">Free-Forever</h3>
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta quos rem quidem </p>
    <ul class="space-y-2 py-2">
        <li><i class="fa-solid fa-circle-check"></i> 50GB File Storage</li>
        <li><i class="fa-solid fa-circle-check"></i> 500 Emails Per Day</li>
        <li><i class="fa-solid fa-circle-check"></i> 10 Workflows</li>
        <li><i class="fa-solid fa-circle-check"></i> Deal PipleLine</li>
    </ul>
    <div class="text-center px-5 py-2 border-2 border-blue-600 rounded-3xl font-bold text-blue-600 hover:bg-blue-800 hover:text-white hover:cursor-pointer">Request a Demo</div>
</div>
<div class="h-96 w-72 space-y-2 text-gray-400 rounded-lg px-5 py-5 border-2 border-blue-800 bg-[url(https://img.freepik.com/free-vector/white-abstract-background_23-2148806276.jpg)] ">
    <h3 class="text-blue-600 font-bold">Start-Up</h3>
    <h3 class="text-blue-600 font-extrabold">Free-Forever</h3>
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta quos rem quidem </p>
    <ul class="space-y-2 py-2">
        <li><i class="fa-solid fa-circle-check"></i> 50GB File Storage</li>
        <li><i class="fa-solid fa-circle-check"></i> 500 Emails Per Day</li>
        <li><i class="fa-solid fa-circle-check"></i> 10 Workflows</li>
        <li><i class="fa-solid fa-circle-check"></i> Deal PipleLine</li>
    </ul>
    <div class="text-center px-5 py-2 border-2 border-blue-600 rounded-3xl font-bold 
    bg-blue-800 text-white hover:bg-white hover:text-blue-600 hover:cursor-pointer">Request a Demo</div>
</div>
</div>`;