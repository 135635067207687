import { useState, useEffect } from "react";
import axios from "axios";
import TelegramSettings from "./TelegramSettings";
import PostManagement from "./PostManagement";
import GameManagement from "./GameManagement";
import MessageSender from "./MessageSender";
import JoinRequestsManagement from "./JoinRequestsManagement";
import WebAppManagement from "./WebAppManagement";

const TelegramManagement = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [message, setMessage] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${apiUrl}/projects`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProjects(response.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
      setMessage("Error fetching projects");
    }
  };

  const handleProjectChange = (e) => {
    setSelectedProjectId(e.target.value);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Telegram Bot Management</h1>

      <div className="mb-4">
        <label className="block mb-2">Select Project:</label>
        <select value={selectedProjectId} onChange={handleProjectChange} className="w-full p-2 border rounded">
          <option value="">Select a project</option>
          {projects.map((project) => (
            <option key={project.id} value={project.id}>
              {project.name}
            </option>
          ))}
        </select>
      </div>

      {selectedProjectId && (
        <>
          <TelegramSettings projectId={selectedProjectId} setMessage={setMessage} />
          <PostManagement projectId={selectedProjectId} setMessage={setMessage} />
          <GameManagement projectId={selectedProjectId} setMessage={setMessage} />
          <WebAppManagement projectId={selectedProjectId} setMessage={setMessage} />  
          <MessageSender projectId={selectedProjectId} setMessage={setMessage} />
          <JoinRequestsManagement projectId={selectedProjectId} setMessage={setMessage} />
        </>
      )}

      {message && <p className="mt-4 text-center font-semibold">{message}</p>}
    </div>
  );
};

export default TelegramManagement;
