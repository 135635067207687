export const source = `<div class="flex items-center justify-center">
<div class="flex-col items-center justify-center text-gray-800 text-center">
    <div class="flex items-center justify-center">
        <div class="h-36 w-[90%] flex items-center justify-center md:text-4xl text-2xl lg:text-5xl font-bold ">Tailblocks is the best website for Tailwind CSS Blocks</div>
    </div>

    <div class="flex">

    <div class="flex md:flex-row flex-col p-4 space-x-4 space-y-2 max-w-7xl justify-around w-full h-auto lg:h-60 items-center ">
        <div class=" w-[70%] md:w-1/2 bg-gray-200 py-10 rounded-2xl flex items-center justify-center ml-4">
                <div class="flex-col  items-center px-0 md:px-6">
                    <div class="text-5xl font-bold bg-gradient-to-r from-blue-400 to-emerald-400  bg-clip-text text-transparent">98%</div>
                    <div class="text-md mt-8 font-bold text-gray-800">Tailblocks has gained 98% users this year</div>
                    <div class="text-xs font-medium text-gray-800">Tailblocks has more than 15k+ visitors per month.</div>
            </div>
        </div>
        <div class=" w-[70%] md:w-1/2 bg-gray-200 py-10 rounded-2xl flex items-center justify-center ml-4">
                <div class="flex-col  items-center px-0 md:px-6">
                    <div class="text-5xl font-bold bg-gradient-to-l from-fuchsia-500 to-orange-500  bg-clip-text text-transparent">128%</div>
                    <div class="text-md mt-8 font-bold text-gray-800">Tailblocks has 128% new impressions this year</div>
                    <div class="text-xs font-medium text-gray-800">Tailblocks has more than 15k+ visitors per month.</div>
            </div>
        </div>
        
        </div>
        
    </div>
    </div>
</div>`;
