export const source = `    <div class="flex justify-center">
<div
    class="flex flex-col justify-center items-center md:flex-row shadow rounded-xl max-w-7xl w-[90%] h-[670px] md:h-[460px] m-2">
    <div class="h-[100%] w-full md:w-3/4  bg-center  bg-cover rounded-lg"
        style="background-image: url(https://images.pexels.com/photos/733745/pexels-photo-733745.jpeg?cs=srgb&dl=pexels-cesar-perez-733745.jpg&fm=jpg)">
        <div class="text-lg lg:text-xl text-center mt-16 space-x-5">
           
            <a href="#">
                <i class="fa-brands fa-facebook-f text-blue-500  bg-white rounded-full px-[14px] py-[10px] " ></i>
            </a>
           <a href="#">
               <i class="fa-brands fa-twitter text-cyan-400 bg-white rounded-full px-[10px] py-[10px] "></i>
           </a>
           <a href="#">
               <i class="fa-brands fa-google-plus-g text-red-500 bg-white rounded-full px-[10px] py-[10px] "></i>
           </a>
        </div>
        <div class="text-white text-base font-semibold text-center my-10 space-y-2">
            <h1 class="">Don't have an account ?</h1>
            <h3> <a href="#" class="underline underline-offset-4 tracking-wide hover:text-blue-400">Signup</a> </h3>
        </div>

    </div>
    <div class="h-[90%] w-full md:w-3/4">
        <div class="text-xl cursor-pointer flex flex-col justify-center items-center mt-5 md:mt-0">
            <img class="h-10 md:h-14 w-[13%] md:w-[17%] "
                src="https://tailwindcss.com/_next/static/media/social-square.b622e290e82093c36cca57092ffe494f.jpg"
                alt="" srcset="">
            <h1 class="font-semibold text-gray-600">Tailwind CSS</h1>
        </div>
        <div class="flex flex-col justify-center items-center mt-10 md:mt-14 space-y-6 md:space-y-8">
            <div class="">
                <input type="text" placeholder="User Name"
                    class=" bg-gray-100 rounded-lg px-5 py-2 focus:border border-blue-600 focus:outline-none text-black placeholder:text-gray-600 placeholder:opacity-50 font-semibold md:w-72 lg:w-[340px]">
            </div>
            <div class="">
                <input type="password" placeholder="Password"
                    class=" bg-gray-100 rounded-lg px-5 py-2 focus:border border-blue-600 focus:outline-none text-black placeholder:text-gray-600 placeholder:opacity-50 font-semibold md:w-72 lg:w-[340px]">
            </div>
            <div class="flex space-x-2 -ml-28 md:-ml-40  lg:-ml-52">
                <input class="" type="checkbox" id="checkbox" name="checkbox">
                <h3 class="text-sm font-semibold text-gray-400 -mt-1 cursor-pointer">Remember Me</h3>
            </div>
        </div>
        <div class="text-center mt-7">
            <button
                class="uppercase px-24 md:px-[118px] lg:px-[140px] py-2 rounded-md text-white bg-blue-500 hover:bg-blue-600  font-medium ">login</button>
        </div>
        <div class="text-center my-6">
            <a href="#" class="text-sm font-medium text-gray-400 hover:text-blue-500 hover:underline">Forgot
                Password ?</a>
        </div>
    </div>
</div>
</div>`;
