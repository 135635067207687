export const source = `
<!-- Gradient -->
<style>
.gradient-bg {
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at 25% 32%, #22c55e2e 0%, rgba(4, 26, 4, 0) 30%), radial-gradient(circle at 75% 57%, #3c27a475 0%, rgba(4, 26, 4, 0) 36%) , linear-gradient(180deg, #040810, #071a46);
}
</style> 
<section class="gradient-bg min-h-screen flex items-center justify-center p-4">
    <div class="w-full max-w-4xl aspect-video relative">
      <h2 class="text-white text-4xl font-bold text-center">Ваш контент тут</h2>
    </div>
</section>
`;
