import { useState } from "react";
import axios from "axios";

const TelegramChannel = () => {
  const [channelName, setChannelName] = useState("");
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [useConsole, setUseConsole] = useState(false); // Для перемикання

  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchMessages = async () => {
    setLoading(true);
    setError("");
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${apiUrl}/telegram/channel-messages`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          channel: channelName,
          use_console: useConsole, // Передаємо параметр
        },
      });

      setMessages(response.data.messages || []);
    } catch (err) {
      setError("Failed to fetch messages: " + (err.response?.data?.error || err.message));
    }
    setLoading(false);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Telegram Channel Messages</h1>
      <div className="mb-4">
        <input
          type="text"
          value={channelName}
          onChange={(e) => setChannelName(e.target.value)}
          placeholder="Enter channel name"
          className="w-full p-2 border rounded"
        />
      </div>

      <div className="mb-4">
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            checked={useConsole}
            onChange={() => setUseConsole(!useConsole)}
            className="mr-2"
          />
          Use Console Command
        </label>
      </div>

      <button
        onClick={fetchMessages}
        disabled={loading}
        className="bg-blue-500 text-white px-4 py-2 rounded"
      >
        {loading ? "Loading..." : "Fetch Messages"}
      </button>

      {error && <p className="text-red-500 mt-2">{error}</p>}

      <div className="mt-4">
        {messages.map((msg, index) => (
          <div key={index} className="border-b py-2">
            <p className="font-bold">{msg.message}</p>
            <p className="text-sm text-gray-500">
              {new Date(msg.date * 1000).toLocaleString()}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TelegramChannel;
