export const source = `<div class="flex items-center justify-center text-center">
<div class="flex flex-col p-2 m-2  max-w-7xl">
    <div class="text-3xl font-medium">Meet Our Team</div>
    <div class="text-sm mx-2 md:text-xl text-stone-500">Meet the team member who have contributed their designs for this websites.</div>
    <div class="text-sm mx-2 md:text-xl mb-2 text-stone-500">Resource person of Tailblocks communinty</div>

    <div class="flex items-center justify-center md:flex-row flex-col space-x-4 space-y-2  p-2">
       
        <div class="flex-col px-6 w-auto md:w-40 py-2 ml-3 md:ml-0 bg-gray-300 rounded-2xl">
            <div class="h-28 w-28  rounded-full overflow-hidden bg-gray-200">

                <img  src="https://source.unsplash.com/300x300/?man,businessman" alt="" class="" />
            </div>
            
            <div class="text-lg font-medium text-stone-600 cursor-pointer hover:text-stone-400">John Doe</div>
            <div class=" italic text-gray-500">CEO, Tailblocks</div>
        </div>
        <div class="flex-col px-6 w-auto md:w-40 py-2  bg-gray-300 rounded-2xl">
            <div class="h-28 w-28  rounded-full overflow-hidden bg-gray-200">

                <img  src="https://source.unsplash.com/300x300/?boy" alt="" class="" />
            </div>
            
            <div class="text-lg font-medium text-stone-600 cursor-pointer hover:text-stone-400">Arkay</div>
            <div class=" italic text-gray-500">Executive </div>
        </div>
        <div class="flex-col px-6 w-auto md:w-40 py-2  bg-gray-300 rounded-2xl">
            <div class="h-28 w-28  rounded-full overflow-hidden bg-gray-200">

                <img  src="https://source.unsplash.com/300x300/?girl" alt="" class="" />
            </div>
            
            <div class="text-lg font-medium text-stone-600 cursor-pointer hover:text-stone-400">Elina Gilbert</div>
            <div class=" italic text-gray-500">HR</div>
        </div>
        
    </div>
    <div class="flex space-x-4 space-y-2  p-2 items-center justify-center md:flex-row flex-col ">
        <div class="flex-col px-6 w-auto md:w-40 py-2 ml-3 md:ml-0 bg-gray-300 rounded-2xl">
            <div class="h-28 w-28  rounded-full overflow-hidden bg-gray-200">

                <img  src="https://source.unsplash.com/300x300/?kid" alt="" class="" />
            </div>
            
            <div class="text-lg font-medium text-stone-600 cursor-pointer hover:text-stone-400">Clay Johnson</div>
            <div class=" italic text-gray-500">Manager</div>
        </div>
        <div class="flex-col px-6 w-auto md:w-40 py-2  bg-gray-300 rounded-2xl">
            <div class="h-28 w-28  rounded-full overflow-hidden bg-gray-200">

                <img  src="https://source.unsplash.com/300x300/?girl,woman" alt="" class="" />
            </div>
            
            <div class="text-lg font-medium text-stone-600 cursor-pointer hover:text-stone-400">Hannah Saw</div>
            <div class=" italic text-gray-500">Marketing</div>
        </div>
        <div class="flex-col px-6 w-auto md:w-40 py-2  bg-gray-300 rounded-2xl">
            <div class="h-28 w-28  rounded-full overflow-hidden bg-gray-200">

                <img  src="https://source.unsplash.com/300x300/?woman" alt="" class="" />
            </div>
            
            <div class="text-lg font-medium text-stone-600 cursor-pointer hover:text-stone-400">Anne Winters</div>
            <div class=" italic text-gray-500">Financer</div>
        </div>
        
    </div>
</div>
</div>`;
