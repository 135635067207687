export const source = `<div class="flex justify-center">
<div class="flex flex-col justify-center">
    <div class="flex flex-col md:flex-row max-w-7xl justify-center items-center ">
        <div class="overflow-hidden w-full m-4 shadow-sm flex justify-center">
            <div class="flex flex-col md:flex-row items-center m-1">
                <div class=" w-3/4 md:w-1/2 overflow-hidden m-1"> <img
                        src="https://source.unsplash.com/700x350/?beach,nature" alt="" class="" />
                    <div class="flex text-gray-500 text-sm m-2">
                        <div class="m-1 font-bold">Vlog,</div>
                        <div class="m-1">31 March, 2023</div>
                    </div>
                    <div class="font-bold text-black text-xl m-2">Study nature, love nature, stay close to nature.
                        It will never fail you.</div>
                    <div class="text-sm text-gray-500 mt-4 m-2"><a href="#">There is something special and unique
                            about nature. It has the ability to take us away from the hustle and bustle of our
                            everyday lives and transport us to a different world. Whether it is the beauty of a
                            sunset, the majesty of a waterfall, or the serenity of a forest, nature has a way of
                            captivating our hearts and souls. </a></div>
                    <div class="flex cursor-pointer">
                        <div class="m-2"> <img src="https://source.unsplash.com/50x50/?girl" alt=""
                                class=" rounded-full" /> </div>
                        <div class="grid m-1">
                            <div class="font-bold text-sm hover:text-gray-600 mt-2">Max</div>
                            <div class=" text-sm hover:text-gray-600">Professor, California</div>
                        </div>
                    </div>
                </div>
                <div class=" w-3/4 md:w-1/2 overflow-hidden "> <img
                        src="https://source.unsplash.com/700x350/?forest" alt="" class="" />
                    <div class="flex text-gray-500 text-sm ">
                        <div class="m-1 font-bold">Vlog,</div>
                        <div class="m-1">31 March, 2023</div>
                    </div>
                    <div class="font-bold text-black text-xl m-2">Forests are one of the most important ecosystems
                        on Earth. They provide homes for wildlife, purify the air we breathe, and help to regulate
                        the Earth climate. Forests are also a major source of timber and other forest products.
                        Unfortunately, forests around the world are under threat from deforestation.</div>
                    <div class="text-sm text-gray-500 mt-4 m-2"><a href="#">I do not like formal gardens. I like
                            wild nature. It is just the wilderness instinct to me, I guess.</a></div>
                    <div class="flex cursor-pointer">
                        <div class="m-2"> <img src="https://source.unsplash.com/50x50/?boy" alt=""
                                class=" rounded-full" /> </div>
                        <div class="grid m-1">
                            <div class="font-bold text-sm hover:text-gray-600 mt-2">Klaus Mikelson</div>
                            <div class=" text-sm hover:text-gray-600">Freelancer, Tokyo</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>`;
