export const source = ` <div class="flex justify-center ">
<nav class="self-center top-0 w-full max-w-7xl shadow bg-green-500">
    <div class="flex justify-between items-center text-white">
        <h1 class="uppercase pl-10 py-4 text-xl font-sans font-bold">logo</h1>
        <ul class="hidden md:flex items-center text-[18px] font-semibold pr-10 space-x-1">
            <li class="hover:border border-dotted py-1 rounded-lg px-4"><a href="#">Home</a></li>
            <li class="hover:border border-dotted py-1 rounded-lg px-4"><a href="#">Portfolio</a></li>
            <li class="hover:border border-dotted py-1 rounded-lg px-4"><a href="#">Services</a></li>
            <li class="hover:border border-dotted py-1 rounded-lg px-4"><a href="#">About</a></li>
            <li class="hover:border border-dotted py-1 rounded-lg px-4 "><a href="#">Pricing</a></li> <button
                class="hidden md:flex text-base border border-white px-5 py-2 my-0 font-semibold hover:bg-green-600 rounded-2xl">Shop</button>
        </ul> <button class="block p-3 mx-10 md:hidden hover:bg-green-600 rounded-xl group">
            <div class="w-5 my-[3px] h-[3px] bg-white mb-[2px]"></div>
            <div class="w-5 my-[3px] h-[3px] bg-white mb-[2px]"></div>
            <div class="w-5 my-[3px] h-[3px] bg-white"></div>
            <div
                class="absolute top-0 -left-full opacity-0 h-screen w-[60%] border bg-green-500 group-focus:left-0 group-focus:opacity-100 transition-all ease-in duration-300 ">
                <ul class="flex flex-col items-center text-[18px] pt-[60px] ">
                    <li class="hover:bg-green-600 py-4 px-6 w-full"><a href="#">Home</a></li>
                    <li class="hover:bg-green-600 py-4 px-6 w-full"><a href="#">Portfolio</a></li>
                    <li class="hover:bg-green-600 py-4 px-6 w-full"><a href="#">Services</a></li>
                    <li class="hover:bg-green-600 py-4 px-6 w-full"><a href="#">About</a></li>
                    <li class="hover:bg-green-600 py-4 px-6 w-full"><a href="#">Pricing</a></li>
                    <li class="hover:bg-green-600 py-2 px-9 mt-3 border-2 border-white "><a href="#">Shop</a></li>
                </ul>
            </div>
        </button>
    </div>
</nav>
</div>`;
