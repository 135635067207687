import { useState, useEffect } from "react";
import axios from "axios";

const PostManagement = ({ projectId, setMessage }) => {
  const [posts, setPosts] = useState([]);
  const [selectedPostId, setSelectedPostId] = useState("");
  const [destinationType, setDestinationType] = useState("channel");
  const [destination, setDestination] = useState("");
  const [newPost, setNewPost] = useState({
    title: "",
    content: "",
    category: "",
    publish: false,
  });
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (projectId) {
      fetchPosts();
    }
  }, [projectId]);

  const fetchPosts = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${apiUrl}/telegram/posts?project_id=${projectId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPosts(response.data);
    } catch (error) {
      console.error("Error fetching posts:", error);
      setMessage("Error fetching posts");
    }
  };

  const handlePostSelection = (e) => {
    setSelectedPostId(e.target.value);
  };

  const sendPost = async (e) => {
    e.preventDefault();
    if (!selectedPostId) {
      setMessage("Please select a post to send");
      return;
    }
    if (!destination) {
      setMessage("Please enter a destination");
      return;
    }
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${apiUrl}/telegram/telegram-send-post`,
        {
          project_id: projectId,
          post_id: selectedPostId,
          destination_type: destinationType,
          destination: destination,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setMessage("Post sent successfully to Telegram");
      fetchPosts();
    } catch (error) {
      console.error("Error sending post:", error);
      setMessage("Error sending post to Telegram: " + (error.response?.data?.error || error.message));
    }
  };

  const handleNewPostChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewPost((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const createPost = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${apiUrl}/telegram/telegram-posts`,
        {
          ...newPost,
          project_id: projectId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setMessage("Post created successfully and ready for Telegram");
      setNewPost({ title: "", content: "", category: "", publish: false });
      fetchPosts();
    } catch (error) {
      console.error("Error creating post:", error);
      setMessage("Error creating post");
    }
  };

  return (
    <div className="mb-8">
      <h2 className="text-xl font-semibold mb-4">Post Management</h2>
      <form onSubmit={sendPost} className="mb-4">
        <h3 className="text-lg font-semibold mb-2">Send Post to Telegram</h3>
        <div className="mb-4">
          <label className="block mb-2">Select Post:</label>
          <select value={selectedPostId} onChange={handlePostSelection} className="w-full p-2 border rounded">
            <option value="">Select a post</option>
            {posts.map((post) => (
              <option key={post.id} value={post.id}>
                {post.title} {post.sent_to_telegram ? "(Sent)" : ""}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block mb-2">Destination Type:</label>
          <select value={destinationType} onChange={(e) => setDestinationType(e.target.value)} className="w-full p-2 border rounded">
            <option value="channel">Channel</option>
            <option value="group">Group</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block mb-2">Destination:</label>
          <input
            type="text"
            value={destination}
            onChange={(e) => setDestination(e.target.value)}
            className="w-full p-2 border rounded"
            placeholder="Enter channel name or group ID"
            required
          />
        </div>
        <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded">
          Send Post to Telegram
        </button>
      </form>

      <form onSubmit={createPost}>
        <h3 className="text-lg font-semibold mb-2">Create New Post for Telegram</h3>
        <div className="mb-4">
          <label className="block mb-2">Title:</label>
          <input type="text" name="title" value={newPost.title} onChange={handleNewPostChange} className="w-full p-2 border rounded" required />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Content:</label>
          <textarea name="content" value={newPost.content} onChange={handleNewPostChange} className="w-full p-2 border rounded" rows={4} required></textarea>
        </div>
        <div className="mb-4">
          <label className="block mb-2">Category:</label>
          <input type="text" name="category" value={newPost.category} onChange={handleNewPostChange} className="w-full p-2 border rounded" required />
        </div>
        <div className="mb-4">
          <label className="flex items-center">
            <input type="checkbox" name="publish" checked={newPost.publish} onChange={handleNewPostChange} className="mr-2" />
            Publish
          </label>
        </div>
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
          Create Post
        </button>
      </form>
    </div>
  );
};

export default PostManagement;
