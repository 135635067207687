import React, { useState, useEffect } from "react";
import axios from "axios";
import ModalCustom from "../partial/ModalCustom";

interface Destination {
  type: string;
  identifier: string;
}

interface TelegramSettingsState {
  bot_token: string;
  chat_id: string;
  is_active: boolean;
  additional_settings: string;
  game_short_name: string | null;
  game_url: string | null;
  web_app_url: string | null;
  destinations: Destination[];
}

const TelegramSettings: React.FC<{ projectId: string; setMessage: (message: string) => void }> = ({ projectId, setMessage }) => {
  const [settings, setSettings] = useState<TelegramSettingsState>({
    bot_token: "",
    chat_id: "",
    is_active: false,
    additional_settings: "",
    game_short_name: null,
    game_url: null,
    web_app_url: null,
    destinations: [],
  });

  const [isOpenInstructions, setIsOpenInstructions] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (projectId) {
      fetchSettings();
    }
  }, [projectId]);

  const fetchSettings = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${apiUrl}/telegram/settings/${projectId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setSettings({
        bot_token: response.data.bot_token || "",
        chat_id: response.data.chat_id || "",
        is_active: response.data.is_active || false,
        additional_settings: response.data.additional_settings || "",
        game_short_name: response.data.game_short_name || null,
        game_url: response.data.game_url || null,
        web_app_url: settings.web_app_url || null,
        destinations: Array.isArray(response.data.destinations) ? response.data.destinations : [],
      });
    } catch (error) {
      console.error("Error fetching settings:", error);
      setMessage("Error fetching Telegram settings");
    }
  };

  const handleSettingsChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;
    if (name === "destinations") {
      const destinationsArray = value
        .split(",")
        .map((item) => {
          const [identifier, type] = item.trim().split(":");
          return {
            identifier: identifier.trim(),
            type: (type && type.trim()) || "channel", // Default to 'channel' if type is not specified
          };
        })
        .filter((item) => item.identifier !== "");
      setSettings((prev) => ({ ...prev, destinations: destinationsArray }));
    } else {
      setSettings((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? (e.target as HTMLInputElement).checked : value,
      }));
    }
  };

  const saveSettings = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const settingsToSave = {
        ...settings,
        project_id: projectId,
      };
      await axios.post(`${apiUrl}/telegram/telegram-settings`, settingsToSave, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setMessage("Settings saved successfully");
    } catch (error) {
      console.error("Error saving settings:", error);
      setMessage("Error saving settings");
    }
  };

  const closeModalInstructions = () => setIsOpenInstructions(false);
  const showInstructions = () => setIsOpenInstructions(true);

  return (
    <>
      <form onSubmit={saveSettings} className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Bot Settings</h2>
        <div className="mb-4">
          <label className="block mb-2">
            Bot Token:
            <a href="#" onClick={showInstructions} className="text-blue-500 ml-2">
              (Як отримати?)
            </a>
          </label>
          <input
            type="text"
            name="bot_token"
            value={settings.bot_token}
            onChange={handleSettingsChange}
            className="w-full p-2 border rounded"
            placeholder="Enter your Telegram Bot Token"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Chat ID:</label>
          <input
            type="text"
            name="chat_id"
            value={settings.chat_id}
            onChange={handleSettingsChange}
            className="w-full p-2 border rounded"
            placeholder="Enter Chat ID"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Destinations (format: identifier:type, comma-separated):</label>
          <input
            type="text"
            name="destinations"
            value={settings.destinations.map((d) => `${d.identifier}:${d.type}`).join(", ")}
            onChange={handleSettingsChange}
            className="w-full p-2 border rounded"
            placeholder="karambda:channel, -4564997132:group"
            required
          />
        </div>
        <div className="mb-4">
          <label className="flex items-center">
            <input type="checkbox" name="is_active" checked={settings.is_active} onChange={handleSettingsChange} className="mr-2" />
            Active
          </label>
        </div>
        <div className="mb-4">
          <label className="block mb-2">Additional Settings:</label>
          <textarea
            name="additional_settings"
            value={settings.additional_settings}
            onChange={handleSettingsChange}
            className="w-full p-2 border rounded"
            placeholder="Enter additional settings as JSON"
            rows={4}
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Game Short Name:</label>
          <input
            type="text"
            name="game_short_name"
            value={settings.game_short_name || ""}
            onChange={handleSettingsChange}
            className="w-full p-2 border rounded"
            placeholder="Enter game short name"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Game URL:</label>
          <input
            type="text"
            name="game_url"
            value={settings.game_url || ""}
            onChange={handleSettingsChange}
            className="w-full p-2 border rounded"
            placeholder="Enter game URL"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Web App URL:</label>
          <input
            type="text"
            name="web_app_url"
            value={settings.web_app_url || ""}
            onChange={handleSettingsChange}
            className="w-full p-2 border rounded"
            placeholder="Enter Web App URL (optional)"
          />
        </div>
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
          Save Settings
        </button>
      </form>

      {isOpenInstructions && (
        <ModalCustom isOpen={isOpenInstructions} closeModal={closeModalInstructions} text="Instructions" zIndex={10}>
          <>
            <div className="telegram-instructions">
              <h2>Як отримати Bot Token та Chat ID для Telegram бота</h2>

              <h3>Отримання Bot Token</h3>
              <ol>
                <li>Відкрийте Telegram і знайдіть користувача @BotFather.</li>
                <li>
                  Почніть діалог з @BotFather і відправте команду <code>/newbot</code>.
                </li>
                <li>
                  Дотримуйтесь інструкцій BotFather:
                  <ul>
                    <li>Введіть ім'я для вашого бота (наприклад, "Мій Тестовий Бот").</li>
                    <li>Введіть унікальне ім'я користувача для вашого бота, яке повинно закінчуватися на "bot" (наприклад, "my_test_bot").</li>
                  </ul>
                </li>
                <li>
                  Після успішного створення бота, BotFather надасть вам Bot Token. Це довгий рядок символів, який виглядає приблизно так:{" "}
                  <code>123456789:ABCdefGhIJKlmNoPQRsTUVwxyZ</code>.
                </li>
                <li>Збережіть цей токен, він буде потрібен для налаштування вашого бота.</li>
              </ol>

              <h3>Отримання Chat ID</h3>
              <p>Існує кілька способів отримати Chat ID. Ось два найпростіші:</p>

              <h4>Метод 1: Використання бота @userinfobot</h4>
              <ol>
                <li>Знайдіть в Telegram бота @userinfobot і почніть з ним діалог.</li>
                <li>Відправте будь-яке повідомлення цьому боту.</li>
                <li>Бот відповість вам інформацією про ваш акаунт, включаючи ваш Chat ID.</li>
              </ol>

              <h4>Метод 2: Використання API Telegram</h4>
              <ol>
                <li>Відправте повідомлення вашому новоствореному боту.</li>
                <li>
                  Відкрийте у браузері наступну URL-адресу (замініть YOUR_BOT_TOKEN на ваш реальний Bot Token):
                  <pre>
                    <code>https://api.telegram.org/botYOUR_BOT_TOKEN/getMe</code>
                  </pre>
                </li>
                <li>Ви побачите JSON-відповідь. Знайдіть в ній поле "chat" і в ньому "id". Це і буде ваш Chat ID.</li>
              </ol>

              <h3>Важливі примітки</h3>
              <ul>
                <li>Зберігайте ваш Bot Token в безпеці. Не передавайте його стороннім особам і не публікуйте публічно.</li>
                <li>Якщо ви хочете, щоб бот відправляв повідомлення в групу, вам потрібно додати бота до цієї групи і отримати Chat ID групи.</li>
                <li>
                  Для отримання Chat ID групи, зробіть бота адміністратором групи, відправте повідомлення в групу і використайте метод getUpdates, як описано
                  вище.
                </li>
              </ul>

              <h3>Використання отриманих даних</h3>
              <p>
                Після отримання Bot Token та Chat ID, ви можете використовувати їх у вашому додатку для налаштування Telegram інтеграції. Введіть ці дані у
                відповідні поля в інтерфейсі керування Telegram ботом вашого додатку.
              </p>
            </div>
          </>
        </ModalCustom>
      )}
    </>
  );
};

export default TelegramSettings;
