export const source = `    <!-- ====== Badges Section Start -->
<section class="bg-white py-20 lg:py-[120px] dark:bg-dark">
  <div class="container mx-auto">
    <div class="-mx-2 mb-8 flex flex-wrap items-center">
      <span
        class="m-2 inline-block rounded border border-transparent bg-primary px-2.5 py-1 text-xs font-medium text-white"
      >
        Primary
      </span>
      <span
        class="m-2 inline-block rounded border border-transparent bg-secondary px-2.5 py-1 text-xs font-medium text-white"
      >
        Secondary
      </span>
      <span
        class="m-2 inline-block rounded border border-transparent bg-dark px-2.5 py-1 text-xs font-medium text-white dark:bg-dark-2"
      >
        Dark
      </span>
      <span
        class="m-2 inline-block rounded border border-transparent bg-body-color px-2.5 py-1 text-xs font-medium text-white"
      >
        Gray
      </span>
      <span
        class="m-2 inline-block rounded border border-transparent bg-gray-3 px-2.5 py-1 text-xs font-medium text-dark"
      >
        Light
      </span>
      <span
        class="m-2 inline-block rounded border border-transparent bg-yellow-dark px-2.5 py-1 text-xs font-medium text-white"
      >
        Warning
      </span>
      <span
        class="m-2 inline-block rounded border border-transparent bg-red-dark px-2.5 py-1 text-xs font-medium text-white"
      >
        Danger
      </span>
      <span
        class="m-2 inline-block rounded border border-transparent bg-green-dark px-2.5 py-1 text-xs font-medium text-white"
      >
        Success
      </span>
      <span
        class="m-2 inline-block rounded border border-transparent bg-cyan px-2.5 py-1 text-xs font-medium text-white"
      >
        Info
      </span>
    </div>

    <div class="-mx-2 mb-8 flex flex-wrap items-center">
      <span
        class="m-2 inline-block rounded border border-primary px-2.5 py-1 text-xs font-medium text-primary"
      >
        Primary
      </span>
      <span
        class="m-2 inline-block rounded border border-secondary px-2.5 py-1 text-xs font-medium text-secondary"
      >
        Secondary
      </span>
      <span
        class="m-2 inline-block rounded border border-dark px-2.5 py-1 text-xs font-medium text-dark dark:border-dark-4 dark:text-dark-4"
      >
        Dark
      </span>
      <span
        class="m-2 inline-block rounded border border-body-color px-2.5 py-1 text-xs font-medium text-body-color"
      >
        Gray
      </span>
      <span
        class="m-2 inline-block rounded border border-light px-2.5 py-1 text-xs font-medium text-dark dark:text-light"
      >
        Light
      </span>
      <span
        class="m-2 inline-block rounded border border-yellow-dark px-2.5 py-1 text-xs font-medium text-yellow-dark"
      >
        Warning
      </span>
      <span
        class="m-2 inline-block rounded border border-red-dark px-2.5 py-1 text-xs font-medium text-red-dark"
      >
        Danger
      </span>
      <span
        class="m-2 inline-block rounded border border-green-dark px-2.5 py-1 text-xs font-medium text-green-dark"
      >
        Success
      </span>
      <span
        class="m-2 inline-block rounded border border-cyan px-2.5 py-1 text-xs font-medium text-cyan"
      >
        Info
      </span>
    </div>

    <div class="-mx-2 mb-8 flex flex-wrap items-center">
      <span
        class="m-2 inline-block rounded-full border border-transparent bg-primary px-2.5 py-1 text-xs font-medium text-white"
      >
        Primary
      </span>
      <span
        class="m-2 inline-block rounded-full border border-transparent bg-secondary px-2.5 py-1 text-xs font-medium text-white"
      >
        Secondary
      </span>
      <span
        class="m-2 inline-block rounded-full border border-transparent bg-dark px-2.5 py-1 text-xs font-medium text-white dark:bg-dark-2"
      >
        Dark
      </span>
      <span
        class="m-2 inline-block rounded-full border border-transparent bg-body-color px-2.5 py-1 text-xs font-medium text-white"
      >
        Gray
      </span>
      <span
        class="m-2 inline-block rounded-full border border-transparent bg-gray-3 px-2.5 py-1 text-xs font-medium text-dark"
      >
        Light
      </span>
      <span
        class="m-2 inline-block rounded-full border border-transparent bg-yellow-dark px-2.5 py-1 text-xs font-medium text-white"
      >
        Warning
      </span>
      <span
        class="m-2 inline-block rounded-full border border-transparent bg-red-dark px-2.5 py-1 text-xs font-medium text-white"
      >
        Danger
      </span>
      <span
        class="m-2 inline-block rounded-full border-green-dark bg-green-dark px-2.5 py-1 text-xs font-medium text-white"
      >
        Success
      </span>
      <span
        class="m-2 inline-block rounded-full border border-transparent bg-cyan px-2.5 py-1 text-xs font-medium text-white"
      >
        Info
      </span>
    </div>

    <div class="-mx-2 mb-8 flex flex-wrap items-center">
      <span
        class="m-2 inline-block rounded-full border border-primary px-2.5 py-1 text-xs font-medium text-primary"
      >
        Primary
      </span>
      <span
        class="m-2 inline-block rounded-full border border-secondary px-2.5 py-1 text-xs font-medium text-secondary"
      >
        Secondary
      </span>
      <span
        class="m-2 inline-block rounded-full border border-dark px-2.5 py-1 text-xs font-medium text-dark dark:border-dark-4 dark:text-dark-4"
      >
        Dark
      </span>
      <span
        class="m-2 inline-block rounded-full border border-body-color px-2.5 py-1 text-xs font-medium text-body-color"
      >
        Gray
      </span>
      <span
        class="m-2 inline-block rounded-full border border-light px-2.5 py-1 text-xs font-medium text-dark dark:text-light"
      >
        Light
      </span>
      <span
        class="m-2 inline-block rounded-full border border-yellow-dark px-2.5 py-1 text-xs font-medium text-yellow-dark"
      >
        Warning
      </span>
      <span
        class="m-2 inline-block rounded-full border border-red-dark px-2.5 py-1 text-xs font-medium text-red-dark"
      >
        Danger
      </span>
      <span
        class="m-2 inline-block rounded-full border border-green-dark px-2.5 py-1 text-xs font-medium text-green-dark"
      >
        Success
      </span>
      <span
        class="m-2 inline-block rounded-full border border-cyan px-2.5 py-1 text-xs font-medium text-cyan"
      >
        Info
      </span>
    </div>

    <div class="-mx-2 mb-8 flex flex-wrap items-center">
      <span
        class="m-2 inline-block rounded border border-transparent bg-primary/10 px-2.5 py-1 text-xs font-medium text-primary"
      >
        Primary
      </span>
      <span
        class="m-2 inline-block rounded border border-transparent bg-secondary/10 px-2.5 py-1 text-xs font-medium text-secondary"
      >
        Secondary
      </span>
      <span
        class="m-2 inline-block rounded border border-transparent bg-dark/10 px-2.5 py-1 text-xs font-medium text-dark dark:bg-white/5 dark:text-white"
      >
        Dark
      </span>
      <span
        class="m-2 inline-block rounded border border-transparent bg-body-color/10 px-2.5 py-1 text-xs font-medium text-dark dark:bg-gray/10 dark:text-gray-2"
      >
        Gray
      </span>
      <span
        class="m-2 inline-block rounded border border-transparent bg-gray-3/50 px-2.5 py-1 text-xs font-medium text-dark"
      >
        Light
      </span>
      <span
        class="m-2 inline-block rounded border border-transparent bg-yellow-dark/10 px-2.5 py-1 text-xs font-medium text-yellow-dark"
      >
        Warning
      </span>
      <span
        class="m-2 inline-block rounded border border-transparent bg-red-dark/10 px-2.5 py-1 text-xs font-medium text-red-dark"
      >
        Danger
      </span>
      <span
        class="m-2 inline-block rounded border border-transparent bg-green-dark/10 px-2.5 py-1 text-xs font-medium text-green-dark"
      >
        Success
      </span>
      <span
        class="m-2 inline-block rounded border border-transparent bg-cyan/10 px-2.5 py-1 text-xs font-medium text-cyan"
      >
        Info
      </span>
    </div>

    <div class="-mx-2 mb-8 flex flex-wrap items-center">
      <span
        class="m-2 inline-block rounded-full border border-transparent bg-primary/10 px-2.5 py-1 text-xs font-medium text-primary"
      >
        Primary
      </span>
      <span
        class="m-2 inline-block rounded-full border border-transparent bg-secondary/10 px-2.5 py-1 text-xs font-medium text-secondary"
      >
        Secondary
      </span>
      <span
        class="m-2 inline-block rounded-full border border-transparent bg-dark/10 px-2.5 py-1 text-xs font-medium text-dark dark:bg-white/5 dark:text-white"
      >
        Dark
      </span>
      <span
        class="m-2 inline-block rounded-full border border-transparent bg-body-color/10 px-2.5 py-1 text-xs font-medium text-dark dark:bg-gray/10 dark:text-gray"
      >
        Gray
      </span>
      <span
        class="m-2 inline-block rounded-full border border-transparent bg-gray-3/50 px-2.5 py-1 text-xs font-medium text-dark"
      >
        Light
      </span>
      <span
        class="m-2 inline-block rounded-full border border-transparent bg-yellow-dark/10 px-2.5 py-1 text-xs font-medium text-yellow-dark"
      >
        Warning
      </span>
      <span
        class="m-2 inline-block rounded-full border border-transparent bg-red-dark/10 px-2.5 py-1 text-xs font-medium text-red-dark"
      >
        Danger
      </span>
      <span
        class="m-2 inline-block rounded-full border border-transparent bg-green-dark/10 px-2.5 py-1 text-xs font-medium text-green-dark"
      >
        Success
      </span>
      <span
        class="m-2 inline-block rounded-full border border-transparent bg-cyan/10 px-2.5 py-1 text-xs font-medium text-cyan"
      >
        Info
      </span>
    </div>
  </div>
</section>
<!-- ====== Badges Section End -->`;
