const BodyStyle = () => {
  const editor = (window as any).editor;

  const setBodyStyle = () => {
    editor.setStyle("body { overflow-x: auto; overflow-y: auto;}");
  };

  return (
    <button className="bg-gray-500 hover:bg-gray-600 text-gray-800 font-bold py-2 px-4 rounded-l" type="button" onClick={() => setBodyStyle()}>
      Body Style
    </button>
  );
};

export default BodyStyle;
