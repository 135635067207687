export const source = ` <div class="bg-black p-4 items-center">
<div class="flex justify-center pt-10 pb-14 md:pb-9"> <img class="w-2/5 md:w-[25%] lg:w-[18%] xl:w-[15%]"
        src="images/cucet-footer-logo.png" alt="" srcset="" /> </div>
<div class="space-y-9 grid grid-cols-1 md:grid-cols-2 md:pl-24 md:pr-16 lg:grid-cols-4 lg:px-7 xl:px-14 xl:ml-16">
    <div class="text-[#6d6d6d] font-[650] text-[16px] leading-7">
        <div class="flex justify-start md:mt-10">
            <h1
                class="text-white pb-[36px] font-sans uppercase font-normal tracking-wider underline underline-offset-[12px] decoration-red-500/90">
                Inform </h1> <span class="text-white font-sans uppercase font-normal tracking-wider">ation</span>
        </div>
        <p>Registration Start Date</p>
        <p>Registration End Date</p>
        <p>Exam Last Date </p>
    </div>
    <div class="text-[#747474] text-[15px] font-[550] leading-7 tracking-wide md:">
        <div>
            <pre
                class="text-white pb-[34px] font-sans uppercase font-normal tracking-wider underline underline-offset-[12px] decoration-red-500/90"> dates &nbsp;</pre>
        </div>
        <p>23 Dec 2021</p>
        <p>30 May 2022</p>
        <p>23 Dec 2021 to 31 May 2022</p>
    </div>
    <div class="md:flex flex-col justify-between">
        <div class="text-[#747474] text-[15px] font-[550] leading-7 tracking-wide">
            <div class="flex justify-start">
                <h1
                    class="text-white pb-[34px] font-sans uppercase font-normal tracking-wider underline underline-offset-[12px] decoration-red-500/90">
                    About </h1> <span class="text-white font-sans uppercase font-normal tracking-wider">exam</span>
            </div>
            <p class=""> <a class="hover:text-[#cacaca]" href="#">Apply</a> </p>
            <p class=""> <a class="hover:text-[#cacaca]" href="#">Eligibility</a> </p>
            <p class=""> <a class="hover:text-[#cacaca]" href="#">Application Process</a> </p>
            <p class=""> <a class="hover:text-[#cacaca]" href="#">Syllabus</a> </p>
        </div>
        <div
            class="text-[#747474] text-[15px] font-[550] leading-6 tracking-wide mt-9 md:order-5 md:pt-32 lg:pb-28">
            <div class="flex justify-start md:-mt-32">
                <h1
                    class="text-white pb-[38px] font-sans uppercase font-normal tracking-wider underline underline-offset-[12px] decoration-red-500/90">
                    suppor </h1> <span class="text-white font-sans uppercase font-normal tracking-wider">t</span>
            </div>
            <p class=""> <a href="#" class="hover:text-[#cacaca]">FAQ's</a> </p>
        </div>
    </div>
    <div class="text-[#797878] text-[15px] font-[550] leading-6 tracking-wide md:order-4 lg:">
        <div class="flex justify-start">
            <h1
                class="text-white pb-[38px] font-sans uppercase font-normal tracking-wider underline underline-offset-[12px] decoration-red-500/90">
                get in t </h1> <span class="text-white font-sans uppercase font-normal tracking-wider">ouch</span>
        </div>
        <p class="space-x-3"> <i class="fa fa-home text-[17px]"></i><strong
                class="text-[#8b8b8b] text-[15px] tracking-wide">Our Company</strong> </p>
        <div class="leading-7">
            <p class=""> NH-70 Chandigarh-Ludhiana Highway, <br /> Mohali, Punjab (INDIA) <br /> General Helpline
                No:+91 987-6543-210 </p>
        </div>
        <div class="leading-7 mt-4 text-[15px] font-[500]">
            <p class="space-x-3 hover:text-[#cacaca]"> <i class="fa fa-phone text-[17px]"></i> <a href="#"> 1800
                    1800 88800</a> </p>
            <p class="space-x-3 hover:text-[#cacaca]"> <i class="fa fa-phone text-[17px]"></i> <a
                    href="#">9946948000S ( <span class="text-[13px] font-[500]">General Enquiry</span> )</a> </p>
            <p class="space-x-3 hover:text-[#cacaca]"> <i class="fa fa-phone text-[17px]"></i> <a
                    href="#">994694700&nbsp;( <span class="text-[13px] font-[500]">Technical Support: CUCET</span> )
                </a> </p>
            <p class="space-x-3 hover:text-[#cacaca]"> <i class="fa fa-envelope text-[16px]"></i> <a href="#">
                    admin@Company.in</a> </p>
        </div>
    </div>
</div>
<div class="divide-y divide-[#747474] contrast-200 mt-3 mb-3 md:-mt-4 md:-mb-2 lg:-mt-16 xl:-mt-24">
    <p>&nbsp;</p>
    <p>&nbsp;</p>
</div>
<div class="flex justify-center mb-4 md:mb-1 text-[#afafaf] text-center text-xl space-x-3">
    <p> <a href="#"
            class="w-10 h-10 bg-[#474747] hover:text-blue-500 transform ease-in-out duration-500 hover:bg-white rounded-full inline-block pt-[7px]"><i
                class="fa fa-facebook"></i></a> </p>
    <p> <a href="#"
            class="w-10 h-10 bg-[#474747] hover:text-blue-500 transform ease-in-out duration-500 hover:bg-white rounded-full inline-block pt-[6px]"><i
                class="fa fa-twitter"></i></a> </p>
    <p> <a href="#"
            class="w-10 h-10 bg-[#474747] hover:text-blue-500 transform ease-in-out duration-500 hover:bg-white rounded-full inline-block pt-[6px]"><i
                class="fa fa-instagram"></i></a> </p>
    <p> <a href="#"
            class="w-10 h-10 bg-[#474747] hover:text-blue-500 transform ease-in-out duration-500 hover:bg-white rounded-full inline-block pt-[6px]"><i
                class="fa fa-linkedin"></i></a> </p>
    <p> <a href="#"
            class="w-10 h-10 bg-[#474747] hover:text-blue-500 transform ease-in-out duration-500 hover:bg-white rounded-full inline-block pt-[6px]"><i
                class="fa fa-youtube"></i></a> </p>
    <p> <a href="#"
            class="w-10 h-10 bg-[#474747] hover:text-blue-500 transform ease-in-out duration-500 hover:bg-white rounded-full inline-block pt-[6px]"><i
                class="fa fa-pinterest"></i></a> </p>
    <p> <a href="#"
            class="w-10 h-10 bg-[#474747] hover:text-blue-500 transform ease-in-out duration-500 hover:bg-white rounded-full inline-block pt-[6px]"><i
                class="fa fa-telegram"></i></a> </p>
</div>
</div>`;
