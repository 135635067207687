export const source = `
<!-- Interactive Features Section -->
<section class="bg-gray-900 py-16 relative overflow-hidden min-h-screen flex items-center" data-section="interactive-features">
    <div class="container mx-auto px-4 relative z-10">
        <div class="flex flex-wrap justify-center items-center gap-6 perspective-1000" data-element="feature-cards">
            <!-- Interactive feature cards here -->
            Interactive feature cards here 
        </div>
    </div>
</section>
`;
